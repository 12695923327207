const locales = {
  menus: {
    common: {
      requests: "Reports",
      reports: "Reports",
      approvals: "Approvals",
    },
    rp: {
      expenses: "Expenses",
      cardStatements: "Card statement",
      cashAdvances: "Cash advances",
      summary: "Summary",
      originalReceipt: "Original receipts",
    },
    ip: {
      invoices: "Invoices",
      proposals: "Proposals",
      analysis: "Summary",
      suppliers: "Suppliers",
      nationalTaxDocuments: "Tax documents",
      waitingWorkerDocumentInputs: "Data Entry",
    },
    cm: {
      contractManagement: "Contract Management",
    },
    systemSettings: "System settings",
    personalSettings: "Personal settings",
  },
  createMenu: {
    new: "New",
    rp: {
      report: "Report",
      otherReports: "Other reports",
      createExpense: "Register Expense",
      manual: "Manual",
      transport: "Transport expense",
      auto: "Auto",
      allowances: "Allowances",
    },
    ip: {
      report: "Report",
      proposal: "Proposal",
      paymentRequest: "Payment request",
      registerInvoice: "Register Invoice",
      manual: "Manual",
    },
  },
  commons: {
    serveses: {
      receiptPost: "TOKIUM経費精算",
      invoicePost: "TOKIUMインボイス",
      taxDocument: "TOKIUM電子帳簿保存",
      contractManagement: "TOKIUM契約管理",
    },
    actions: {
      index: "Actions",
      send: "Send",
      save: "Save",
      register: "Register",
      search: "Search",
      add: "Add",
      merge: "Merge",
      create: "Create",
      update: "Update",
      change: "Change",
      duplicate: "Duplicate",
      registration: "Registration",
      edit: "Edit",
      apply: "Submit",
      sendReport: "Send the report",
      editRequest: "Edit",
      settlementRequest: "Settlement request",
      saveAndApply: "Save and apply",
      reapply: "Resubmit",
      withdraw: "Withdraw",
      withdrawApproval: "Revoke approval",
      reject: "Reject",
      resetFlow: "Reset Approval Flow",
      approve: "Approve",
      recordTemporaryPaymentRefund: "Record temporary payment refund",
      cancelTemporaryPaymentRefund: "Cancel temporary payment refund",
      approval: "Approval",
      output: "Output",
      delete: "Delete",
      finish: "Finish",
      cancel: "Cancel",
      cancelAll: "Cancel all",
      cancelTemporaryPayment: "Cancel temporary payment",
      check: "Check",
      reset: "Remove",
      resetSearchConditions: "Reset search conditions",
      searchWithTheConditions: "Search with the specified conditions",
      configure: "Configure",
      configured: "Configured",
      close: "Close",
      unset: "Not configured",
      import: "Import",
      importWithXlsxFile: "Import",
      downloadWithXlsxFile: "Export",
      remove: "remove",
      newEntry: "New",
      markAsRead: "Mark as read",
      markAllAsRead: "Mark all as read",
      markAsUnread: "Mark as unread",
      yes: "Yes",
      no: "No",
      select: "Select",
      enable: "Enable",
      downloadCashAdvanceRequest: "Download cash advance request",
      loginAsAgent: "Login as an agent",
      loginAsAgentPlaceholder: "search by name/email",
      loginBackToUser: "Logout from an agent",
      loginAsTenant: "Login as an tenant",
      changeTenant: "Please switch tenants to see.",
      loginAsTenantPlaceholder: "search by tenant name",
      logout: "Logout",
      destroy: "Deleted.",
      back: "Back",
      split: "Split",
      confirmSplit: "Split Expense",
      next: "Next",
      setting: "Setting",
      enterComment: "Enter your message",
      loadMore: "Load more",
    },
    units: {
      person: "person",
      person_plural: "people", // eslint-disable-line camelcase
      report: "matter",
      result: "result",
      result_plural: "results", // eslint-disable-line camelcase
      hour: "H",
      minute: "M",
      name: "Name",
      version: "version",
      otherCounts: "{{count}} others",
      year: "year",
      year_plural: "years", // eslint-disable-line camelcase
      month: "month",
      month_plural: "months", // eslint-disable-line camelcase
      char: "character",
    },
    ordinals: {
      no: "{{count}}",
      no_interval: "(1){1st};(2){2nd};(3){3rd};", // eslint-disable-line camelcase
      no_plural: "{{count}}th", // eslint-disable-line camelcase
    },
    messages: {
      confirmDelete: "Are you sure you want to delete it?",
      confrimPayment:
        "Are you sure you want to mark the reports as payments completed?",
      confrimTemporaryPayment:
        "Are you sure you want to mark the reports as temporary payments completed?",
      noDataFound: "There are no data",
      submitted: "Your data have been submitted",
      saved: "Your data have been saved",
      deleted: "Your data have been deleted",
      markAllAsRead: "Are you sure yout want to mark all as read?",
      updated: "Updated successfully",
      refundTemporaryPayment:
        "Please refund the difference between the cash advance and the actual expenditure",
    },
    errors: {
      communicationError: "A communication error has occured",
      failedToSubmit: "Failed to send you data",
      failedToSave: "Failed to save your data",
      failedToUpload: "Failed to upload files",
      failedToUpdate: "Failed to update",
      failedToFetch: "Failed to fetch data",
    },
    status: {
      created: "Not Submitted",
      sending: "Sending",
      notEntered: "Not Entered",
      notSelected: "Not Selected",
      submitted: "Submitted",
      unsubmitted: "Not Submitted",
      creating: "Registering data",
      failed: "Failed to register",
      applying: "Approval Pending",
      reapplying: "Reapplication",
      recalled: "Withdrawal",
      rejected: "Rejected",
      aborted: "Approval Flow Change Required",
      archived: "Approval Flow Changed",
      approved: "Approved",
      potentially: "Potential Approval Pending",
      closed: "Application Completed",
      pendingTemporaryPayment: "Waiting for advance payment",
      temporaryPaid: "Temporary Payment Processed",
      pendingTemporaryPaymentRefund:
        "Waiting for the refund of advance payment",
      unsettledTemporaryPayment: "Unsettled advance payment",
      settled: "Settled",
      unsettled: "Unsettled",
      paid: "Payment Processed",
      checked: "Checked",
      nothingProject: "Project is not set",
      multipleProjects: "Multiple projects are set",
      nothingPaymentGroup: "Payment group is not set",
      multiplePaymentGroups: "Multiple payment groups are set",
      notAggregated: "Not aggregated",
      aggregated: "Aggregated",
      notExported: "Not exported",
      exported: "Exported",
      notPaid: "Not paid",
      none: "None",
      unconfirmed: "Unconfirmed",
      alert: "alert",
      approvalRevoked: "Approval revoked",
    },
    inputs: {
      required: "{{name}} (Required field)",
    },
    words: {
      date: "Date",
      dateAndTime: "Date and time",
      total: "Total",
      sizePerPage: "Page Size",
      name: "name",
      type: "type",
      info: "info",
      threshold: "Threshold",
      condition: "Condition",
      unknown: "unknown",
      here: "here",
    },
    misc: "Misc.",
    image: "Image",
    preposition: {
      from: "From",
      to: "To",
    },
    conditions: {
      yes: "All",
      no: "Nothing",
      all: "All",
    },
    validations: {
      valid: "Valid",
      invalid: "Invalid",
      all: "All",
    },
    formItemType: {
      text: "Text",
      multilineText: "Multi lines text",
      number: "Number",
      date: "Date",
      check: "Check (Yes or No)",
      multiCheck: "Check (multiple selection)",
      radio: "Radio button",
      select: "Select menu",
      attachment: "File",
    },
    dataFetchTable: {
      itemsAreDisplayed: "items",
      total: "total",
      items: "items",
      sizePerPage: "Size per page",
      itemsAreSelected: "items selected",
      selectedItemsMenu: "Controll menu",
    },
    table: {
      pagenation: {
        page: "page",
        moveTo: "move to",
      },
    },
    ubiquitous: {
      category: "Category",
    },
    label: {
      required: "Required",
    },
  },
  accountingDataScheduledExports: {
    title: "経費精算",
    fileTransferLocations: {
      createModal: {
        title: "New file transfer location",
      },
      deleteConfirmModal: {
        title: "Delete file transfer location",
        message1: "If you delete a destination, you cannot undo it.",
        message2: "Are you sure you want to delete it?",
      },
      detailDrawer: {
        connectionCheck: "Check Connection",
      },
      commons: {
        existEmptyInput: "There are unentered fields",
      },
      table: {
        noData: "No file transfer locations found.",
        protocol: "Protocol",
        name: "File transfer location Name",
        description: "Description",
      },
      form: {
        name: "File transfer location",
        description: "Description",
        protocol: "Protocol",
        host: "Host",
        port: "Post",
        authMethod: "Authentication method",
        authMethodPassword: "Password authentication",
        account: "Account",
        password: "Password",
        directory: "Transfer directory",
        connectionConfirmation: "Connection Confirmation",
        connectionSuccess: "Success",
        connectionFail: "Fail",
        connectionNoCheck: "No check",
        popoverDescription: {
          name: "Up to 200 characters can be set",
          description: "Up to 1000 characters can be set",
          host: `Can be set to a domain name or IP address.

              ex)
              example.com
              192.168.1.1`,
          port: "Can be set in the range of 20~65535",
          account: `Up to 32 characters can be set.
              Periods (.) and slash (/) are not allowed`,
          password: "Up to 255 characters can be set",
          directory: `Up to 255 characters can be set
            Root directory (/) cannot be specified`,
        },
      },
      indexPage: {
        title: "File transfer locations",
        backToTask: "Back to tasks",
        new: "New",
      },
    },
    jobRequests: {
      table: {
        noData: "No execution results found.",
        date: "Date",
        fileTransferLocationFileName: "Output file name",
        status: "Status",
        runnerType: "Job type",
        timeRequired: "Time required",
        latestJobExecution: "Result",
        fileTransferLocationProtocol: "Protocol",
        fileTransferLocationDirectory: "Transfer directory",
        taskName: "Task name",
        enableAccountingDataCreation: "Export format",
        accountingDataCreationEnabled: "Yes",
        accountingDataCreationDisabled: "No",
        enableFileTransfer: "File transfer",
        fileTransferEnabled: "Yes",
        fileTransferDisabled: "No",
      },
      indexPage: {
        title: "Execution results",
        backToTask: "Back to tasks",
        reloadJob: "Reload latest job requests status",
      },
      status: {
        success: "Success",
        fail: "Fail",
      },
      runnerType: {
        schedular: "scheduled job",
        tester: "test job",
        immediateRunner: "immediate job",
        reRunner: "re-executed job",
      },
      timeRequired: {
        minutes: "{{minutes}} minutes.",
      },
      latestJobExecution: {
        aggregateResults: "Aggregate results",
        aggregationFailure: "Aggregation: Failure",
        infoWithExceptionDetail: "Detail",
        errorDetail: "Error Detail",
        criticalMessage: "Unexpected Error Occured",
        download: "Download",
        reExecute: "Re-execute",
        none: "Not executed",
        label: "Export format",
        bankData: {
          label: "Zengin Data",
          download: "Download",
        },
      },
    },
    tasks: {
      deleteConfirmModal: {
        title: "Delete task",
        message1: "If you delete the task, you cannot undo it.",
        message2: "Are you sure you want to delete it?",
      },
      createModal: {
        title: "Create task",
      },
      forms: {
        base: {
          heading: "基本",
          title: "基本",
          name: {
            label: "Task Name",
            required: "タスク名を入力してください",
          },
          executionInterval: {
            label: "実行間隔",
            required: "実行間隔を選択してください",
            intervals: {
              byMonth: "毎月",
              byWeekday: "毎週",
              byDay: "毎日",
              byOnceSpecifiedDate: "Specified date",
            },
            lastDayOfMonth: "月末日",
          },
          executionTimetable: {
            label: "実行時間枠",
            required: "実行時間枠を選択してください",
            loading: "実行時間枠を読込中...",
            notFound:
              "実行時間枠が登録されていません。サポートまでお知らせください。",
          },
          periodStartedAt: {
            label: "有効期限（開始）",
            required: "有効期限（開始）を入力してください",
          },
          periodEndedAt: {
            label: "有効期限（終了）",
            required: "有効期限（終了）を入力してください",
          },
        },
        aggregation: {
          heading: "集計",
          title: "集計",
          analysisAggregationName: {
            label: "集計名フォーマット",
            required: "集計名フォーマットを入力してください",
            placeholder: "{yyyymmdd}_集計",
          },
        },
        export: {
          heading: "出力",
          title: "会計データ出力",
          enableAccountingDataCreation: {
            label: "会計データを出力する",
            enable: "出力する",
            disable: "出力しない",
          },
          exportFormat: {
            label: "出力形式",
            required: "出力形式を選択してください",
            loading: "出力形式を読込中...",
            notFound:
              "仕訳フォーマット設定または会計出力形式を設定してください。",
          },
          exportFileNameFormat: {
            label: "出力ファイル名フォーマット",
            required: "出力ファイル名フォーマットを入力してください",
            placeholder: "{yyyymmdd}_集計",
          },
          executor: {
            label: "実行者",
            required: "実行者を選択してください",
            loading: "実行者を読込中...",
            notFound: "従業員設定で管理者または集計者を設定してください。",
          },
          enableBankDataCreation: {
            label: "Output Zengin Data",
            enable: "Output",
            disable: "Do not output",
          },
          companyExpenseAccount: {
            title: "Zengin Data Output",
            label: "Payment Account",
            required: "Please select an output format",
            loading: "Loading output format...",
            notFound:
              "Please set the output format for company expense accounts",
            paymentSpecifiedTitle: "Payment Specified Date",
            paymentSpecifiedRequired: "Please select a payment method",
            feePaymentDestination: "Fee Payment Destination",
            our: "Our",
            their: "Their",
            inputPaymentSpecifiedRequired: "Please enter the payment date",
          },
        },
        bankExport: {
          title: "Zengin Data",
        },
        transfer: {
          heading: "転送",
          title: "ファイル転送",
          enableFileTransfer: {
            label: "ファイルを転送する",
            enable: "転送する",
            disable: "転送しない",
          },
          fileTransferLocation: {
            label: "転送先",
            required: "転送先を選択してください",
            loading: "転送先を読込中...",
            notFound: "転送先を設定してください。",
          },
        },
      },
      table: {
        noData: "No tasks found.",
        name: "Task Name",
        schedule: "Schedule",
        enabled: "Scheduled exports",
        exportFormatName: "ExportFormatName",
        analysisAggregationConditions: "AnalysisAggregationConditions",
        latestJobExecution: "Previous execution",
        nextExecutionTimetable: "Next execution",
        notValidTimetable: "There is no valid next execution timetable",
        enableAccountingDataCreation: "Export format",
        accountingDataCreationEnabled: "Yes",
        accountingDataCreationDisabled: "No",
        enableBankDataCreation: "Output Bank Data",
        enableBankDataCreationEnabled: "Yes",
        enableBankDataCreationDisabled: "No",
        enableFileTransfer: "File transfer",
        fileTransferEnabled: "Yes",
        fileTransferDisabled: "No",
        noNextExecution:
          "Next time will not be executed because it is already scheduled or the execution time has expired.",
        noNextExecutionHelp1:
          "Specified dates are executed only once for regular execution.",
        noNextExecutionHelp2:
          "For immediate or test execution, multiple runs can be performed without limitation on the number of times.",
      },
      indexPage: {
        title: "Tasks",
        setUpFileTransferLocation: "File transfer locations",
        jobIndex: "Execution results",
        new: "New",
      },
      enable: {
        true: "Valid",
        false: "Invalid",
      },
      schedule: {
        dayOfWeek: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        day: "{{day}}th",
        executionDayOfMonth: "{{date}}th of every month",
        executeAtEndOfEachMonth: "Last day of each month",
        executionDayOfWeek:
          "Every $t(accountingDataScheduledExports.tasks.schedule.dayOfWeek.{{day}})",
        executionDaily: "Every day",
      },
      analysisAggregationConditions: {
        noData: "No aggregation conditions",
        details: "Aggregation condition details",
        destination: "Destination",
        applicantGroup: "Applicant group",
        paymentGroup: "Payment group",
        applicantPost: "Applicant's position",
        approvedApprover: "Approved approver",
        projectId: "Project ID",
        projectName: "Project name",
        includeChildDepartment: "Include child departments",
        includeNotAggregated: "Include not aggregated",
        includeAggregated: "Include aggregated",
        includeNotExported: "Include not exported",
        includeExported: "Include exported",
        includeNotPaid: "Include not Paid",
        includePaid: "Include paid",
        includeUnsettled: "Include unsettled",
        includeSettled: "Include settled",
        matchedOriginalReceipt: "Include original receipt matched",
        notMatchedOriginalReceipt: "Include original receipt not matched",
        includeNotTemporaryPaid: "Include not temporary paid",
        includeTemporaryPaid: "Include temporary paid",
        submittedPeriod: "submittedPeriod",
        transactedPeriod: "transactedPeriod",
        lastApprovedPeriod: "lastApprovedPeriod",
        temporaryPaymentDuePeriod: "temporaryPaymentDuePeriod",
        startPeriod: "Travel Period",
        tags: "Applicant's label",
        registratedNumber: "Registrated number",
        includeAsInvoice: "Yes",
        includeNotAsInvoice: "No",
        period: {
          current: "Current month",
          previous: "Previous month",
          rangeAndDate: "{{from}}~{{to}} {{range}}",
        },
        includeLabel: {
          departmentSearchRange: "Department search scope",
          aggregationStatus: "Aggregation status",
          exportationStatus: "Exportation status",
          paymentStatus: "Payment status",
          temporaryPaymentStatus: "Cash advance status",
          payOffStatus: "Pay off status",
          matchedOriginalReceiptStatus: "Matched original receipt",
          asInvoice: "Treat as an eligible invoice",
        },
      },
      latestJobExecution: {
        aggregateResults: "Aggregate results",
        aggregationFailure: "Aggregation: Failure",
        download: "Download",
      },
      descriptions: {
        aggregationDate: `You can filter reports by specifing dates such as "submitted at" or "transacted at"

        Please see following examples, with condition today is 8-Apr-2022(Friday).

        (ex. 1) specify as 'month' :
        「1」「month」「before」「1」 ~
        「1」「month」「after」「15」
        → Will pick up requests covering from 1-Mar-2022 to 15-May-2022.

        (ex. 2) specify as 'this month' :
        「0」「month」「before(or after)」「1」 ~
        「0」「month」「before(or after)」「end of month」
        → Will pick up requests covering from 1-Apr-2022 to 8-Apr-2022.

        (ex. 3) specify as 'weekday' :
        「2」「week」「before」「Sunday」 ~
        「1」「week」「before」「Saturday」
        → Will pick up requests covering from 20-Mar-2022(Sunday) to 2-Apr-2022(Saturday).

        (ex. 4) specify as 'day' :
        「1」「day」「before」 ~
        「1」「day」「before」
        → Will pick up requests covering 7-Apr-2022`,
        charsRestriction: `Characters that can be used are "alphanumeric characters", "hiragana", "katakana", "kanji", "long vowel symbols", "hyphen", "underscore", "{", "}".`,
        dateFormat: `You can specify the year, month, and day.

          Example) When the task execution date is 2021/9/5
          ・Convert only within {}
          {jpymd}_data → 395_data
          ・Display with zero fill
          {yyyymmdd} → 20210905
          ・Change to end-of-month date
          {yymmlastdd} → 210930
          ・Change of month and date
          {yymmdd[+2]} → 210907 ( Two days later )
          {yymm[-2]dd} → 210705 ( Two months ago )
          ・Change to the nearest business day
          {last_business_day(yyyymmdd)} → 20210903
          {next_business_day(yyyymmdd)} → 20210906`,
        executionDay: `Reports that exist by the time the task is executed will be included in the aggregation.
          If "Monthly" and "Last day of month" are specified as the execution date and the task execution time is 12:00, reports created after 12:00 on the last day of the month will not be included in the aggregation.`,
      },
      readOnlyForm: {
        bankDataCreation: {
          title: "Zengin Data",
          enableField: {
            title: "Enable",
            enable: "Yes",
            disable: "No",
          },
          exportFileName: "Export file name format",
          paymentDate: "Date of payment",
          paymentAccount: "Payment account",
          executionDate: {
            prefix: "実行日",
            afterDay: "⽇後",
            beforeDay: "⽇前",
            afterBusinessDay: "営業⽇後",
            beforeBusinessDay: "営業⽇前",
          },
        },
      },
    },
    noLicense: {
      messageForRP:
        "The accounting data scheduled exports function (for TOKIUM経費精算) is a paid option. Please contact our support team to use this function.",
      message:
        "The accounting data scheduled exports function (for TOKIUM経費精算/TOKIUMインボイス) is a paid option. Please contact our support team to use this function.",
      summaryForRP: `You can automate following operations with assigned period span, which you are now doing manually at [Receipt Post > Aggregation]

      ・create aggergation
      ・export accounting data
      ・export bank data

      furthermore, you can transfer accounting data files to designated FTP server of your company.
      `,
      summary: `You can automate following operations with assigned period span, which you are now doing manually at [Receipt Post/Invoice Post > Aggregation]

      ・create aggergation
      ・export accounting data
      ・export bank data

      furthermore, you can transfer accounting data files to designated FTP server of your company.
      `,
    },
  },
  genericFields: {
    dataSets: {
      alertSettings: {
        title:
          'If you enable "required entry" for the using expenses, you can use the alert function to display warnings or disallow application.',
        urlName: ">> Go to alert settings",
      },
      titles: {
        list: "DataSet List",
        createDataSet: "Create data set",
        removeDataSet: "Remove data set",
      },
      items: {
        code: "Code",
        name: "Name",
        description: "Description",
        sort: "Sort",
        enabled: "Enable",
        usingCategories: "Using categories",
        linkToItems: "Items list",
        usingCategoryAutoSettingHelpText:
          "In case of enable, when a category is registered, it will be attached automatically to the category.",
        usingCategoryAutoSetting: "Auto using setting for new category",
        usingCategoryAutoSettingParams: {
          request: "Input required",
          multiple: "Multiple select",
        },
        usingCategorySettingHelpText:
          'The entry form for this data set will appear when "Using category" is selected.',
      },
      usingCategoriesParam: {
        expenseCategories: "Expense category",
        debitInvoiceCategories: "Debit invoice category",
        required: "Required",
        multiple: "Multiple",
        checkAll: "Check/Remove All boxes",
      },
      actions: {
        selectUsingExpenseCategories: "Select using expense categories",
        selectUsingDebitInvoiceCategories:
          "Select using debit invoice categories",
      },
      errors: {
        name: "Name is required.",
        reachedLimit: "You can create data sets up to {{limit}}.",
      },
      messages: {
        confirmDeleteDataSet: "Are you sure to remove data set?",
      },
      creatableByAllUsers: {
        label: "Employees can create datasets",
      },
    },
    items: {
      table: {
        code: "Code",
        name: "Name",
        sort: "Sort",
        userName: "Maker",
        createdAt: "Created at",
        createdMethod: "Way of making",
        linkToMetadata: "Link to metadata settings",
        method: {
          preferencesSetting: "システム設定",
          preferencesImport: "インポート",
          externalApi: "外部API",
          usersExpense: "経費",
          usersInvoice: "インボイス",
        },
      },
      titles: {
        list: "Item List",
        createItem: "Create Item",
        removeItem: "Delete Item",
        selectorTitle: "select Item",
      },
      enable: {
        true: "Valid",
        false: "Invalid",
      },
      errors: {
        code: "Code is required.",
        name: "Name is required.",
        sort: "Sort is required.",
        failedToDelete: "Failed to delete the item",
      },
      labels: {
        code: "Code",
        name: "Name",
        isDeleted: "Deleted",
      },
      imports: {
        title: "Import expense generic",
        importItems: "Import expense generic.",
        sample: "sample file",
        guide2:
          "To modify the settings, export the latest settings, modify the export file, and then import.",
        moveExport: "Export current settings",
      },
      exports: {
        title: "Export expense generic",
        createLatestExportFile: "Start creating latest file",
        guide:
          "To modify the settings, export the latest settings, modify the export file, and then import.",
        moveImport: "Go to import screen",
      },
      messages: {
        confirmDeleteItem: "Are you sure you want to delete it?",
        confirmDeleteAll: "Will delete selected items, is it ok ?",
      },
      search: {
        placeholders: "Multiple words allowed",
      },
      button: {
        addButton: "Add",
        disabledAddButton: "New item can be added, with code and name",
      },
      unit: "count",
    },
    licence: {
      noLicenseMessage:
        "License registration is required to use Generic Field function. To get licenses, please contact our support team.",
      guidanceForSupportPage: "For more information on this feature, click ",
    },
  },
  agents: {
    labels: {
      agent: "Agent",
    },
  },
  aggregationResults: {
    actions: {
      addAccount: "Add account",
      createSelected: "Register expenses",
      deleteSelected: "Delete the selected rows",
    },
    errors: {
      categoryMissing: "Please select an expense category",
      failedToDelete: "Failed to delete the selected rows",
      paymentGroupMissing: "Please select a payment group",
    },
    messages: {
      delete: "Deleted the data successfully",
    },
    titles: {
      accountList: "Account list",
      createExpenses: "Register expenses",
      paymentStatement: "Payment statement",
    },
  },
  transactions: {
    titles: {
      page: "Expense",
      manual: "Enter manually",
      fare: "Enter fare",
      picture: "Enter by picture",
      list: "Expenses",
      spentList: "Actual expenses",
      transactionsImport: "Import expenses",
      add: "Register expenses",
      edit: "Edit expenses",
      details: "Expense details",
      columnSettings: "column settings",
    },
    properties: {
      id: "ID",
      authorName: "Author",
      date: "Date",
      optionalDate: "Date (expenses)",
      shop: "Payee",
      visited: "Destination",
      origin: "Departure station",
      destination: "Arrival station",
      originByCategory: "Departure place",
      destinationByCategory: "Arrival place",
      originAndDestinationByCategory: " Origin and Destination",
      visitByCategory: "Visiting Place",
      purposeByCategory: "Purpose",
      amount: "Amount",
      amountPerPerson: "[{{amount}} per person]",
      rate: "Rate",
      currency: "Currency",
      withholding: "Withholding tax amount",
      employeeName: "Employee name",
      taxCategory: "Tax category",
      taxCategoryForAmountLabel: "Amount per tax category",
      amountPerTaxCategory: "Amount",
      taxAmountPerTaxCategory: "Tax amount",
      multipleTaxRates: "Multiple tax rates",
      category: "Expense category",
      creditCategory: "Credit account title",
      reportTitle: "Expenses report title",
      preReportTitle: "Pre-report title",
      personalReportTitle: "Expenses report title",
      image: "Receipt",
      isElectronicReceiptImage: "Electronic receipt image",
      transitPayee: "Transit payee",
      comment: "Memo",
      status: "Status",
      registrationDate: "Registered date",
      project: "Project",
      department: "Cost allocated department",
      noRefund: "No refund",
      timeStamp: "TimeStamp",
      read: "Read",
      unread: "Unread",
      userDepartment: "Department",
      reportSequenceNum: "Expenses report ID",
      preReportSequenceNum: "Pre-report ID",
      label: "Label",
      companion: "Participants",
      preTransactionContent: "Content",
      eligibleInvoiceConfirmation: "Qualified Invoice Confirmation",
      asEligibleInvoice: "Qualified Invoice",
      eligibleInvoiceTitle: "Eligible Invoice",
      eligibleInvoice: {
        regardAs: "Regards",
        notRegardAs: "Not regards",
      },
      eligibleInvoiceNotice:
        "The expense is not necessarily as eligible invoice, even if registrated number is found",
      registratedNumber: "Registrated number",
      invoicingOrganization: "Invoicing Organization",
      paidAddress: "Paid Address",
      examples: {
        preTransactionContent: "E.g. Air ticket",
      },
      hasValidatedReceiptMatching: "Receipt matching",
      rateReferenceType: {
        label: "Currency rate setting",
        setting: {
          label: "Setting",
          description: "Apply the rate set by the administrator.",
        },
        manual: {
          label: "Manual",
        },
        unspecified: {
          label: "No change",
          description:
            "Change only currency without modifying currency rate and amount.",
        },
      },
      resolutionInformation: {
        colorSpaceLabel: "Colorspace",
        colorSpaceUnit: "",
        dpiLabel: "Scanner Requirement/ dpi",
        areaDimension: "AreaDimension",
        dpi: "DPI",
        foreside: "foreside",
        backside: "backside",
        noContent: "No Content",
        noData: "No Data",
        noDataBecauseElectronic: "No Data (electronic receipt image)",
      },
      electronicReceiptAlert: {
        title: "Electronic receipts are assigned for this expense.",
        description1:
          "Please attach the PDF or other file you received as an electronic receipt as is.",
        description2:
          "PLEASE DO NOT attach a file that has been printed on paper and photographed or scanned.",
      },
    },
    index: {
      addTransactions: "Register expenses",
      createTransaction: "Register expense",
      selectTransaction: "Select expense",
      editSelected: "Edit the selected expenses",
      editSelectedMulti: "Update selected",
      detachSelected: "Detach the selected expenses",
      deleteSelected: "Delete the selected expenses",
      mergeSelected: "Merge the selected expenses",
      displayItemsSettings: "Display items",
      otherActions: "Other actions",
      displayItems: "Display items",
      selectedColumns: "Selected columns",
      moveToTop: "Move to top",
      displayItemsSettingsCaution: "This settins is saved on only your browser",
      selectFromExistingExpenses: "Select from existing expenses",
      createExpense: "Register new expenses",
      addSelectedToReport: "Add selected to report",
      searchbox: {
        openForm: "Specify search conditions",
        all: "All",
        resetCondition: "Reset the condition",
        search: "Search with the condition",
        scope: {
          label: "Scope",
          all: "Whole company",
        },
        onlyDeleted: "Deleted only",
        exportLineId: "Export ID",
        selectAll: "Select All",
        sequenceNum: "Expenses report ID",
        searchTarget: "Search target",
        searchDisplay: "Conditions of display",
        userDepartment: "Department",
        departmentScope: {
          label: "Department search scope",
          includeChildDepartment: "Include child departments",
          showAllDepartments: "Show All Departments",
          onlyMainDepartment: "Only primary department",
        },
        toBePaid: {
          label: "To be reimbursed",
          yes: "Yes",
          no: "No",
        },
        paidByCorporateCard: {
          label: "Paid by corporate card",
          description:
            "Search expenses paid by corporate card, which is configured on Office Settings (Corporate card) page",
          yes: "yes",
          no: "no",
        },
        electronicReceiptImage: {
          label: "Electronic receipt image",
          yes: "Yes",
          no: "No",
        },
        includeDraftSaved: {
          label: "Include draft-saved applications",
          description:
            "If checked, expenses included in the expense application that is being saved as a draft will be included in the search.",
          disabled:
            "Available when either 'Not Submitted', 'Registering data', 'Failed to register' is checked..",
        },
        receiptExpenseMatching: {
          label: "Matched original receipt",
          alreadyMatched: "Already matched",
          notMatched: "Not matched",
          electronicOrNotAttachedReceiptImage:
            "Unnecessary（Not attached receipt image / Electronic）",
          markedAsMatched: "Marked as matched",
          descriptions: {
            markedAsMatched:
              'You can search for expenses whose marked as a match without the original receipt by your administrator. The status "Marked as matched" may occur by the following reasons; The expense has not correctly matched because another expense has already created from the original receipt, or there is no original receipts matching.',
          },
        },
        settingLabel: "Status of settings",
        isUnassignedApprovalFlow: {
          label: "Approval flow not set",
          description:
            "If you specify the name of approval flow simultaneously, you can search for members who are not linked with the approval flow",
        },
        blankSearch: "Blank",
        emptyAmount: "Amount blank search",
        emptyTransactedAt: "Usage date blank search",
        emptyShopname: "Payee blank search",
        asInvoiceForSearch: "Handling of qualified invoices",
        registratedNumber: "Registration number",
        asInvoiceCheck: {
          true: "Can be",
          false: "None",
        },
      },
      cannotRequest: {
        chooseExpenses: "Please select the expenses to request.",
        includeUnrequestableExpense:
          "The selected items include unrequestable expenses.",
        checkAlertFirst: "Check alerted expenses ",
        checkAlertlast: " in selected items.",
        alreadyRequestedExpense:
          "Expenses that have already been attached to report/pre_report cannot be requested again.",
        workerInputtingExpense:
          "Expenses under inputting by operater cannot be requested.",
        associatedWithPreReportExpense:
          "Expenses associated with Pre-report cannot be requested.",
        notAuthorizedAgent: "Agent not authorized to apply.",
      },
      results: "Search results",
      alert: "Warnings",
      inputByWorker: "Automatically entered",
      untouchedAmount: "Fetched from accounts",
      unknownCategory: "Unknown expense category",
      editMode: "Edit Mode",
      columnSelection: "Filter columns",
      restoreDefaultColumnSelection: "Restore default",
      resolutionInformation: {
        requirementTitle: "Scanner Requirement",
        detail: "detail",
        requirementDetail: `
        Please re-upload receipt image.
        *both vertical and horizonal DPI shall be over 200.
        *vertical length shall be over 2,338 pixels, and horizonal length shall be over 1.654 pixels.
        Image shall meet any one of above, for the purpose of meeting saved scanner requirement by Japanese NATIONAL AX AGENCY.
        `,
      },
    },
    inputs: {
      finish: "Finish to input",
      again: "Continue to input",
      reuse: "Reuse the content",
      cancelImage: "Cancel this image",
      addCostAllocation: "Add cost allocated department",
      image: "Receipt image",
      backsideImage: "Receipt image(backside)",
      boardingDate: "Boarding date",
      multiDate: "Select multiple dates",
      inputShop: "Enter the payee",
      inputVisited: "Enter the visited place",
      noRouteSelected: "Please search for the route",
      showOtherRoutes: "Show other routes",
      selectRoute: "Select the route",
      addVia: "Add via station",
      round: "Round trip",
      means: "Transport facilities",
      searchAgain: "Search again",
      detailSettings: "Detail settings",
      details: {
        shinkansen: "The Shinkansen",
        limitedExpress: "Limited express",
        liner: "Liner",
        localBus: "Local bus",
        connectionBus: "Connection bus",
        highwayBus: "Highway bus",
        midnightBus: "Midnight bus",
        plane: "Airway",
        ship: "Sea route",
        surcharge: "Express surcharge",
        free: "Free",
        reserved: "Reserved",
        green: "Green",
      },
      jrReservation: {
        exYoyaku: "ＥＸ予約",
        exETokkyu: "ＥＸ予約(ｅ特急券)",
        exHayatoku: "ＥＸ予約(ＥＸ早特)",
        exHayatoku21: "ＥＸ予約(ＥＸ早特２１)",
        exGreenHayatoku: "ＥＸ予約(ＥＸグリーン早特)",
        smartEx: "スマートＥＸ",
        smartExHayatoku: "スマートＥＸ(ＥＸ早特)",
        smartExHayatoku21: "スマートＥＸ(ＥＸ早特２１)",
        smartExGreenHayatoku: "スマートＥＸ(ＥＸグリーン早特)",
      },
      facilities: "Transport facilities",
      expressOptions: "Express options",
      jrDiscount: "Discount",
      bus: "Bus",
      train: "Train",
      plane: "Plane",
      others: "Other options",
      icCard: "IC Card",
      fare: "Fare",
      onewayFare: "One-way fare",
      rideTime: "Ride time",
      distance: "Distance",
      inputAmount: "Enter the amount",
      report: "Expenses report title",
      personalReport: "Expenses report title",
      file: "File name",
      mergeWithAggregation: "Merge Card Statement",
      attachToExistingReport: "Attach to existing report",
      attachToExistingReportOrPreReport: "Attach to existing report/pre report",
    },
    labels: {
      noRefund: "No refund",
    },
    imports: {
      uploading: "Now uploading...",
      selectFormat: "Select format of csv",
      allImportResults: "Show all import results",
      defaultShow: "In normal, show 5 results of recents",
    },
    droparea: {
      click: "Please click here and select a {{ext}} file",
      or: "Or",
      dragAndDrop: "Drag and drop a {{ext}} file",
      filename: "<em><b>{{name}}</b></em> will be uploaded",
      image: "Image",
    },
    pdf: {
      failMessage:
        "※ PDF cannot be viewed now due to the browsers' technical issue. You can preview the file after the expense is registered.",
    },
    histories: {
      types: {
        add: "Add",
        edit: "Edit",
        delete: "Detach",
        split: "Split",
      },
      editorTypes: {
        system: "System",
      },
      fieldTypes: {
        amount: "Amount",
        transactedAt: "Date",
        shop: "Payee/Destination",
        isCorporate: "No Refund",
        route: "Route",
      },
      properties: {
        changedAt: "Changed At",
        changeLog: "Changes",
        editor: "Editor",
        project: {
          displayId: "ID",
          name: "Name",
        },
        amount: {
          value: "",
          originalValue: "(Foreign Currency)",
          originalCurrencyId: "Currency Code",
        },
        route: {
          onewayAmount: "One-way fare",
          origin: "Departure Station",
          destination: "Arrival Station",
          lines: "Route",
          via: "Route",
          route: "Route",
          isRoundTrip: "Round Trip",
          withIc: "IC Card",
        },
        receiptFile: {
          foreside: "Foreside",
          backside: "Backside",
        },
        originalReceipt: {
          match: "Match",
          cancel: "Cancel",
        },
        companion: {
          colleagues: "Our Company",
          clients: "Partner",
        },
        withholding: {
          amount: "Tax amount",
          name: "Recipient's name",
          address: "Recipient's address",
        },
        markAsMatched: {
          match: "Match",
          cancel: "Cancel",
          values: {
            false: "Not matched.",
            true: "Mark as matched.",
          },
        },
      },
      messages: {
        count: "There is a change history record",
        count_plural: "There are {{count}} change history records", // eslint-disable-line camelcase
      },
      titles: {
        originalReceiptDetails: "Original Receipt Details",
        receiptFileDetails: "Receipt File Details",
        history: "Edit histories",
      },
    },
    confirmationTitles: {
      confirmEmptyRequiredFields: "Confirmation of not filled required fields",
      confirmWithholding: "Auto calculation of withholding tax amount",
      confirmCancelReceiptMatching: "Receipt matching will be canceled",
    },
    messages: {
      success: "Your data have been successfully saved",
      create: "Created the data successfully",
      delete: "Deleted the data successfully",
      merge: "Merged the data successfully",
      deleteImage: "Deleted the image successfully",
      confirmDelete:
        "Are you sure you want to delete all the selected expenses?",
      detach: "Detached the data successfully",
      confirmDetach:
        "Are you sure you want to detach the expense?(Original expense doesn't delete)",
      confirmAllDetach:
        "Are you sure you want to detach all the selected expenses?",
      confirmEmptyRequiredfields:
        "Are you sure you didn't input fields of {{emptyRequiredfields}}?",
      confirmWithholding:
        "You are changing the expense category to the other which requires withholding tax. Would you like to make the withholding tax amount calculated automatically? (this will overwrite the tax amount.)",
      confirmMerge:
        "Of the {{selected}} selected expenses, you can integrate the below {{mergeable}}. Do you integrate them?",
      update: "Updated the data successfully",
      saveRotation: "Saved the rotation of the image",
      isCorporate: "Required no refund",
      isElectronicReceiptImage: "Tied to electronic receipts",
      isMerged: "Merged",
      fromWorker: "Entered by picture",
      fromCard: "Registered by accounts",
      foreignCurrency: "Entered in currency other than your basic one",
      confirmCancelReceiptMatching:
        "Receipt matching or marking as matched will be canceled after update the receipt image.",
      multipleTaxRates:
        "“●” is displayed on the expense whose expense category is associated with multiple tax rates in the settings.",
      uploadedBy: "Uploaded by {{name}}({{email}})",
      uploadedByYourself: "Uploaded by yourself",
      waitingToUpload: "Waiting to upload",
      expenseCanBeAttachedToAlreadyCreatedReportsOrPreReports:
        "Expense can be Attached to already created reports or pre-reports",
      expenseCanBeAttachedToAlreadyCreatedReports:
        "Expense can be Attached to already created reports",
    },
    errors: {
      duplicateReport:
        "You cannot use the expenses report title that has already been used",
      costAllocationDecimal: "Cost allocation percentage must be an integer",
      costAllocationNonPositive:
        "Cost allocation percentage must be a positive integer",
      costAllocationSumOfFraction:
        "The Sum of cost allocation percentages must be 100",
      costAllocationDuplicature: "Payment group are duplicated",
      didNotEnterCurrencyRate: "Enter the currency rate",
      amountDoedNotEqualTotalAmountPerTaxCategories:
        "Amount must be equal to the total amount for each tax rates.",
      noImage: "There is no receipt",
      noOrigin: "Enter the starting point",
      noDestination: "Enter the arrival place",
      noTransportFacilities: "Please select at least one transport facility",
      routeNotFound: "No route between stations is found",
      routeUnselected:
        "Select departure and arrival stations and search travel routes",
      communicationError:
        "A communication error has occured during searching for station names",
      failedToLoadCategory: "Failed to get account title's list",
      failedToCreateData: "Failed to create the expense data",
      failedToUpdateData: "Failed to update the expense data",
      failedToDelete: "Failed to delete the expense data",
      failedToDetach: "Failed to datach the expense data",
      failedToMerge: "Failed to merge the expense data",
      failedToSaveRotation: "Failed to save the rotation of the image",
      failedToUploadImage:
        "Failed to upload the receipt image. Please upload it again.",
      failedToSignTimeStamp:
        "Failed to sign a time stamp to the stamp. Please upload it again.",
      uploadLimitError:
        "The size of the receipt image is too large. Please try again with a file smaller than 25MB",
      failedToSearchForRoute: "Failed to search for routes",
      invalidDate: "Invalid date",
    },
    costAllocationModal: {
      title: "Cost Allocation Departmant Detail",
      costAllocation: "Cost Allocation",
      detail: "Detail",
    },
  },
  expenses: {
    split: {
      dropdownButtonItem: "Split by {{item}}",
      totalAmountsDisplay: {
        totalAmountBeforeSplit: "Total amount before split",
        totalAmountAfterSplit: "Total amount after split({{splitCount}} items)",
      },
      amountBySeparatorsEditor: {
        title: "Please enter the item and amount to be split.",
        totalAmount: "Total",
        addButton: "Split more",
        categorySelectTitle: "Please select expense category",
        projectSelectTitle: "Please select project",
        amountInputTitle: "Please input amount",
      },
      diffAmount: {
        overOriginalAmount: "{{diffAmount}} less",
        lessThanOriginalAmount: "{{diffAmount}} more",
      },
      warning: {
        makesManualInputedExpense:
          "The split expense becomes manually inputted expense.",
        resetTaxCategory:
          "If an expense category has 2 or more tax categories, only 1 tax category will be used for each amount of split expenses.",
      },
      confirmModalTitle: "Split Expense?",
      selectCategoryTitle: "Please select expense category",
    },
    timestamp: {
      inprogress: "Timestamp inprogress",
      failed: "Timestamp failed",
    },
    workerInputCorrectionRequest: {
      button: "Report incorrect input",
    },
    timestampVerify: {
      createdAt: "Created At",
      completedAt: "Completion Date",
      message: "Message",
      result: "Result",
      searchedTarget:
        "Performs timestamp verification on all expenses that meet the search conditions.",
      selectedTarget:
        "Performs timestamp verification on selected expenses ({{count}} invoices)",
      sample: "Click here to download a sample of the validation results.",
      error: "Expense's timestamp verification failed.",
      title: "Timestamp verification",
      warn: "Please note that time stamp verification may take some time to complete. Please understand that it may take some time to complete.",
      showAll:
        "Show all verified results (normally shows the 5 most recent results)",
      start: "Start verification",
      started: "Time stamp verification of expenses has been started.",
      completed: "Time stamp verification of expenses has been completed.",
      inprogress: "Expense's timestamp validation in progress.",
      unit: "Verified receipt image",
    },
    expenses: {
      components: {
        operatorEntryConfirmModal: {
          header: {
            title: "Confirmation",
          },
          body: {
            message1:
              "The automatically entered amount and consumption tax amount differ from the amount by tax category.",
            message2: "Do you want to save it?",
            subtotal: "{{amount}} (Tax {{taxAmount}})",
          },
          footer: {
            accept: "Accept",
            reject: "Reject",
          },
        },
        operatorEntryInfo: {
          title: "Auto Input Result",
          total: "Total",
        },
      },
    },
  },
  attachedFiles: {
    icons: {
      description: {
        notExist: "No File Attached",
        notFound: "File Not Found",
        error: "Cannot display the file image",
      },
    },
  },
  analyses: {
    titles: {
      aggregationHistory: "Aggregation History",
      applicationList: "Application List",
      reportTitle: "Expenses report title",
      personalReportTitle: "Expenses report title",
      preTravelReportTitle: "Travel request title",
      sequence: "Expenses report ID",
      userName: "Applicant name",
      applicantGroup: "Applicant group",
      paymentGroup: "Payment group",
      project: "Project",
      applicantPost: "Applicant's position",
      approvedApprover: "Approved approver",
      projectId: "Project ID",
      projectName: "Project name",
      tag: "Labels for the applicant",
      amount: "Total",
      preAmount: "Requested amount",
      status: "Status",
      aggregationStatus: "Aggregation status",
      exportationStatus: "Exportation status",
      paymentStatus: "Payment status",
      temporaryPaymentStatus: "Cash advance status",
      payOffStatus: "Pay off status",
      departmentCondition: "Department search scope",
      includeChildDepartment: "Include child departments",
    },
    searchbox: {
      openForm: "Specify search conditions",
      search: "Search with the condition",
      aggregate: "Aggregate with the condition",
    },
    toolbar: {
      aggregationButton: "Aggregate the {{length}} selected applications",
    },
    aggregationModal: {
      title: "Enter aggregate name",
      placeHolder: "Aggregate name",
      submit: "Create aggregation",
      errorNoData: "Enter aggregate name",
    },
    properties: {
      nothingApplicantGroup: "Applicant group is not set",
      nothingPaymentGroup: "Payment group is not set",
      noProjects: "No projects",
      multipleApplicantGroup: "Multiple applicant groups are set",
      multiplePaymentGroups: "Multiple payment groups are set",
      multipleProjects: "Multiple projects",
    },
    messages: {
      deleteReportSuccess: "Deleted the report successfully",
      deletePersonalReportSuccess: "Deleted the report successfully",
      changeReportTitleSuccess: "Changed the report title",
      changePersonalReportTitleSuccess: "Changed the report title",
      confirmDelete: 'Are you sure you want to delete the report "{{name}}"',
      confirmAnalysesExport: "Are you sure you want to export the analysis?",
      irreversibleOperation: "You cannot undo the change",
      failedToDeleteReport: "Failed to delete the report",
      failedToDeletePersonalReport: "Failed to delete the report",
      cannotPayReports:
        "Reports that cannot be completed by payment are included",
      cannotPayTemporarilyReports:
        "Reports that cannot be completed by temporary payment are included",
    },
  },
  reports: {
    types: {
      report: "Expenses report",
      preReport: "Pre-report",
      preTravelReport: "Pre-travel request",
      application: "Application",
    },
    titles: {
      expenses: "Available Expenses",
      personalExpenses: "Available Expenses",
      checkContents: "Check the contents ({{count}} result)",
      checkContents_plural: "Check the contents ({{count}} results)", // eslint-disable-line camelcase
      reportList: "Reports",
      personalReportList: "Reports",
      timeline: "Timeline",
      kinds: "The kinds of requests",
      list: "Reports",
      reapply: "Do you reapply the request with the current approval flow?",
      reportRequest: "Report Request",
      attachableExpenses: "Attachable expenses",
    },
    properties: {
      application: "Application",
      applicationName: "Application name",
      reportTitle: "Expenses report title",
      personalReportTitle: "Expenses report title",
      applicantName: "Applicant",
      amount: "Total amount",
      withholding: "Withholding tax total",
      preAmount: "Pre-report total",
      temporaryPaymentDueAt: "Payment due",
      reimbursableExpense: "Reimbursable expense",
      status: "Report status",
      statusForApprover: "Approve status",
      lastApprovedDate: "Final approval date",
      comment: "Comment",
      approvalFlow: "Approval flow",
      requestDate: "Request date",
      scheduleAt: "Payment Scheduled Date",
      sequence: "Expenses report ID",
      approvalFlowName: "Approval flow name",
      requestType: "Type",
      reportTypes: {
        report: "Expenses",
        noTemporaryPayment: "Settlement(Cash advance)",
        temporaryPayment: "Settlement(No cash advance)",
      },
      currentApprovalFlow: "current approval flow",
      matchedOriginalReceipt: "Matched original receipt",
      reportRequestTypes: {
        chooseFromExpenses: "Choose from requestable Expenses",
        chooseFromPreReports: "Choose from requestable PreReports",
      },
      preReportAndReport: "PreReport / Report",
    },
    index: {
      addReport: "Add reports",
      addPersonalReport: "Add reports",
      scope: {
        label: "Scope",
        all: "Whole company",
      },
      matchedOriginalReceipt: {
        label: "Original receipt confirmation",
        yes: "Finished",
        no: "Not yet",
        description:
          "You can search reports whose expenses have all been matched with the original receipts. Expenses without receipt images are considered as matched.",
      },
    },
    requests: {
      selectAll: "Select all",
      selectByProject: "Select projects",
      selectThisMonth: "Select this month's expenses",
      selectLastMonth: "Select last month's expenses",
      selectAdvancedPayment: "Me(refund)",
      selectCorporatePayment: "Company",
      selectPayer: "Select payers",
      clearSelection: "Uncheck all",
      submitAll: "Submit all the expenses",
      submitSelected: "Submit the selected expenses",
      saveAsDraft: "Save as draft",
      createReportOfAll: "Create the report of all the expenses",
      createReportOfSelected: "Create the report of the selected expenses",
      enterReportTitle: "Enter report title",
      enterPersonalReportTitle: "Enter report title",
      enterComment: "Enter some comments",
      showExpenses: "Show included expenses",
      detach: "Dettach from reports",
      submit: "Submit",
      applyNewApprovalFlow: "Apply new approval flow",
      useCurrentApprovalFlow: "Use current approval flow",
      temporaryPaymentProcessed: "Temporary payment processed",
      createBlankReport: "save draft report.",
      createReportWithSelectedExpenses:
        "save draft report with selected expenses.",
      youCanAddExpenseLaterAndRequest: "you can add expenses and report later.",
      saveDraftReport: "Save report as draft",
    },
    timeline: {
      markerMessage: "Approvals below are required",
    },
    messages: {
      submitSuccess: "You have submitted expenses successfully",
      deleteReportSuccess: "Deleted the report successfully",
      deletePersonalReportSuccess: "Deleted the report successfully",
      changeReportTitleSuccess: "Changed the report title",
      changePersonalReportTitleSuccess: "Changed the report title",
      confirmDelete: 'Are you sure you want to delete the report "{{name}}"',
      statusFormat: "Submitted at {{datetime}}",
      statusFormatPersonalPlan: "Saved at {{datetime}}",
      enterComment: "Please enter your message...",
      selectApprovers: "Please select your approvers.",
      exportSize: "You can export at most {{size}} reports at once.",
      noSelectableProjects: "No selectable Projects.",
      noSelectableReports: "No selectable Reports.",
      addExpensesSuccess: "Added expenses successfully",
      noData: "Report Not Found.",
    },
    errors: {
      failedToDeleteReport: "Failed to delete the report",
      failedToDeletePersonalReport: "Failed to delete the report",
      workerInProgress:
        "Cannot submit the report because some expenses are not created as data yet",
      failedToCreateData:
        "Cannot submit the report because some expenses could not be created as data",
    },
    searchConditions: {
      openForm: "Specify search conditions",
      ReportId: "Expenses report ID",
      ReportName: "Expenses report name (Keyword)",
      approvalFlowName: "Approval flow name",
      optionalDate: "Date (expenses)",
      requestDate: "Request date",
      lastApprovedDate: "Final approval date",
      approvedUser: "Approved user",
      IncludedInApprovalFlow: "Included in Approval Flow",
      applicantName: "Applicant's name",
      applicantDepartment: "Applicant's department",
      category: "Expense category",
      approvalStatus: "Approval status",
      approvalStatusForApprover: "Approval status for Approver",
      projectId: "Project ID",
      projectName: "Project name",
      includesAborted: '"Approval Flow Change Required" is included',
      searchWithReportTitle: "Search with report title",
    },
    modals: {
      actions: {
        createCsv: "Start creating the latest output file",
      },
    },
  },
  preReports: {
    properties: {
      title: "Pre-report title",
      content: "Content",
      amount: "Expenses amount",
      withholding: "Withholding tax total",
      preAmount: "Requested amount",
      applicantName: "Applicant",
      status: "Status",
      sequence: "Pre-report ID",
      project: "Project",
      department: "Department",
    },
    items: {
      addItems: "Add an item",
      anItem: "An item",
      errors: {
        amount: {
          blank: "Please enter amount.",
        },
        category: {
          blank: "Please select expense category.",
        },
        content: {
          blank: "Please enter content.",
        },
        date: {
          blank: "Please select date.",
          invalid: "Date is invalid.",
        },
      },
      menu: "Items",
    },
    titles: {
      list: "Expense Request Reports",
    },
    reportablePreApplications: "Reportable expenses requests",
  },
  preTravelReports: {
    titles: {
      list: "Pre-Travel Reports",
    },
  },
  temporaryPayments: {
    titles: {
      list: "Cash Advances",
    },
    properties: {
      title: "Cash advance report title",
      preAmount: "Amount",
      paidAt: "Payment date",
      amount: "Usage amount",
      withholding: "Withholding tax amount",
      refundAmount: "Refund amount",
      applicantName: "Applicant",
      status: "Status",
      sequence: "Report ID",
      paymentDate: "Payment date",
      refundDate: "Refund date",
    },
  },
  exports: {
    titles: {
      fileExport: "Export file",
      accountingData: "Accounting data",
      applicationForm: "Application form",
      preReport: "Export pre-report",
      temporaryPayment: "Export cash advance request",
      zengin: "Export zengin data",
      zenginAndAccountingData: "Export zengin data or accounting data",
      finishToExport: "Completed export accounting data",
      finishToPay: "Completed payments process",
      completedTemporaryPayment: "Completed temporary payments process",
      csvExport: "Export CSV",
      aggregationByUserExport: "Export aggregate by user",
      aggregationByCategoryExport: "Export aggregate by expense category",
      expenseAccountSelector: "Select payment account",
    },
    actions: {
      exportColumns: "Start creating a file with the search results",
      showModal: "Start creating a file with the search results",
      searchWithAccount:
        "Search with bank name, branch name, and bank account number",
      allExportResults: "Show 100 export results",
      defaultShow: "In normal, show 5 results of recents",
    },
    inputs: {
      recipient: "Recipient",
      selectFormat: "Select a format",
      sendEmail: "Send an e-mail",
      or: "or",
      download: "Download",
      requestAccountingCsv: "Start creating a file with the current data",
      downloadXls: "Download application form (xls format)",
      requestXlsZip: "Request to create application form (xls format)",
      requestAggregationByCategoryExportFile:
        "Request to create aggregate by account title(csv format)",
      requestAggregationByUserExportFile:
        "Request to create aggregate by employee (csv format)",
      downloadPreXls: "Download pre-repot request (xls format)",
      downloadTemporaryPaymentXls: "Download cash advance request (xls format)",
      requestedAt: "Creating started at",
      completedAt: "Creating completed at",
      exportType: "Format",
      processing: "Processing",
      format: "File format",
      downloadXlsx: "Start creating a file with the current settings",
      error: "error",
      result: "Export result",
      transactionDate: "Set transaction date",
    },
    messages: {
      sendEmailSuccess: "Sended an e-mail",
      emailFormat: "Please select e-mail addresses",
      exported: "Exported",
      auto: "(Automatic)",
      aggregatedManually: "Manually",
      aggregatedByScheduledExportsTask:
        "Automatic (by accounting data scheduled exports task)",
      enterTransactionDate: "Please enter transaction date",
      changeAnalysisTitleSucceed: "Analysis title has been changed.",
    },
    errors: {
      emailMissing: "Please select e-mail addresses",
      workerInProgress:
        "Cannot output the report because some expenses are not created as data yet",
      payeeAccountBlank:
        "Please make sure that the payee's account payable has been set.",
      payerCompanyAccountBlank:
        "Please make sure that the company's account payable has been set.",
    },
  },
  companions: {
    companion: "Participants",
    ourCompany: "Our Company",
    partner: "Partner",
    addCompanion: "Add participants",
    addOurs: "Add our company's participants",
    ours: "Our company's participants",
    addPartners: "Add the partner's participants",
    partners: "The partner's participant",
    name: "Name",
    number: "Number",
    companyName: "Company's name",
    group: "Department",
    companyPost: "Post",
    ourStaff: "A staff of our company : name or department",
    partnerStaff: "A staff of the partners : name, comapny name, etc",
    companionName: "Participant name",
    detail: "Detail",
    messages: {
      added: "The companion was added",
      edited: "The companion was edited",
      deleted: "The companion was deleted",
      notFound: "A companion is not found",
      confirmToDelete: "Are you sure you want to delete companion?",
    },
    title: {
      participantsList: "Participants List",
      participantImport: "Import Paticipant",
      addPaticipant: "Add Paticipant",
      editPaticipant: "Edit Paticipant",
    },
    actions: {
      importCSV: "Import",
      addParticipant: "Add participant",
      formatExplanation: "The format is Name, Company's Name, Depertment, Post",
      forSample: "Click here for CSV sample",
    },
  },
  imports: {
    startAt: "Start Time",
    endAt: "End Time",
    message: "Message",
    importResult: "Import Results",
  },
  projects: {
    project: "Project",
    none: "No project name",
    information: "Project information",
    deleted: "Deleted",
    imports: {
      importProjects: "Import projects.",
      limitOfNumberToImport:
        "The max number of projects to be imported once is 50,000.",
      sample: "Sample data",
    },
    titles: {
      addMember: "Assign Member to the Position",
      createProject: "Create a Project",
      details: "The Details of the Project",
      exportModal: "Export Projects",
      importModal: "Import Projects",
      postSettings: "Project Position Settings",
      projectList: "List of Projects",
      settings: "Project Setting",
    },
    properties: {
      name: "Project name",
      id: "Project ID",
    },
    actions: {
      searchPlaceholder: "Multiple words allowed",
      assignMembers: "Assign Members to the Position",
      selectProject: "Select the project",
      addProject: "Add a new project",
      searchByName: "Search by the user name",
      useProjectSetting: "Use Project Functions",
      employeeBuildProjects: "Allow Employees to Create Projects",
      addMember: "Add Member",
      searchWithDeleted: "Search also deleted projects",
    },
    buttons: {
      add: "Add",
      moveToExportModal: "Open export modal",
      moveToImportModal: "Open import modal",
    },
    messages: {
      confirmDelete: "This action will delete the project. Are you sure?",
    },
  },
  approvals: {
    titles: {
      reports: "Approvals",
      applicationApprovals: "Application Approvals",
      approvalFlowInfo: "Approval Flow information",
    },
    actions: {
      withdrawApproval: "Withdraw approval",
      approve: "Approve",
    },
    flows: {
      approver: "Approver",
      department: "Approvable Department",
      post: "Positions",
      departmentPost: "Department Post",
      userDepartment: "Department",
      payerDepartment: "Payer Department",
      projectPost: "Positions of Project",
      approvalStep: "{{order}} Step",
      notEditable: "Not editable",
      notSkippable: "Not skippable",
      selfApprovable: "Self-approvable",
      selfApprovalSkippable: "Skip approval",
      all: "All",
      someOne: "Some one",
      addStep: "Add a new approval step",
      addApprover: "Add an approver",
      noResults: "We cannot find any approver",
      empty: "Select when you request",
      addCondition: "Add condition",
      addAndCondition: "Add AND condition",
      addOrCondition: "Add OR condition",
      flowName: "Approval Flow Name",
      conditions: "Conditions",
      priority: "Priority",
      description: "description",
      specifiesDepartmentPosition:
        "upper-level department's executives will be specified.",
      rules: {
        include: "in",
      },
      options: {
        userDepartmentPosts: {
          upperLevelPostSelecting:
            "Assign approvers with a post on upper level departments",
          nearestPostsApproverSelecting:
            "Assign approvers with a post on nearest departments",
        },
        payerDepartmentPosts: {
          upperLevelPostSelecting:
            "Assign approvers with a post on upper level payer departments",
          nearestPostsApproverSelecting:
            "Assign approvers with a post on nearest payer departments",
        },
      },
      descriptions: {
        conditions: `
          Set the expense conditions to which approval flow is applied.\n
          Amount, expense category, project, department, etc, can be used as conditions.\n
          However, for approval flows of pre-reports, only expense category can be used.\n
          In addition, no conditions can be specified for application requests.
        `,
        priority: `
          Expenses will be divided into reports by applying the approval flows in the order of priority.\n
          The registered priority cannot be used.

        `,
        notEditable: `
          The reporter cannot edit or delete the approval step when submitting the expense report.\n
          Please uncheck if the reporter can change the approvers.

        `,
        notSkippable: `
          The report cannot be submitted if no approvers can be found.\n
          (e.g. When a department has no manager.)
        `,
        preReportCondition:
          "This condition is only applicable to the pre report.",
        selfApprovable:
          "Please check if the reporter themselves can approve their reports in case the approvers include the reporter.",
        selfApprovalSkippable:
          "Please check if the approval step is skippable in case the approvers include the reporter.",
        upperLevelPostSelecting:
          "Approval requests will only be sent to executives who belong to a specific higher-level department, such as the upper-level department or its upper-lebel department.",
        nearestPostsApproverSelecting:
          "If there are executives in each upper-level department or upper-level department, approval requests will be sent only to the executives who belong to the department closest to the reporter.",
      },
      labels: {
        preReport: "Pre report",
        totalAmount: "Total amount",
        post: "Post",
      },
      // マニュアル変更が必要なため今は他箇所に合わせて承認フローではなく申請フロー表記とする(CS確認済み)
      search: {
        userNameOrEmail: "User name or E-mail",
        departmentName: "Department",
        approvalFlowName: "Approval flow name",
        requestType: "Kinds of requests",
        post: "Positions",
        selectApprovalFlow: "Select the approval flow",
      },
    },
    search: {
      openForm: "Specify search conditions",
    },
    messages: {
      stepConstraint:
        "If you add a department, that approval will be done by one of the approvers in the department.",
      stepEmpty: "If empty steps exist, these steps are skipped.",
      rejected: "You refered the report back to the applicant",
      approved: "You approved the report",
      withdrewApproval: "You withdrew approval",
      memberUnassignedApprovalFlows:
        "There are employees unassigned to approval flows.",
      approverSelectFlowConstraint:
        "This flow is that approver must select next approver at approve.",
    },
  },
  notifications: {
    types: {
      expense: "Notifications about expenses",
      request: "Notifications about requests",
      account: "Notifications about corporate cards",
      originalReceipt: "Notifications about original receipts",
    },
    actions: {
      editExpense: "Edit expenses",
      matchingExpense: "Select matching expense",
      businessUse: "Business use",
      privateUse: "Private use",
      informAdministrators: "Inform your administrators",
    },
    actionTitle: {
      ProcessOriginalReceipt: "Process the original receipt",
      SubmitReceipt: "Did you submit this receipt?",
      privateUse: "Private use",
    },
    properties: {
      message: "Message",
      cardType: "Card type",
      cardName: "Card name",
    },
    messages: {
      approvalPending: "Approval is pending",
      unknownCategory: "Select the account title",
      requireCompanion: "Select participants",
      setCompanions: "Set participants",
      mistakenlySubmit: `
        In case you mistakenly submit a receipt for private use, /n
        you can inform your administrators that it was not for business use. /n
        Your administrators will disregard this receipt.
      `,
      linkedAggregation: "New details have been created",
    },
    errors: {
      failedToLoad: "Failed to load. Please reload the screen.",
    },
  },
  userPreferences: {
    titles: {
      mailNotification: "Mail Notification Settings",
      editCompanions: "Participant Settings",
      agentSettings: "Agent Settings",
    },
    mailNotification: {
      mailNotificationSettings: "Mail Notification Settings",
      applicationNotice: {
        title: "Notifications about request",
        onApproved: "When approved",
        onRejected: "When rejected",
        onCanceled: "When approval is canceled",
        onCommented: "When there is a comment",
      },
      noticeToApprover: {
        title: "Notifications about approval",
        onNewApplication: "When there is a new application requiring approvals",
        onWithdrawn: "When withdrawn",
        onRejected: "When rejected by other approvers",
        onCommented: "When there is a comment",
      },
      agentNotifcation: {
        title: "Notifications about agent",
        onRequestAndApprove: "When your agent applies or approves a report",
        onApproved: "When a report you applied as an agent is approved",
        onRejected: "When a report you applied as an agent is rejected",
        onCanceled: "When a report you applied as an agent is canceled",
        onCommented:
          "When a comment is added to a report you applied as an agent",
      },
      otherNotification: {
        title: "Receipt Notifications",
        onCompleted: "Receipt automatic input completion/failure",
        onCreatedCorporateAggregation: "When linking corporate card details",
        notSubmitted:
          "When there are expenses not yet submitted after cutoff date",
        onReceivedExpectedPaymentDate:
          "When you receive notification about payment date",
        onReceivedExpectedTemporaryPaymentDate:
          "When you receive notification about temporary payment date",
        onReceivedOriginalReceiptIdentificationRequest:
          "When you received an original receipt identification request",
        help: {
          corporateAggregationDescription:
            "Only corporate cards registered by administrators are subject to this setting",
        },
      },
    },
    editCompanions: {
      ourCompanions: "Our company's participant",
      clientCompanions: "The partner's participant",
      ourCompanionEditModal: {
        title: "Enter information of our company's participant",
        nameLabel: "Name",
      },
      clientCompanionEditModal: {
        title: "Enter information of the partner's participant",
        nameLabel: "Name",
        companyLabel: "Company",
      },
    },
    agentSettings: {
      addAgentModal: {
        button: "Add an agent",
        title: "Add an agent",
        selectAgent: "Select an agent",
        addAuthority: "Add authority",
        unknownName: "Unknown name",
      },
      agentTable: {
        agentName: "Agent name",
        expenseRequest: "Expenses",
        canMakeExpensesAndSubmit: "Add expenses and submit reports",
        canApprove: "Approve reports",
      },
      errorMessage: {
        user: {
          required: "Please select a user",
        },
        authority: {
          required: "Please select at least one authority",
        },
      },
    },
  },
  preferences: {
    titles: {
      account: "Account Settings",
      commuterPass: "Commuter Ticket",
      category: "Expense category",
      closingDate: "Closing Date Setting",
      businessCategory:
        "Customize the mapping between categories of business and expense categories",
      sso: "Single Sign-On Settings",
      expenseAccount: "Expense Transfer Account Setting",
      companyExpenseAccount: "Payment Account Setting",
      teiki: "Commuter Pass Setting",
      commentFormat: "Comment Template",
      detailFormat: "Detail Template",
      accountTitle: "Account Titles Setting",
      alertRuleList: "Alert Rules",
      expenseAlertRuleList: "Expense Alert Rules",
      expenseAlert: "Expense Alert",
      autoCategorizeRule: "Apply automatic expense category setting",
      editTaxCategory: "Edit Tax Category",
      noRefund: "No refund",
      emptyPreTransaction: "Pre-report expenses",
      corporateCardsAccountList: "Corporate card holders",
      password: "Change Password",
      suppliers: "Supplier Setting",
      securitySettings: "IP Address Restriction",
      currencies: "Foreign Currency Setting",
    },
    users: {
      inputs: {
        name: "Account name",
        email: "E-mail",
        locale: "Language",
        employeeId: "Employee ID",
        newPassword1: "New Password",
        newPassword2: "Confirm New Password",
        oldPassword: "Current Password",
      },
      locale: {
        ja: "日本語",
        en: "English",
      },
    },
    reports: {
      titles: {
        reportNameFormat: "Expenses report title format",
        preReportNameFormat: "Pre-report title format",
        reportRemarksFormat: "Report remarks format",
        exportedRequestFileDisplayGroupName:
          "Exported request file display group name",
      },
      descriptions: {
        commentFormat:
          "You can set the default comment when filling the expenses.",
        detailFormat:
          "You can set the default detail when filling the pre-report.",
        editTaxCategory:
          "You can set the tax category name regardless of the expense category when filling the expenses.",
        noRefund:
          "You can switch between display and non-display of no refund targets, when filling the expenses.",
        reportNameFormat:
          "You can set the expenses report title to be entered in advance when creating the report.",
        preReportNameFormat:
          "The default value for pre-report title can be set",
        specialSymbols:
          "The following special symbols can be used for the name.",
        reportRemarksFormat1:
          "You can set the items displayed in the remarks column when outputting the report xls file.",
        reportRemarksFormat2: "You can use following special formats",
        exportedRequestFileDisplayGroupName:
          "When outputting the expense reimbursement application form, you can set the company name to be displayed as the recipient.",
      },
      examples: {
        example: "example: ",
        reportNameFormat: {
          placeholder: "{year}_{month}_expense",
          input: "{year}_{month}_expense",
          outputParser: "_",
          output: "_expense",
        },
        preReportNameFormat: {
          placeholder:
            "{submissionyear}_{submissionmonth}_pre_report_{username}",
          input: "{username}_pre_report",
          output: "John Smith_pre_report",
        },
        reportRemarksFormat: {
          placeholder: "{comment}  【project name】{projectname}",
          input: "{comment}  【project name】{projectname}",
          output:
            "The expenses for the business trip. 【project name】 project A",
        },
        exportedRequestFileDisplayGroupName: {
          placeholder: "株式会社TOKIUM 御中",
        },
      },
    },
    requests: {
      approval: {
        approvalSetting:
          "Approval setting (expense application, advance application, general-purpose application)",
        bulkApprove: "Enable bulk approval",
        bulkApproveDesc:
          "Approvers will be able to approve multiple expense applications, pre-applications, and general-purpose applications at once.",
        bulkApproveChanged: "Updated bulk approval settings",
      },
    },
    teiki: {
      route: "Route",
      startAt: "Start Date",
      expireAt: "Expiry Date",
      notFound: "Commuter pass is not found.",
      titles: {
        add: "Add commuter pass",
        edit: "Edit commuter pass",
      },
      search: "Search with the condition",
      terms: {
        oneMonth: "One month",
        threeMonths: "Three month",
        sixMonths: "Six month",
      },
      fee: {
        oneMonth: "One month fee",
        threeMonths: "Three month fee",
        sixMonths: "Six month fee",
      },
      information:
        "The commuter pass setting is locked. In case you need to change your setting, please ask the administrator to unlock.",
    },
    expenseAccount: {
      bank: "Bank Name",
      bankBranch: "Branch Name",
      type: "Account Type",
      ordinary: "Ordinary",
      current: "Current",
      saving: "Saving",
      number: "Number",
      holderKana: "Holder Name",
      customerCode: "Customer Code",
      account: "account",
      transferFee: "transfer fees",
      eqOrHeigher: "equal or heigher than",
      lower: "lower than",
      sameBankBranch: "same bank branch",
      sameBank: "same bank",
      otherBank: "other bank",
      to: "transfer to",
      amount: "transfer amount",
      yen: "yen",
      transferFeeHelp:
        "transfer fees will be taken into account when exporting InvoicPost report as Zengin format",
      openTransferFeeHelp: "Impact on Zengin data",
      transferFeeHelpText:
        "The amount of money to be transferred is automatically calculated by referring to the payee (*1) that is set when the Zengin data export and the payee (*2) that is set on the invoice.",
      holderKanaHelp:
        "You can enter hiragana, katakana, alphanumeric characters, and symbols 【()-/.,】.\nInput is automatically converted to single-byte capital letters when saved.\nA muddled or semi-muddled sound is counted as two characters after half-width conversion.",
      titles: {
        add: "Add account",
        edit: "Edit payment account",
      },
      information:
        "Bank account settings has been locked. If you need to add or edit an account, please contact to the administrator.",
    },
    sso: {
      provider: "ID provider",
      email: "E-mail",
      actions: {
        add: "Link Account",
        destroy: "Unlink Account",
      },
    },
    ssoAvailable: {
      title: "Single Sign On",
      description:
        "Set whether to permit employees to use single sign-on. <br><br><b>Note: </ b> If you turn off this setting, employees who are signing in with single sign-on will be signed out from all devices, and each employee's single sign-on settings will be deleted.",
      confirmationModal: {
        buttonText: "Disable",
        content:
          "Employees signing in with single sign-on will be signed out from all devises, and each employee's single sign-on settings will be deleted.",
      },
    },
    closingDates: {
      tabTitles: {
        expense: "TOKIUM経費精算",
        invoice: "TOKIUMインボイス",
      },
      titles: {
        setting: "Expenses Closing Date Settings",
        invoiceSetting: "Payment Requests Closing Date Settings",
        add: "Add Closing Date",
        edit: "Edit Closing Date",
      },
      text: "Cutoff Day",
      add: "Add cutoff day",
      transactedAtFrom: "Transacted Date(from)",
      transactedAtTo: "Transacted Date(to)",
      accountingDateFrom: "Accouting Date(from)",
      accountingDateTo: "Accounting Date(to)",
      requestableDateFrom: "Requestable Period(from)",
      requestableDateTo: "Requestable Period(to)",
      notificationDate: "Notification Date",
      descriptions: {
        closingDateSetting:
          "You can set the requestable period for each expense use date.",
        invoiceClosingDateSetting:
          "You can set the requestable period for each invoice accounting date.",
        toEnableSetting:
          "However, it functions only when the requestable period check in expense alert setting is valid.",
        toEnableSettingInInvoice:
          "However, it functions only when the requestable period check in invoice alert setting is valid.",
      },
      term: "(every month)",
      after: "days",
      noticeAt: "Notice at",
      rowDate: "{{date}} (Every month)",
      rowNoticeAt: "{{date}} days after the cutoff day",
      endOfMonth: "End of month",
    },
    businessCategories: {
      header: "Mapping between categories of business and account titles",
      modalHeader:
        "Update mapping between categories of business and account titles",
      businessCategoryName: "Business name",
      categoryName: "Expense category",
      correspondingCategory: "Corresponding expense categories",
      priority: "Priority",
    },
    externalCategoryMaps: {
      messages: {
        changeSuperCategoryNameSuccess: "Updated the name successfully",
      },
    },
    externalTaxCategoryMap: {
      messages: {
        changeSuperTaxCategoryNameSuccess:
          "Updated the tax category name successfully",
      },
    },
    rootCategories: {
      settings: "Expense Category Setting",
      add: "Add a new expense category",
      showDisabledCategories: {
        setToTrue: "Show disabled categories",
        doneSettingToTrue: "Now showed disabled categories",
        doneSettingToFalse: "Now hid disabled categories",
      },
      categoryName: "Expense category",
      superCategory: "Account title",
      subCategory: "Sub category",
      requiresReceipt: "Receipt",
      requiresCompanion: "Participants",
      requiresOriginAndDestination: "OriginAndDestination",
      requiresVisit: "Visit",
      requiresPurpose: "Purpose",
      requiresPreReport: "Pre-report",
      requiresWithholding: "Withholding Tax",
      requiresMemoInput: "Memo input",
      eligibleInvoiceConfirmation: "Qualified Invoice Confirmation",
      notRequiresReceivingInvoice: "Qualified Invoice",
      requiresRegistratedNumber: "Registrated Number",
      requiresPaidAddress: "Paid Address",
      genericFieldDataSets: {
        name: "Generic Field Data Sets",
        required: "Required",
        optional: "Optional",
        single: "Single",
        multiple: "Multiple",
      },
      sort: "Sort",
      taxCategory: "Tax category",
      memoTemplate: "Memo",
      usePreReportItem: "Used in pre-report item",
      required: "Required",
      necessary: "Necessary",
      noNeed: "No Need",
      enable: "enable",
      enabling: "enable",
      disabling: "disable",
      regardAs: "Yes",
      notRegardAs: "No",
      edit: "Edit",
      create: "Create",
      category: "Expense category",
      parentCategory: "Parent expense category",
      editingParent: "Set as a parent expense cateogry",
      nothing: "nothing",
      defaultTransitCategory: "Default Transport Expense Category",
      displayPreference: {
        title: "User setting of showing or hiding expense categories",
        text: "Each user can show or hide each expense category. Maintain current settings in every account?",
        overwrite: "Show it to every account",
        maintain: "Maintain current settings",
      },
      errors: {
        messages: {
          canNotSelectDeletedTaxCategory:
            "can not select deleted tax categories",
        },
      },
      placeholders: {
        notEntered: "Not Entered",
      },
      alert: "Alert",
      help: {
        canNotEditEnableWithoutEnabledChild:
          "You can't edit because there is no enable child expense category.",
        canNotEditUsePreReportItem:
          "You can't edit because there is no enable child expense category that is used in pre-report item.",
        canNotEditUsePreReportItemWhenDisable:
          "You can't edit because the expense category is disabled.",
        checkChangeMemoFieldInput:
          "Set whether it is necessary to enter (additional notes, etc.) in the memo field when creating expenses.",
        isEditingParentCanBeChangedOnlyOnce:
          "Once you register the expense category, you cannot change parent expense category to normal expense category or vise versa.",
        requiresWithholdingCanBoChangedOnlyOnce:
          'Once you register the expense category, you cannot change withholding tax from "Necessary" to "Not necessary" or vise versa.',
        usePreReportItem:
          "You can set wether the expense category is used in pre-report item.",
      },
    },
    taxCategorySelections: {
      title: "Tax Category Setting",
      name: "Name",
      taxRate: "Tax rate",
      validPeriod: "Valid period",
      sort: "Sort",
      setting: "Set",
      errors: {
        messages: {
          noSelection: "Please select at least one tax category",
          invalidSort: "Please input positive number for sort",
        },
      },
    },
    personalCategories: {
      displaySetting: "Expense Category Setting",
      accountTitle: "Expense category",
      valid: "valid",
      noData: "There are no data",
      messages: {
        enabled: "{{name}} has been enabled",
        disabled: "{{name}} has been disabled",
      },
    },
    format: {
      comment: "Comment Template",
      detail: "Detail Template",
      placeholder: {
        reportname: "Report name",
        reportid: "Report ID",
        prereportid: "Pre-report ID",
        username: "Applicant name",
        userid: "Employee ID",
        userdepartment: "Department",
        date: "Request date",
        email: "Applicant's e-mail",
        category: "Account title",
        parentdisplaycategory: "Parent expense category",
        displaycategory: "Exoense category",
        subcategory: "Sub category",
        taxcategoryname: "Tax category Name",
        transactiondate: "Used date",
        shopname: "Shop name",
        projectname: "Project name",
        projectid: "Project ID",
        comment: "Comment",
        foreigncurrencycode: "Foreign Currency Code",
        exchangerate: "Exchange Rate",
        foreigncurrencyamount: "Foreign Currency Amount",
        companionnum: "Number of participants",
        companions: "Participants",
        companiondetails: "Details of participants",
        clientinc: "Client company names",
        clientnum: "Number of client participants",
        clients: "Client participants",
        clientdetails: "Details of client participants",
        employeenum: "Number of employees participants",
        employees: "Employee participants",
        employeedetails: "Details of employee participants",
        originbycategory: "Departure place",
        destinationbycategory: "Arrival place",
        visitbycategory: "Visiting Place",
        purposebycategory: "Purpose",
        department: "Department",
        submissionyear: "Year of submission",
        submissionmonth: "Month of submission",
        submissionday: "Day of submission",
        year: "Year of the oldest expense",
        month: "Month of the oldest expense",
        day: "Day of the oldest expense",
        reimbursable: "Reimbursable or not",
        bank: "ex:Mitsui Sumitomo",
        bankBranch: "ex:Ginza",
        number: "ex:1234567",
        holderKana: "ex:Taro Yamada",
        customerCode: "ex:0111234567",
        nameOrEmail: "Input Employee name or email",
        origin: "Departure station",
        destination: "Arrival station",
      },
    },
    paymentDate: {
      settings: {
        title: "Setting of date to be paid",
        useSetting: {
          title: "Registration date to be paid",
          description: "You can set a date to be paid at payment processing",
        },
      },
      labels: {
        dateToBePaid: "Date to be paid",
        notificationMessage: "Notification message",
      },
    },
    temporaryPaymentDate: {
      settings: {
        useSetting: {
          preTitle: {
            useDkRequests: "Expense",
            usePaymentRequest: "Invoice",
          },
          title: "Registration date to be temporarily paid",
        },
      },
      labels: {
        dateToBePaid: "Date to be temporarily paid",
        notificationMessage: "Notification message",
      },
    },
    accountTitle: {
      add: "Add a new account title",
      edit: "Update the account title",
      destroy: "Delete the selected account titles",
      list: "List of Account Titles",
      properties: {
        name: "Account Title",
        yomi: "Kana",
        taxCategory: "Tax Category",
        code: "Code",
        defaultCreditCategory: "Default credit account title",
      },
      messages: {
        deleteMultiple: "Deleted {{count}} account titles",
      },
      errors: {
        taxCategoryNotSelected: "The tax category is not specified",
      },
    },
    taxCategories: {
      taxCategoriesSetting: "Tax Categories Setting",

      column: {
        taxCategoryName: "Name",
        taxRate: "Tax rate",
        startDate: "Start date",
        endDate: "End date",
        taxRatePercentage: "Tax rate(%)",
        eligible: "Eligible",
        eligibleName: "Eligible Tax Category name",
      },
      button: {
        add: "Add tax category",
        restore: "Restore tax category",
      },
      operation: {
        add: "Add tax category",
        edit: "Edit tax category",
      },
      messages: {
        deletedCategoryIsnotExist: "No tax categories have been deleted.",
        taxCategoriesHaveBeenDeleted: "Tax categories below have been deleted.",
        dataUnchanged:
          "The data relating to deleted tax categories, such as expenses and expense categories, have not been affected.",
        getFailed: "Tax Categories get failed.",
        notChangeEligibleTaxCategory:
          "Eligible Tax category cannot be changed if used on an expense or Invoice line.",
        notChangeTaxRate:
          'Tax Rate cannot be changed because "Uneligible Tax Category" is used on the expense or Invoice line.',
      },
      form: {
        label: {
          eligibleOrUneligible: "Eligible/Uneligible",
          eligibleTaxCategory: "Eligible Tax Category",
          eligible: "Eligible",
          uneligible: "Uneligible",
        },
      },
    },
    alertRules: {
      levels: {
        ignore: "Off",
        warning: "Warning",
        error: "Error",
      },
      name: "Rule name",
      description: "Definition of Rule",
      level: "Alert Level",
      setting: "Setting",
      link: {
        linkToGenericFieldsText: "Link to generic fields",
      },
      requestablePeriod: {
        period: "Available Period",
        nextCutoffDay: "next cutoff day",
        prevCutoffDay: "previous cutoff day",
        messages: {
          update: "Updated available period",
        },
      },
      autoInputEditRule: {
        autoInput: "Auto Input",
        externalLink: "External Link",
        transactedAt: "Date",
        shopName: "Shop Name",
        amount: "Amount",
        route: "Route",
        externalLinkDescription: "IC Card, Credit Card, etc",
        isUnenterdCheckedDescription:
          "Changes when items are not entered are not targeted",
      },
      ruleNames: {
        stampedWithinDueDateRule: "TimeStamp Validation",
      },
    },
    editTaxCategory: {
      header: "Edit tax category",
    },
    editNoRefund: {
      header: "Edit no refund",
    },
    showDistance: {
      title: "Show distance",
      attention: "You can enable this option to show distance of transit.",
      description: "Show distance",
    },
    emptyPreTransaction: {
      title:
        "You can enable this option to allow users to submit a pre-report without expenses.",
      header: "Submit a pre-report without expenses",
    },
    icCardUseOption: {
      title: "IC fare by default",
      description:
        "Indicate whether your employees' public transportation fare was calculated as IC or as a ticket.",
    },
    icCardDeductionOption: {
      title: "Commuter pass deduction",
      description:
        "Commuter pass deduction will be automatically applied to expenses with IC cards.",
    },
    requestTypes: {
      title: "Request Types List",
      name: "Request Type Name",
    },
    password: {
      password: "New Password",
      passwordConfirmation: "Confirm New Password",
      currentPassword: "Current Password",
      description:
        "・Password must be between 8 and 256 characters long\n・Must include at least 3 of the following: lowercase, uppercase, numbers, and symbols",
    },
    messages: {
      updatedAccount: "Updated your account information",
      AddedCutoffDay: "Add your cutoff day setting",
      updatedPassword: "Password has been changed",
    },
    errors: {
      failedToUpdateAccount: "Failed to update your account information",
    },
    autoCategorizeRule: {
      target: "Target",
      regex: "Condition",
      category: "Expense category name",
      state: "State",
      delete: "Delete",
      titles: {
        add: "Add category setting",
      },
    },
    listShow: {
      titles: {
        approvalFlowName: "List options",
      },
      descriptions: {
        approvalFlowName:
          "Show approval flow name in report list and approval list",
        projectName: "Show project name in report list and approval list",
        withholding:
          "Show withholding tax amount in report list and approval list",
      },
    },
    suppliers: {
      titles: {
        blockConfirmation: "Block Confirmation",
        unblockConfirmation: "Unblock Confirmation",
      },
      imports: {
        title: "Import suppliers",
        importSuppliers: "Import suppliers.",
        sample: "sample file",
        guide:
          "The detailed instructions are on the column P of the sample file. Please leave the column blank when importing",
      },
      messages: {
        blockConfirmation: "Do you stop using this supplier?",
        unblockConfirmation: "Do you use this supplier?",
      },
      searchConditions: {
        blocked: "Show blocked suppliers",
      },
      actions: {
        new: "Add new supplier",
        update: "Update the supplier",
        block: "Block",
        unblock: "Unblock",
      },
      properties: {
        code: "Supplier code",
        name: "Supplier name",
        willPayAt: "Payment due date",
        specify: "Specify",
        notSpecify: "Not specify",
        willPayOfMonth: "Payment due date of month",
        willPayOfDay: "Payment due date of day",
        willPayForwardSetting: "Holiday setting (Follow the calendar)",
        willPayForward: "Holiday setting",
        forward: "Forward",
        back: "Back",
        willPayFee: "Will pay fee setting",
        wePayFee: "We pay fee",
        theOtherPayFee: "The other pay fee",
        editableOnRequest: "Editable setting",
        editable: "Editable",
        notEditable: "Not editable",
      },
      descriptions: {
        code: "If you update suppliers using an importing, please enter supplier code.",
        editableOnRequest:
          "Enables to modify payment due date and payment fee.",
      },
      errors: {
        name: {
          required: "Please enter supplier name",
        },
        willPayOfMonth: {
          required: "Please select will pay of month",
        },
        willPayOfDay: {
          required: "Please select will pay of day",
        },
        willPayForward: {
          required: "Please select holiday setting",
        },
        willPayFee: {
          required: "Please select will pay fee setting",
        },
        bank: {
          required: "Please select bank name",
        },
        bankBranch: {
          required: "Please select branch name",
        },
        number: {
          required: "Please enter a seven-digit number",
        },
        holderKana: {
          required: "Please enter holder name",
        },
      },
    },
    currencies: {
      refCurrency: "Key Currency",
      common: {
        period: "Applicable Period",
        currency: "Currency",
        edit: "Edit",
        delete: "Delete",
        cancel: "Cancel",
      },
      tables: {
        title: "Exchange Rates List",
        rate: "Configure Rate",
        configChanged: "Changed Configuration",
      },
      buttons: {
        changeCurrencyButton: "Change Key Currency",
        addCurrencyButton: "Add Currency",
        importCsvButton: "Import",
        addRateButton: "Add Rate Setting",
        updateButton: "Update",
        addButton: "Add",
        historyButton: "View past rates",
      },
      modal: {
        changeCurrencyTitle: "Change Key Currency",
        importTitle: "Currency Rate Import",
        confirmTitle: "Delete Setting",
        rateFormTitle: "Exchange Rate Setting",
        formatInfo:
          "You need to register Currency Code, Base Currency Code, Rate, Fraction Handling, beginning and ending of applicable period.",
        autoUpdate:
          'If you specify automatic acquisition, please make "変換レート" column "自動取得" or blank.',
        sampleLink: "Click here to download CSV samples.",
        warnNotUndo: "This action cannot be undone.",
        deleteConfirm: "Are you sure want to delete this rate setting?",
        changeBaseInfo:
          "This operation will also delete all exchange rate settings.",
        changeBaseConfirm: "Are you sure want to proceed?",
        autoRefresh: "Automatic Rate Acquisition",
        rate: "Exchange Rate",
        fraction: "Fraction Handling",
        roundUp: "Round Up",
        roundOff: "Round Off",
        roundDown: "Round Down",
        pastRateNotFound: "There is no viewable rate.",
        startDate: "Start Date",
        expiryDate: "Expiry Date",
      },
      rootCategories: {
        showPastExchangeRates: "show expired exchange rates",
      },
    },
    analysesOutputStatus: {
      title: "Export status of accounting data",
      preTitle: {
        useDkRequests: "Expense",
        usePaymentRequest: "Invoice",
      },
      attention: "About completed export status of accounting data",
      description:
        "It is altered automatically to the completed export status when accounting data is exported",
    },
    journalEntriesExportSetting: {
      title: "Journal entries export setting",
      labels: {
        availableExportFormatToEveryone:
          "Make journal entries/customized formats available to non-aggregator users",
      },
    },
    requiredCompanionRule: {
      description:
        "Display alert if only expense's owner is registered as participant.",
    },
    currency: {
      actions: {
        addRateConfig: "Add Exchange Rate Config",
        addCurrency: "Add Currency",
        importFromCSV: "Import From CSV",
      },
      importForm: {
        title: "Foreign Currency Rate",
      },
      confirmationModal: {
        title: "Delete Configuration",
      },
      table: {
        title: "Exchange Rates",
        header: {
          currency: "Currency",
          exchangeRate: "Exchange Rate",
          applicablePeriod: "Applicable Period",
          actions: "Actions",
        },
      },
      description: {
        autoUpdate:
          'If you specify automatic acquisition, please make "変換レート" column "自動取得" or blank.',
        format:
          "The format is 通貨コード,基準通貨コード,変換レート,端数の取り扱い,適用開始日,適用終了日.",
        fromSample: "Click here for CSV Sample",
        cannotCancel: "You cannot undo this operation.",
        askDelete: "Are you sure you want to delete?",
      },
    },
    securitySettings: {
      ipRestrictionsOptionCard: {
        alert:
          "IP Access Restriction is paid option, please contact support if you would like to use",
        warning:
          "Before turning on address restriction feature, please input some specified IP addresses.",
        description: "Only allow access from the specified IP addresses.",
        note1:
          "Only allow access from the specified IP addresses in the allowes IPs.",
        note2: "Up to 1,000 IP addresses can be registered.",
        changeEventsLink: "History Change",
      },
      ipRestictionChangeConfirmationModal: {
        title: "Changing IP Access Restrictions",
        enable: "Enable",
        disable: "Disable",
        toEnableAlert:
          "When IP Access Restriction is enabled, access to the site is blocked from all but the authorized IP addresses. Do you want to change this?",
        toDisableAlert:
          "When IP Access Restriction is disabled, access to the site from any IP addresses. Do you want to change this?",
      },
      allowIpCard: {
        title: "Allowed IP address list",
        search: {
          title: "Specify search conditions",
          reset: "Reset search conditions",
          submit: "Search with the condition",
        },
        addModal: "Create IP Address",
        updateModal: "Update IP Address",
        ipAddress: "Ip Address",
        comment: "Comment",
        cidr: "CIDR",
        add: "Add IP Address",
        import: "Import",
        export: "Export",
        edit: "Edit",
        date: "Register date",
        delete: "Delete",
        deleteAll: "Delete IP Address",
        importModal: {
          title: "Import allowed IP address",
          description1: "Import (add, update, delete) Allowed IP address.",
          description2: "Click here for the template.",
          description3:
            "Please be sure to specify the IP address and comment column.",
          description4: "To add, leave the ID column empty.",
          description5:
            "To update the file, please use the export file. Please do not change the ID column when editing.",
          moveToExportModal: "Open export modal",
          alert1: "IP access restrictions are in effect.",
          alert2:
            "The imported allowed IP addresses will immediately become subject to IP access restrictions.",
        },
      },
      changeEventsCard: {
        title: "Change History",
        search: {
          title: "Specify search conditions",
          startDate: "Start date",
          endDate: "End date",
          employeeEmail: "Employee or email address",
          ipAddress: "IP Address",
          comment: "Comment",
          reset: "Reset search conditions",
          submit: "Search with the condition",
        },
        added: "Add",
        updated: "Update",
        deleted: "Delete",
        enabled: "Enable",
        enabledIpRestriction: "All Permission　→　Partial Permission",
        disabled: "Disable",
        disabledIpRestriction: "Partial Permission　→　All Permission",
        imported: "Import",
        changeType: "Change Type",
        changeDate: "Change Date",
        editor: "Editor",
        details: "Description",
      },
      allowIpTableExportModal: {
        title: "Export allowed IP address",
        button: "Export in CSV Format",
        moveToImportModal: "Open import modal",
      },
    },
    security: {
      subMenu: {
        ipRestriction: "IP Restriction",
        singleSignOn: "Single Sign On",
        subdomain: "Subdomain",
        samlSetting: "SAML Settings",
        samlAccount: "SAML Accounts",
      },
      subdomain: {
        title: "Subdomain",
        create: "Create",
        description:
          "By registering a subdomain, you can restrict the login method and use SAML authentication functions.",
        warning:
          "Saving the subdomain changes the login URL and requires all employees, including administrators, to log in again. Please contact the employee with the new login URL in advance.",
        checkAvailable: "Check available",
        overview: "Overview",
        loginUrl: "Login URL",
        edit: "Edit",
        stopUseSubdomain: "Stop use subdomain",
        updateModal: {
          title: "Subdomain",
          warning:
            "Updating a subdomain requires all employees, including administrators, to log in again. Please contact them with the new login URL in advance.",
        },
        updateConfirmModal: {
          title: "Warning",
          content:
            "Updating a subdomain will prevent employees from connecting. Do you want to change it?",
        },
        stopUsingModal: {
          title: "Subdomain Suspension",
          content:
            "If a subdomain is deactivated, all employees, including administrators, will need to log in again. To deactivate a subdomain, enter the subdomain in the input field and click the Deactivate button.",
          stopUsing: "Suspension",
        },
        stopUsingConfirmModal: {
          title: "Warning",
          content:
            "If a subdomain is deactivated, all employees, including administrators, will need to log in again. Do you really want to delete it?",
        },
        options: {
          title: "SAML Authentication Options",
          skipIPAccessRestriction:
            "Skip IP access restrictions during SAML authentication",
          enableModal: {
            title: "Skip IP Access Restriction",
            buttonText: "Enable",
            content: "Do you really want to skip IP Access Restriction",
          },
          disableModal: {
            title: "Skip IP Access Restriction",
            buttonText: "Disable",
            content:
              "Do you really want to disable skip IP Access Restriction?",
          },
          warnings: {
            message: "Register your IdP information.",
            noSamlLicence: "No valid SAML licence exists",
            noIpRestrictionLicense: "No valid IP Restriction license exits",
            ipRestrictionDisabled: "IP Restriction function is disabled",
          },
        },
      },
      allowedAuthenticationMethods: {
        title: "Allowed Authentication Methods",
        emailPassword: "Email and password",
        google: "Google",
        office365: "Microsoft 365",
        apple: "Apple",
        edit: "Edit",
        updateModal: {
          title: "許可ログイン方法",
        },
      },
      samlSetting: {
        title: "SAML Settings",
        create: "Create",
        noLicense:
          "SAML is paid option, please contact support if you would like to use",
        noSubdomain: "Please config a subdomain before using this feature",
        samlSettingNameHelpText:
          "The setting name is displayed as a button on the login screen. Please use a short and appropriate setting name.",
        table: {
          name: "Setting Name",
          description: "Description",
          status: "Status",
          active: "Active",
          inactive: "Inactive",
        },
        newModal: {
          title: "Create SAML Settings",
          name: "Name",
          description: "Description",
        },
        updateModal: {
          title: "Update SAML Settings",
          name: "Name",
          description: "Description",
        },
      },
      samlSettingDetail: {
        title: "SAML Configuration",
        setupDescription: {
          head: "Setup steps",
          line1: "Register the service provider information in your IdP.",
          line2: "Download the metadata from the IdP or copy the metadata URL.",
          line3: "Upload the metadata or register the metadata URL.",
          line4: "Activate.",
          line5:
            "Select this SAML setting for the Allow login method on the subdomain screen.",
          end1: "If your IdP is Azure AD, please change the source attribute of the claim below.",
          end2: "Claim name: http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
          end3: "Source attribute: user.userprincipalname",
        },
        summary: {
          title: "Summary",
          name: "Name",
          description: "Description",
          edit: "Edit",
        },
        serviceProvider: {
          title: "Service Provider",
          entityId: "Entity ID",
          ascUrl: "ACS URL",
          logoutUrl: "Logout URL",
        },
        identityProvider: {
          title: "Identity Provider",
          metadataUpload: "Upload metadata",
          metadataUrl: "Metadata URL",
          edit: "Edit",
          cannotChange:
            "To change the metadata, please disable the SAML setting.",
        },
        connectionCheck: {
          title: "Check connection",
          action: "Check connection",
        },
        activation: {
          title: "Activation",
          action: "Enable SAML",
          enableModal: {
            title: "SAML Setting",
            buttonText: "Enable SAML Setting",
            content: "Do you really want to enable SAML settings?",
          },
          disableModal: {
            title: "SAML Setting",
            buttonText: "Disable SAML Setting",
            content: "Do you really want to disable SAML settings?",
          },
          warnings: {
            mustSetMetadata: "You must set Metadata.",
          },
        },
        stopUsingModal: {
          title: "Delete SAML Settings",
          content:
            "Enter current SAML settings name in the text field below and click Delete button",
          stopUsing: "Delete",
        },
        stopUsingConfirmModal: {
          title: "Warning",
          content:
            "If you deactivate this SAML settings, your employees may not be able to access TOKIUM. Do you really want to delete it?",
        },
        updateIdPModal: {
          title: "SAML Settings",
          metadataUpload: "Metadata Upload",
          or: "OR",
          metadataURL: "Metadata URL",
        },
      },
      samlAccount: {
        title: "SAML Accounts",
        info: {
          line1:
            "It can be mapped when the account on the TOKIUM and the account on the IdP side are different.",
          line2:
            "If the account on the TOKIUM and the account on the IdP side are the same, there is no need to set them.",
        },
        setSearchCondition: "Specific Search Condition",
        import: "Import",
        export: "Export",
        employee: "Employee",
        linkedAccount: "Linked Accounts",
        filter: {
          all: "All employees",
          linkAll: "Link all employees",
          unlinkAll: "Unlink all employees",
          batch: "Selected employees",
          batchLink: "Link selected employees",
          batchUnlink: "Unlink selected employees",
        },
        searchModal: {
          title: "SAML Linked Accounts",
          setSearchCondition: "Specific Search Condition",
          employeeCode: "Employee Code",
          nameOrEmail: "Name or Email",
          department: "Department",
          blocked: "Blocked",
          notSet: "Not Set",
          alreadyLinked: "Linked",
          all: "All",
          resetSearchCondition: "Reset search condition",
          search: "Search with the condition",
        },
        linkAllModal: {
          title: "SAML Account Batch Link",
          description:
            "The selected SAML settings will be set to the same account as the TOKIUM account.",
          linkAll: "Batch Link",
        },
        releaseAllModal: {
          title: "SAML Account Batch Release",
          description: "The selected SAML settings will be unlinked.",
          releaseAll: "Batch Release",
        },
        singleModal: {
          title: "SAML Integration Account",
          link: "Link",
          release: "Release",
          useReceiptPostEmail: "Apply TOKIUM Account",
        },
        destroyLinkModal: {
          title: "SAML Linked Accounts",
          unlink: "Unlink",
        },
        importModal: {
          title: "Import SAML Linkage Accounts",
          description1: "Import (add, update, delete) SAML linkage accounts.",
          description2: "Click here for the template.",
          description3: "Please be sure to specify the email address column.",
          description4:
            "Be sure to specify at least one column for the SAML setting name.",
          description5: "The name column can be empty.",
          description6:
            "In case of deletion, please leave the linkage information (SAML email or user name) blank.",
          description7: "To update the file, please use the downloaded file.",
          gotoExport: "Go to export",
        },
        exportModal: {
          title: "Export SAML Linkage Accounts",
          button: "Export in CSV Format",
          description: "You can export SAML Linkage Accounts",
          gotoImport: "Go to import",
        },
      },
    },
    nationalTaxDocumentSettings: {
      typeAliases: {
        title: "Document Type",
        actions: {
          addAlias: "Add Document Type",
        },
        properties: {
          enabled: {
            yes: "Yes",
            no: "No",
          },
        },
        tableLabels: {
          name: "Name",
          typeName: "Document Type Category",
          order: "Order",
          enabled: "Enabled",
        },
        tableLabelHelpers: {
          name: {
            message:
              'The name will use as the type of "Tax Documents."\nYou can set it up with any name you like.\n[Example]: Change "Contract" to "Issuance Contract."',
          },
          typeName: {
            message:
              'Please select the document category that applies to the name.\nIf the name is unsuitable for any category, please select "Other."\n[Example]: If the document type name is "Issuance Invoice," select "Invoice" for the document category.',
          },
        },
        formLabels: {
          includeDisabled: "Show types include disabled",
          name: "Name",
          type: "Document Type Category",
          order: "Order",
          enabled: "Enabled",
        },
        modal: {
          createAndEdit: {
            titleForCreate: "Add Document Type",
            titleForEdit: "Edit Document Type",
          },
        },
      },
      autoCreation: {
        title: "Autofill Setting",
        labels: {
          preserveSupplierPaymentMethod:
            "Data conversion with the contents of the supplier setting",
        },
        descriptions: {
          preserveSupplierPaymentMethod:
            "When autofill, data is generated based on the information in the supplier's settings, not on the information in the documents.",
        },
        workerInputPreserve: {
          title: "Detail settings",
          supplierSettings: "Supplier Settings",
          workerInput: "Priority Autofill Information",
          items: {
            supplierName: "Supplier Name",
            paymentInformation: "Payment Information",
            bankAccountHolder: "Bank Account Holder",
            payFee: "Pay fee",
          },
          descriptions: {
            paymentInformation:
              "Payment Information(Bank Name, Branch Name, Bank Account Type, Bank Account Number)",
          },
          message: {
            updated: "Supplier priority settings have been updated.",
          },
          error: {
            failedToUpdated: "Supplier priority settings could not be updated.",
          },
        },
      },
    },
    login: {
      subdomain: "Subdomain",
      submit: "Submit",
      back: "Back to Login",
      invalidSubdomain: "Invalid subdomain",
    },
    searchSetting: {
      titles: {
        canDeletedExpenseSearch: "Receipt Setting",
        canDeletedInvoiceSearch: "Invoice Setting",
        canDeletedNationalTaxDocumentSearch: "Tax Document Setting",
      },
      descriptions: {
        canDeletedExpenseSearch: "Allow to search deleted expenses",
        canDeletedInvoiceSearch: "Allow to search deleted invoices",
        canDeletedNationalTaxDocumentSearch:
          "Allow to search deleted tax documents",
        searchTargetDeletedExpenses:
          "Search for deleted expenses created after the date and time specified on the right field",
        searchTargetDeletedInvoices:
          "Search for deleted invoices created after the date and time specified on the right field",
        searchTargetDeletedNationalTaxDocuments:
          "Search for deleted tax documents created after the date and time specified on the right field",
      },
    },
    timestampVerification: {
      title: "Timestamp Verification Settings",
      enable: "Timestamp verification",
      error: "Failed to update timestamp verification settings.",
    },
    resolutionInformation: {
      title: "Show Resolution Informations for expenses and invoices",
      description:
        'Uploaded images are checked to ensure they meet scanner retention requirements of the "Law on Book and Record Keeping through Electronic Methods"',
      enable: "Expense",
    },
  },
  employeeLabels: {
    titles: {
      labels: "List of member labels",
      createLabel: "Create a Member Label",
      editLabel: "Edit the Member Label",
    },
    properties: {
      name: "Label Name",
      color: "Color",
    },
    headers: {
      number: "Number",
      label: "Label",
    },
  },
  members: {
    titles: {
      update: "Update Employee's Information",
      new: "New Employee",
      clearApprovalFlow: "Clear approval flow",
      selectApprovalFlow: "Select approval flow",
      selectLabel: "Select labels",
      list: "Member List",
      import: "Member import",
      export: "Member export",
      settingsLock: "Edit lock setting for employee's information",
    },
    properties: {
      mail: "Email",
      employeeId: "Employee id (optional)",
      name: "Name",
      addDepartment: "Add department",
      primaryDepartment: "Primary department",
      department: "department",
      noDepartment: "No Selection",
      post: "Post",
      noPost: "No Selection",
      approver: "Approver",
      removeDepartment: "Remove department",
      aggregator: "Aggregator",
      admin: "Administrator",
      blocked: "Suspended",
      reference: "Referenece",
      unlock: "Unlock",
      addEmployee: "Add an employee",
      importFromXlsx: "Import",
      exportAsXlsx: "Export",
      account: "Account",
      labelName: "Label Name",
      color: "Color",
    },
    authorities: {
      help: {
        admin: "System settings menu can be viewed and edited",
        accountant: "Aggregator pages can be viewed and edited",
      },
    },
    buttons: {
      update: "Update",
      add: "Add",
      showAllDepartments: "Show all departments",
      clearApprovalFlow: "Clear approval flow",
    },
    departmentPosts: {
      approver: "AP",
    },
    statuses: {
      expenseAccount: "Expense Account",
      commuterPassRoute: "Commuter Pass Route",
      agent: "Agent",
      notConfigure: "Not configure",
      configured: "Configured",
      locked: "Locked",
      blocked: "{{- date}} Blocked",
    },
    headers: {
      employee: "Employee",
      departmentPost: "Department / Post",
      department: "Department",
      permission: "Permissions",
      status: "Status",
      statusHelp:
        "Click on each status or icon to control the setting or editable/uneditable.",
      number: "number",
      label: "Label",
    },
    searchbox: {
      openForm: "Specify search conditions",
      search: "Search with the condition",
      resetCondition: "Reset search conditions",
      onlyMainDepartment: "Only primary department",
      authority: "Authorities",
      searchCondition: "Search Condition",
      displayCondition: "Display Condition",
      showFrozen: "Display frozen accounts",
      nameOrEmail: "Employee name or email",
      department: "Department",
      post: "Post",
      employeeId: "Employee ID",
      label: "Label",
    },
    settingMenu: {
      editExpenseAccount: "Setup the account",
      lockExpenseAccount: "Make the account uneditable",
      unlockExpenseAccount: "Make the account editable",
      editCommuterPassRoute: "Setup the commuter pass",
      lockCommuterPassRoute: "Make the commuter pass uneditable",
      unlockCommuterPassRoute: "Make the commuter pass editable",
      editCategory: "Setup categories",
      editAgent: "Setup the agent",
      unlock: "Unlock",
      block: "Freeze",
      unblock: "Unfreeze",
    },
    messages: {
      memberUpdate: "Updated employee information",
      memberAdd: "Sent invitation mail",
      confirmUserBlockTitle: "Are you sure?",
      confirmUserBlockMessage: "Are you sure you want to suspend this account?",
      confirmUserBlock: "Yes, suspend this account.",
      confirmUserUnblockTitle: "Are you sure?",
      confirmUserUnblockMessage:
        "Are you sure you want to resume this account?",
      confirmUserUnblock: "Yes, resume this account.",
      confirmUserUnlockTitle: "Are you sure?",
      confirmUserUnlockMessage: "Are you sure you want to unlock this account?",
      confirmUserUnlock: "Yes, unlock this account.",
      confirmClearApprovalFlow:
        "Are you sure you want to clear all approval flow?",
    },
    descriptions: {
      unlock:
        "You can unlock employees whose accounts are locked by entering the wrong password 5 times.",
      exportGuide: {
        description1:
          "When updating export contents, please export the current settings, and edit the export file.",
        exportTargets: {
          labels: {
            group: "Department",
            post: "Post",
            departmentPostRelationship: "Department-Post Linkage",
            tag: "Employee Tag",
            member: "Employee",
            personalCategoryPreference:
              "Individual Expense Category Display Settings",
            teiki: "Commuter Pass Information",
            flow: "Approval Flow (Creation)",
            approverSelectFlow: "Approver Selection Flow (Creation)",
            flowMapping: "Approval Flow (Registration)",
          },
          description1: "Please select the export targets",
          description2: "If nothing is selected, all sheets will be exported",
        },
      },
      importGuide: {
        conma: ", ",
        department: "Department",
        post: "Post",
        approvalFlow: "ApprovalFlow",
        teikiRoute: "PassRoute",
        expenseAccount: "ExpenseAccount",
        multiInfoImport:
          "Simultaneously import {{value}} and employee information.",
        memberInfoImport: "Import employee information.",
        sample: "sample",
        templateGuide: "Click here for the template.",
        overwrite:
          "Already registered user information will be <u>overwritten during import</u>.",
        pass: "For commuter passes including buses, commuter passes may not be deducted properly.",
        frozen: 'Please enter "Yes" in the freeze column only when freezing.',
        mail: "Please write the changed email address on the first line of each employee.",
        bankCode:
          "If both the bank name and the bank code are provided, the bank code will take precedence (the same rule applies to the branch name).",
        export:
          "When updating export contents, please export the current settings, and edit the export file.",
        importTargets: {
          labels: {
            group: "Department",
            post: "Post",
            departmentPostRelationship: "Department-Post Linkage",
            tag: "Employee Tag",
            member: "Employee",
            personalCategoryPreference:
              "Individual Expense Category Display Settings",
            teiki: "Commuter Pass Information",
            flow: "Approval Flow (Creation)",
            approverSelectFlow: "Approver Selection Flow (Creation)",
            flowMapping: "Approval Flow (Registration)",
          },
          description1: "Please select the import targets",
          description2:
            "Sheets not selected as import targets will not be imported",
          description3: "If nothing is selected, all sheets will be imported",
        },
      },
    },
    link: {
      closeExportAndOpenImport: "Open import panel.",
      closeImportAndOpenExport: "Export current settings.",
    },
    actions: {
      addLabel: "Create a New Label",
      enableAccountSettingsLock: "Enable lock settings for account settings",
      enableTeikiSettingsLock: "Enable lock settings for regular sections",
    },
  },
  groups: {
    noValue: "No Settings",
    titles: {
      groupList: "Department List",
      employeeList: "Employee List",
      displayColumnSettings: "Column Display Settings",
      displayCodeColumn: "Display Department Code in Departments Table",
      editParent: "Edit parent department",
      selectGroup: "Select department",
      selectPosts: "Select posts",
      costAllocatedDepartment: "Cost Allocated Department Setting",
      export: "Export department",
    },
    actions: {
      addGroup: "Add a department",
      editGroup: "edit a department",
      deleteGroup: "Delete departments",
      searchPlaceholder: "input department name",
      inputExpenseGroup:
        "Inputs cost allocated department in addition to belonging department",
      inputBurdenRatio: "Allocates costs to a single or multiple departments",
      exportDepartmentsPostsApproversTable: "Export",
    },
    properties: {
      name: "Department name",
      parent: "Parent department",
      payer: "Payer department",
      approver: "Approver",
      employee: "Employees",
      post: "Posts",
      sort: "Order",
      code: "Code",
    },
    messages: {
      sort: "If orders are blank, the departments are sorted by department code. If code is blank, the departments are sorted by name.",
      posts:
        "Posts assignable to employees can be specified for each department.",
    },
    exports: {
      guide1:
        "You can check the position holders who are enrolled in each department.",
      guide2:
        "If there is an employee with approval authority, the name will be displayed.",
      guide3:
        "If the employee does not have the authority to approve, his or her name will be displayed as (No authority).",
      guide4:
        "The gray area indicates that there are no employees enrolled in the corresponding department or position.",
    },
  },
  payments: {
    plans: {
      /* eslint-disable camelcase */
      corporate_yearly: "Corporation annual plan",
      personal_yearly: "Personal annual plan",
      corporate_monthly: "Corporation monthly plan",
      personal_monthly: "Personal monthly plan",
      /* eslint-enable camelcase */
    },
    titles: {
      contractInformation: "契約情報",
      monthlyUsage: "Monthly Usage of the Automatic Expense Inputs",
      paperlessPlanSubscriptions: "Paperless Plan Subscriptions",
      receiptBoxes: "Posts",
    },
    messages: {
      cannotChangePlanNow:
        "We do not accept modified Personal and Corporate plans at this time.",
    },
    properties: {
      monthlyUsageScope: "Scope",
    },
    tables: {
      yearAndMonth: "Year/Month",
      numberOfUsed: "Number of Using the Automatic Inputs",
    },
    actions: {
      receiptBoxes: {
        search: "Search posts",
      },
    },
  },
  posts: {
    titles: {
      create: "Create a Position",
      edit: "Edit the Position",
      companyPostSetting: "Position Settings",
      projectPostSetting: "Project Position Settings",
      createCompanyPost: "Create a Position",
      createProjectPost: "Create a Project Position",
      editCompanyPost: "Edit the Position",
      editProjectPost: "Edit the Project Position",
      postNotSet: "Project Post is not set",
    },
    actions: {
      addPost: "Create a New Position",
      deletePost: "Delete the Position",
      searchPlaceholder: "Input post name",
    },
    properties: {
      name: "Position Title",
      order: "Rank",
    },
  },
  accountForms: {
    titles: {
      newAccount: "Register a New Account",
      linkedAccount: "Edit Authentication Info",
      additionalInputAccount: "Input Additional Authentication",
      nfcAccount: "Edit the NFC Preferences",
    },
    actions: {
      registerAsCorporateCard: "Register as corporate card",
      registerAsCorporateExpense: 'Register as "No refund"',
      toggleMergeable: "Import a card statement to an expense input by worker",
      toggleAutoRegister: "Register a card statement automatically",
      toggleIsExcludingProductPurchase:
        "Exclude a card statement which have been used for product purchase",
      defaultCategory: "Default category",
      defaultGroup: "Default department",
      defaultProject: "Default project",
      defaultComment: "Comment template",
    },
    help: {
      registerAsCorporateCard:
        'Fill checkbox if the card is issued by your company so that you can manage your expenses without having to use your own card or cash. The expenses from this card will be registered as "No refund".',
      registerAsCorporateExpense:
        'Fill checkbox if it is paid by your company so that you can manage your expenses without having to use your own cash. The expenses from this will be registered as "No refund".',
    },
    corporateCard: "Corporate card",
  },
  timeStamps: {
    timeStamp: "TimeStamp",
    versionHistory: "VersionHistory",
    properties: {
      stampedAt: "SignedAt",
    },
    foreside: "Fore Side",
    backside: "Back Side",
  },
  requestTypes: {
    modalHeader: {
      settingPreReport: "Pre-report option setting",
      settingReport: "Report option setting",
      editForm: "Edit form",
      createForm: "Add a new form",
    },
    modalBody: {
      useTemporaryPayment: "Use cash advance",
      useDraftSaveReport: "Use draft save report function",
      formName: "Report title",
      notEntered: "not Entered",
      preview: "Preview",
      paidOptionAlert:
        "The function of temporary payment is a paid option. To use it, please contact our support through the chat on the bottom right.",
    },
    modalFooter: {
      close: "close",
      save: "save",
      add: "add",
    },
    formItems: {
      labelName: "Item Name",
      inputType: "Input Type",
      canNotDelete: "Cannot Delete",
      canNotEdit: "Cannot Edit",
      addNewFormItem: "Add Input Item",
      editFormItems: "Edit Input Item",
      addFormItems: "Add New Form",
      required: "Required",
      checkDescription: "Check Description",
      choice: "Choice",
      initValue: "Init Value",
      inputChoicesLineBreakSeparated: "Enter choices with line break separated",
      reportTitle: "Report Title",
      editForm: "Form",
      editOption: "Options",
    },
  },
  approvalFlow: {
    title: {
      approvalFlowList: "Approval Flow List",
      approvalFlowProblemExport: "Export Approval Flow Problems",
    },
    actions: {
      createApprovalFlow: "Create a New Approval Flow",
      createSelectNextApproverApprovalFlow:
        "Create a New Select Next Approver Approval Flow",
      checkProblemsApprovalFlow: "Check problems in Approval Flows",
    },
    properties: {
      name: "Name",
      condition: "condition",
      priority: "Priority",
      isApproverSelectFlow: "Is Approver Select Flow",
      approvalFlow: "Approval Flow",
      edit: "Edit",
      deletion: "delete",
      approverSelectFlow: "Approver Select Flow",
      flowName: "Flow Name",
    },
  },
  metadata: {
    tables: {
      user: "User",
      group: "Department",
      companyPost: "Post",
      tag: "Label",
      project: "Project",
      category: "Expense category",
      taxCategory: "Tax Category",
      superCategory: "Account title",
      paymentRequestsDebitInvoiceCategory: "Debit invoice category",
      paymentRequestsCreditInvoiceCategory: "Credit invoice category",
      paymentRequestsSupplier: "Supplier",
      companyExpenseAccount: "Company expense account",
    },
    keyModal: {
      add: "Add Column",
      update: "Edit Column",
      key: "Column {{index}}",
      newKey: "New Column {{index}}",
    },
    buttons: {
      createLatestExportFile: "Start creating a file with the current settings",
      importFromXlsx: "Import",
      downloadAsXlsx: "Export",
      moveToExportModal: "Export the current settings",
      moveToImportModal: "Move to import modal",
      guideAboutChangeConfig:
        "If you want to modify the settings, export the latest settings, modify the exported file, and import it.",
    },
    messages: {
      added: "Added columns",
      updated: "Updated columns",
      deleted: "Deleted columns",
    },
    imports: {
      title: "Import metadata",
      importMetadata: "Import metadata.",
      sample: "sample file",
      guide:
        "The detailed instructions are on the column D, E of the sample file. Please leave the column blank when importing",
      guide2:
        "Column names must match the table. ※You cannot add new column by importing.",
      guide3:
        "If you want to modify the settings, export the latest settings, modify the exported file, and import it.",
      guideWithHierarchy:
        'When "/" is included in the department name, expense category name, it will be converted to "¥/" at export, but please import as it is.',
    },
    exports: {
      title: "Export metadata",
    },
    alertSettings: {
      genericFields: {
        title: "Codes, names and Items can be set in DataSet settings.",
        urlName: ">> Go to DataSet settings",
      },
    },
  },
  allowance: {
    settings: {
      title: "Allowance",
      newEntry: "New allowance form",
    },
    properties: {
      name: "Allowance Name",
      icon: "Menu Icon",
      factorTypes: "Allowance Factors",
      categories: "Available expense categories",
      defaultCategory: "Default expense category",
      addTableDimension: "Add an allowance dimension",
      tableSize: "Allowance table size",
      table: "Table",
      additionalItem: "Additional item",
      addInputItem: "Add an input item",
      inputType: "Input Type",
      calFormula: "Calculation formula",
      insertVariable: "Insert variable",
    },
    descriptions: {
      name: "This is a name to be shown at the side menu.",
      icon: "This is an icon to be shown at the side menu.",
      factorTypes:
        'Configure matrix elements to calculate allowance. If allowance is defined by "Post" and "Mileage", you can input those 2 items here.',
      categories:
        "Select expense categories used for the choices in the allowance form.",
      defaultCategory:
        "Select an expense category used for the default selection in the allowance form.",
      tableSize: "The table can have up to 200 cells",
      addCalcItem: "You can add items that is used in calculation of allowance",
      formula:
        "This is a configuration of formula that is for calculating allowance in a report",
    },
    placeholders: {
      categories: "All expense categories are available in the allowance form",
      nameOfName: "Name of {{name}}",
      itemName: "Item Name",
      actualCost: "Input of actual cost",
    },
    messageBox: {
      title: {
        info: "Calculation formula details",
        variable: "Variable",
        variableName: "[Variable name]",
        operator: "Operator",
        function: "Function",
        allowance: "Allowance",
      },
      description: {
        variable: "Substitute a value which corresponds an item input by user",
        operator: "Addition, subtraction, and multiplication",
        funcMin: "Return smaller value of a or b",
        funcMax: "Return larger value of a or b",
      },
    },
    notification: {
      unsaved: "You have some unsaved data. Are you sure you want to proceed?",
      unusedVariable: "There are unused variables:",
      sure: "Are you sure you want to delete {{name}}?",
    },
    pulldown: {
      numeric: "Numeric Input",
      duration: "Duration Input",
      select: "Selection Box",
    },
    selectionBox: {
      optionSet: "Options configuration",
      optionName: "Option name",
      value: "Value",
      add: "Add an option",
    },
  },
  categories: {
    actions: {
      selectCategory: "Select the account title",
      searchPlaceholder: "Input an account title",
    },
    imports: {
      title: "Import expense categories",
      importCategories: "Import expense categories.",
      sample: "sample file",
      guide:
        "The detailed instructions are on the last column of the sample file. Please leave the column blank when importing",
      guide2:
        "To modify the settings, export the latest settings, modify the export file, and then import.",
      moveExport: "Export current settings",
    },
    exports: {
      title: "Export expense categories",
      createLatestExportFile: "Start creating latest file",
      guide:
        "To modify the settings, export the latest settings, modify the export file, and then import.",
      moveImport: "Go to import screen",
    },
  },
  tags: {
    actions: {
      selectTag: "Select the tag",
      searchPlaceholder: "Input an tag name",
    },
  },
  companyPosts: {
    actions: {
      selectCompanyPost: "Select the company post",
      searchPlaceholder: "Input an company post name",
    },
  },
  personalCategories: {
    titles: {
      individualSettings: "Individual Account Title Setting",
    },
    messages: {
      saved: '"{{name}}" has turned into {{state}}.',
    },
    misc: {
      enabled: "enabled",
      disabled: "disabled",
    },
  },
  expenseDataEntries: {
    waitingTime: "Approximate receipt entry duration",
    hoursGreaterThan: "More than {{hours}} hours",
    hoursLessThan: "Less than {{hours}} hours",
    minutesGreaterThan: "More than {{minutes}} min",
    minutesLessThan: "Less than {{minutes}} min",
    timeGreaterThan: "More than {{hours}} hours {{minutes}} min",
    timeLessThan: "Less than {{hours}} hours {{minutes}} min",
  },
  journalEntries: {
    expense: "expense",
    reortItem: "report item",
    invoice: "invoice",
    messages: {
      deleted: "It has been deleted",
      duplicated:
        'The journal entries export format "{{name}}" has been duplicated',
      maxLengthRange: "The length must be between 1 and 9999.",
      published:
        'The journal entries export format "{{name}}" has been published',
      registeredExportFormat: "The export format has been registered",
      restored: "The journal entries export format has been restored",
      saved: "It has been saved",
      setToDefault:
        'The journal entries export format "{{name}}" has been set as default',
      setToDraft:
        'The journal entries export format "{{name}}" has been set to draft',
      setToUndefault:
        'The journal entries export format "{{name}}" has been set as undefault',
      updatedDebitCreditStyle: "The debit credit style has been updated.",
      updatedExportFormat: "The export format has been updated.",
      updatedExportTarget: "The export target has been updated.",
      updatedVoucherPreference: "The voucher preference has been updated.",
    },
    descriptions: {
      addColumnWithPlusButton:
        "You can add columns with + button in the table.",
      aggregationLeftHand:
        "If aggregation conditions are specified, you can change export contents depending on whether the aggregation conditions are satisfied.",
      cellType:
        "With customize, you can add conditional branches and combine multiple variables and texts.",
      customCellValue: "You can combine variables and texts.",
      customCellValueOnClick:
        "You can also specify the following for each item by clicking on the selected item.",
      customCellValueSettingDate:
        "・(If the output content is a date) Date format",
      customCellValueSettingLength: "・Character count limit",
      customCellValueSettingCharExtraction:
        "・(If the output is not a date)String extraction",
      customCellValueSettingCharLeading: "・Digit adjustment",
      dateFormat:
        "Year, month, day, hour, minute, and second can be specified. 2021/9/5 9:05 AM will be converted as follows. jpy→3(Japanese calender), yyyy→2021, yy→21, mm→09, m→9, dd→05, d→5, hh→09, h→9, MM→05, M→5, ss→00, s→0 lastd→30(end of month)",
      defaultFormat: "Draft export format cannot be specified as default",
      deleteAdditionalInformation:
        "The detailed instructions are at the right end of the sample file. Please leave the column blank when importing",
      deleteDefaultExportFormat:
        "This export format is set as default. If you delete it, the default format set in export preference settings will be applied.",
      exportFormatName:
        "The name will be on the list selecting the format to export journal entries data with",
      exportFormatNotPublished:
        'This export format is not published. Please click "Publish" button so that the format will be on the list when exporting journal entries data.',
      exportGuide:
        "When updating export contents, please export the current settings, and edit the export file.",
      exportPriority: "The upper the condition is, the higher the priority is.",
      importantImportGuide: "Please read the important notes below",
      importColumns: "Export contents can be imported by xlsx file.",
      importGuide:
        "When updating export contents, please export the current settings, and edit the export file.",
      includeZeroAmountEntries:
        "You can choose whether to export zero amount entries. ",
      leadingChar:
        "The export value will be filled with zero or white space only when max length of column is specified.",
      naviTextUnclickableReason:
        "Please save or discard changes before moving to other settings.",
      onlyConstantCellUpdatable:
        "Only constant cells are updatable by importing settings file.",
      preferencePreviewTable:
        "Journal entries sample is generated depending on the setting about accounting voucher.",
      prioritizeOverExportPreference:
        "The default format here is prioritized over the one in export preference settings",
      publicationStatus:
        "Only published export formats will be on the list when exporting journal entries data.",
      rowSep: "You may leave it 'LF' unless otherwise specified.",
      sample: "Sample data",
      saveEachContent:
        "You will save each export column and content separately.",
      showSettingsSample:
        'Settings sample will be displayed by hovering over "Display sample"',
      template: "Template file",
      ungivenColumnsWillBeDeleted:
        "Columns that are not included in import file will be deleted.",
      useMetadataToSetCode:
        "Please use metadata settings in order to export account and tax code",
      voucherDivisionWay:
        "Please see the preview below to understand the difference between dividing into multiple vouchers or not.",
      writeHeaders:
        "If you export headers as the first row of export data, please select 'export'.",
    },
    titles: {
      blankColumnName: "[Blank column name]",
      exportContentCellModal:
        "Cell content of {{rowExpression}} and {{- columnName}}",
      exportForm: "Export contents",
      formatList: "Journal entries export formats",
      importForm: "Import contents",
      instruction: "Instruction",
      instructionFirstHalf: "Please checkout ",
      instructionSecondHalf: " if you need any help.",
      restored: "Restore a journal entries export format",
      setUpColumn: "Set up export column of {{classificationExpression}}",
      setUpDebitCreditStyle: "Set up debit and credit balance style",
      setUpExportContent: "Set up export item",
      setUpExportTarget: "Set up export target",
      setUpFileFormat: "Set up file format",
      setUpProcedures: "Journal entries export format settings procedures",
      setUpVoucherDivision: "Set up voucher division",
      updateDebitCreditStyle: "Update debit credit style",
      variablePopover: "Edit {{label}}",
      selectPaymentMethodType: "Select payment method",
    },
    buttons: {
      addAndCondition: 'Add "And" conjunction',
      addConditionalBranch: "Add conditional branch",
      addOrCondition: 'Add "Or" conjunction',
      backToList: "Back to the list",
      backward: "Backward",
      close: "Close",
      create: "Create",
      detailed: "Detailed such as file extension",
      discardChanges: "Discard changes",
      downloadPreviewData: "Download preview data",
      finished: "Finish",
      forward: "Forward",
      moveToExportModal: "Open export modal",
      moveToImportModal: "Open import modal",
      publish: "Publish",
      requestToDownloadExportContents: "Download current export content",
      restore: "Restore",
      restored: "Restored",
      save: "Save",
      setToDraft: "Set to Draft",
      toExportPreferenceSettings: "Export preference settings",
      toMetadataSettings: "Metadata settings",
    },
    status: {
      published: "Published",
      private: "Private",
    },
    labels: {
      aboutExportContent: "About export content",
      aboutVoucher: "About voucher",
      aggregatingTarget: "Aggregating target",
      aggregationWay: "How to aggregate rows",
      formatName: "Format Name",
      accountingSoftwareName: "Accounting Software Name",
      colSeps: {
        label: "Column Separation",
        comma: "Comma",
        tab: "Tab",
        noColSep: "Nothing",
      },
      dateFormat: "Date format",
      debitCreditClassificationExpressions: {
        // eslint-disable-next-line camelcase
        common_former: "common former column",
        // eslint-disable-next-line camelcase
        common_latter: "common latter column",
        credit: "credit column",
        debit: "debit column",
      },
      displaySampleDataInExportContents: {
        setToFalse: "Do not register sample values",
        setToTrue: "Register sample values",
      },
      displaySettingsSampleHovered: {
        display: "Display sample",
        displayed: "Sample is displayed",
      },
      exportWay: "How to export rows",
      fileExtension: "Extension",
      leadingChar: "Digit adjustment",
      operators: {
        equal: "=",
        // eslint-disable-next-line camelcase
        all_equal: "exactly match",
        // eslint-disable-next-line camelcase
        greater_than: ">",
        // eslint-disable-next-line camelcase
        greater_than_or_equal: "≥",
        // eslint-disable-next-line camelcase
        less_than: "<",
        // eslint-disable-next-line camelcase
        less_than_or_equal: "≤",
        // eslint-disable-next-line camelcase
        not_equal: "≠",
        in: "includes",
        // eslint-disable-next-line camelcase
        contains_any: "includes",
        // eslint-disable-next-line camelcase
        not_in: "excludes",
        // eslint-disable-next-line camelcase
        contains_all: "contains all",
        // eslint-disable-next-line camelcase
        not_contain_any: "does not contain any",
        // eslint-disable-next-line camelcase
        match_any: "=",
        // eslint-disable-next-line camelcase
        not_match_any: "≠",
      },
      rowSep: "Newline Character",
      header: "Header",
      zeroAmountEntries: "Zero amount entries",
      cellType: "Type",
      cellTypes: {
        constant: "Constant value",
        custom: "Customize",
        variable: "Variable",
      },
      sampleValue: "Sample value",
      createVoucher: "Create voucher",
      voucherDivisionWay: "How to divide into vouchers",
      voucherUnit: "Voucher Unit",
      exportContent: "Export content",
      contentName: "Content name",
      exportedHeaderName: "Header name",
      charCountLimit: "Character count limit",
      charConversion: "Character conversion",
      withCellValueConditions: "Conditional branch",
      exportTargetConditionSetting: {
        simple: "簡単な設定",
        advanced: "詳細な設定",
      },
      charExtraction: "String extraction",
      charExtractionForm: {
        cut: {
          from: "",
          output: "characters",
        },
        extract: {
          from: "～",
          output: "of between extract",
          includeWord: "include",
          helpCard: {
            title: "About the string to be extracted",
            exampleDescription: {
              example: "Example)",
              exampleWord1: "CorporationTOKIUM",
              exampleWord2: "InvoiceSection",
              exampleWord3:
                "(※There is a line break after TOKIUM)When the extraction is performed with the specified character.",
            },
            header: {
              start: "start",
              end: "end",
              result: "extraction results",
            },
            example1: {
              start: "Corporation(not include)",
              end: "Section(not include)",
              result: "TOKIUM Invoice",
            },
            example2: {
              start: "Corporation(include)",
              end: "Section(not include)",
              result: "CorporationTOKIUM Invoice",
            },
            example3: {
              start: "start",
              end: "Section(not include)",
              result: "CorporationTOKIUM Invoice",
            },
            example4: {
              start: "Corporation(not include)",
              end: "end of sentence",
              result: "TOKIUM InvoiceSection",
            },
            example5: {
              start: "Corporation(not include)",
              end: "end of line",
              result: "TOKIUM",
            },
          },
        },
      },
    },
    contents: {
      aggregate: "are the aggregation units of {{rowExpression}}",
      aggregateToSingleRow: "Aggregate to single row",
      aggregateToMultipleRows: "Others",
      aggregationPolicies: {
        withoutVoucher: {
          noAggregation: "Export single {{resourceName}} as single row",
          partialAggregation: "Aggregate some of the {{resourceName}}s",
          allAggregation: "Aggregate all {{resourceName}}s",
        },
        withVoucher: {
          noAggregation: "Export single {{resourceName}} as single row",
          partialAggregation:
            "Aggregate some of the {{resourceName}}s in the voucher",
          allAggregation: "Aggregate all {{resourceName}}s in the voucher",
        },
      },
      export: "Export",
      exportContentInOtherCases: "Export below in other cases",
      exportContentWithCondition: "Export below with upper conditions",
      doNotExport: "Do not export",
      createVoucher: "Create voucher",
      doNotCreateVoucher: "Do not create voucher",
      multipleVoucher: "Divide into multiple voucher",
      singleVoucher: "Single voucher",
      debitCreditStyles: {
        balanceEachRow:
          "Debit amount and credit amount are balanced for each row",
        creditUpperRightAndDebitBottomLeft:
          "Credit amount is summed up upper right and debit amount is summed up bottom left",
        useDepositRow: "Use deposit row",
      },
      voucherDivision: "", // 無くても問題ない補足的な文章なので、語順的にenバージョンでは割愛
      leadingChars: {
        nothing: "Do nothing",
        space: "Fill with white space",
        zero: "Fill with 0",
      },
      charCutPosition: {
        beginning: "first",
        ending: "last",
      },
      charExtractStartPosition: {
        wordSpecification: "word specification",
        beginning: "head",
      },
      charExtractEndPosition: {
        wordSpecification: "word specification",
        endOfSentence: "end of sentence",
        endOfLine: "end of line",
      },
      charExtraction: {
        nothing: "Do nothing",
        cut: "Extract by number of characters",
        extract: "Extract by specified characters",
      },
      preview: "Preview",
      updateDebitCreditStyleConfirmation:
        "Voucher settings and export contents are all cleared if you change debit credit style. This operation cannot be undone.",
      impose: "Impose",
      doNotImpose: "Do not impose",
      doNot: "Do not",
      fullWidth: "Full-width",
      halfWidth: "Half-width",
      fullWidthTohalfWidth: "Full-width to Half-width",
      editContent: "Edit content",
      deleteContent: "Delete content",
      addContentToRightSide: "Add content to right side",
      addContentToLeftSide: "Add content to left side",
      withConditions: "With conditional branches",
      withoutConditions: "Without conditional branches",
    },
    placeholder: {
      blank: "Blank",
      dateFormatExample: "e.g. yyyy/mm/dd",
      invoiceFormatName: "e.g. 請求書仕訳_弥生会計",
      expenseFormatName: "e.g. 経費仕訳_弥生会計",
      multipleSelectable: "multiple selectable",
      unselected: "Unselected",
      sampleValueDisabled: "Auto",
    },
    tables: {
      headers: {
        date: "Date",
        defaultFormat: "Default Format",
        voucherNumber: "Voucher Number",
        rowNumber: "Row Number",
        debit: "Debit",
        credit: "Credit",
        superCategory: "Accounts Title",
        amount: "Amount",
        taxCategory: "Tax Classification",
        formatName: "Format Name",
        accountingSoftwareName: "Accounting Software Name",
        publicationStatus: "Publication status",
      },
    },
    navTabs: {
      expenseExportFormat: "Expense Export Format",
      invoiceExportFormat: "Invoice Export Format",
    },
    createModal: {
      title: "Create Journal entries",
      labels: {
        fromTemplate: "Create from a template",
        fromScratch: "Create from scratch",
        templateName: "Template",
      },
      buttons: {
        submit: "Create",
      },
    },
  },
  searchResults: {
    messages: {
      results: "Search Results",
      notFound: "No Results Found",
      selectableItemNotFound: "There are no {{itemName}} to choose from",
      truncated: "Displaying Top {{count}} results",
      limited: "Displaying up to {{count}} results",
      displayingRecentlyUsed:
        "Recently used projects will are shown with priority",
      selection: "Selected",
    },
  },
  superCategories: {
    actions: {
      searchPlaceholder: "Input an account title",
      selectSuperCategory: "Select the account title",
    },
    export: {
      selectFileType: "Select an file format",
    },
  },
  corporateCards: {
    titles: {
      aggregationResults: "Unapproved Transactions Listing",
    },
    properties: {
      user: "Cardholder",
      numberLast4: "Last 4 digits",
      brand: "Brand",
      issuer: "Issuer",
      settlement: {
        corporate: "Corporate settlement",
        personal: "Personal settlement",
      },
    },
    aggregationResults: {
      properties: {
        transactedAt: "Date",
        alert: "Warnings",
        name: "Shop",
        amount: "Amount",
        reportTitle: "Report title",
        createdAt: "Registration date",
      },
      alerts: {
        deleted: "This transaction is deleted",
        unmerged: "This transaction is not merged to any expenses",
        toBePaid: "The expense will be reimbursed",
      },
      statuses: {
        unapproved: "Not approved",
        deleted: "Deleted",
        unmerged: "Not merged",
        toBePaid: "To be reimbursed",
      },
    },
  },
  originalReceiptIdentificationRequests: {
    titles: {
      selectRecipients: "Select recipients",
      searchResult: "Search result",
      selectResult: "Select result",
    },
    actions: {
      notifySelectedOriginalReceipts: "Notify selected original receipts",
      notifyOriginalReceipt: "Notify this original receipt",
      advancedSearch: "Advanced search",
      allSelectRecipients: "All select recipients",
      clearAllRecipients: "Clear all recipients",
      sendNotification: "Send notification",
    },
    properties: {
      employeeName: "Employee name",
      department: "Department",
    },
  },
  paperless: {
    receiptMatchings: {
      titles: {
        match: "Receipt Matching Page",
        searchExpenses: "Search Expenses",
      },
      errors: {
        unknownCurrency: "Unknown Currency",
        failedToLoadExpenses:
          "Failed to fetch expenses (to be matched [to/with] the receipt)",
        expenseNotSelected: "Please select the expense",
        requireSearch:
          "Please try the search by changing the Date or Amount at least once.\n※There are cases where one side of the amount/date on the data side is incorrect in the actual image and the input data.",
      },
      warnings: {
        JR: "Please also check the name of the station where it was purchased",
      },
      properties: {
        amount: "Amount",
        shopName: "Shop Name",
        transactedAt: "Date",
        originalReceipt: "Original Receipt",
        expenseReceipt: "Expense Receipts",
        status: "Status",
      },
      actions: {
        match: "Match",
        skip: "No matches found",
        next: "Skip this receipt",
        resetSearchConditions: "Reset the search conditions",
        searchWithTheConditions: "Search with the specified conditions",
      },
      messages: {
        select: "Select 'Match' or 'No matches found'",
        match: "The original receipt matched to the expense.",
        confirmation: 'Are you sure to select "{{action}}" ?',
      },
      progressionStatus: {
        labels: {
          finished: "Operated receipts in this month",
          remaining: "Remaining receipts",
        },
      },
    },
    receiptCollectionCycles: {
      titles: {
        list: "Receipt collection cycles",
        search: "Search receipt collection cycles",
      },
      properties: {
        collectedAt: "Collected at",
        collectedCount: "Collected count",
        matching: "Matching",
        needMatchingCount: "Need matching count",
        rootGroupName: "Company name",
        status: "Status",
        subscriptionPeriod: "Contract Period",
        totalCollectedCount: "Total collected count",
        waitingForWorkerCount: "Waiting for worker count",
      },
      actions: {
        new: "Start Receipt Verification",
        resetSearchConditions: "Reset the search conditions",
        searchWithTheConditions: "Search with the specified conditions",
      },
    },
    receiptBox: {
      properties: {
        address: "Post address",
      },
    },
    receiptPackages: {
      titles: {
        list: "Receipt packages",
      },
      properties: {
        managementId: "Management ID",
        storageCode: "Package storage code",
        boxName: "Post name",
        status: "Status",
        receiptNumber: "Original receipt number",
        delete: "delete",
      },
      actions: {
        new: "Add receipt package",
        edit: "Edit receipt package",
        report: "Export report",
      },
      messages: {
        confirmExport: "Finish matchings, and export report?",
      },
    },
    receiptMatchingResults: {
      titles: {
        list: "Receipt matching result",
      },
    },
    originalReceipts: {
      titles: {
        list: "Original receipts",
        details: "Original receipt Details",
        upload: "Original receipts uploading",
        selectMatchedExpense: "Select matched expense",
        exportModal: "Original receipts report file exporting",
      },
      matchingActions: {
        selectMatchedExpense: "Match this expense",
      },
      properties: {
        amount: "Amount",
        collectedAt: "Collected Date",
        image: "Original receipt",
        shopName: "Shop Name",
        transactedAt: "Date",
        companyName: "Company Name",
        storageCode: "Storage Code",
        selfStatus: "Original receipt status",
        expenseStatus: "Expense status",
        reportTitle: "Report Title",
        reportStatus: "Report Status",
        postName: "Post name",
        postAddress: "Post address",
        matchingStatus: "Matching status",
        expenseAuthorName: "Expense author name",
        notificationStatus: "Notification status",
        originalFilename: "File name",
      },
      actions: {
        export: "Export the report file by display contents",
        create: "Create the report",
      },
      matchingStatus: {
        waitMatching: "Wait matching",
        waitCreateExpense: "Expense not found",
        unsubmitted: "Unsubmitted",
        applying: 'Applying (without "wait last applying")',
        waitLastApplying: "Wait last applying",
        closeApplying: "Close applying",
        canceled: "Matching canceled",
        unnecessary: "Matching unnecessary",
        notified: "Notified",
        unnotified: "Unnotified",
      },
    },
    receiptBoxes: {
      properties: {
        name: "Post Name",
      },
    },
    sendBackings: {
      actions: {
        sendBack: "Send Back",
      },
      messages: {
        confirmSendBack: "Are you sure you want to send it back?",
        completeSendBack:
          "The original receipt has been sent back successfully",
      },
    },
    expense: {
      markAsMatched: "Mark As Matched",
      reviseMarkedAsMatched: "Revise Marked As Matched",
      confirmOriginalReceipt: "Check Original Receipt",
      cancelMatchingModal: {
        title: "The original receipt which matched the expense",
        cancel: "Cancel",
        footer: {
          button: "Cancel matching to the original receipt",
        },
      },
      forms: {
        markingAsMatchedForm: {
          labels: {
            comment: "Comment",
          },
          placeholders: {
            comment: "Reasons for mark the expense as matched.",
          },
        },
      },
      modals: {
        markingAsMatchedModal: {
          title: "Force the expense to mark as matched (with a reason)",
          footer: {
            button: "Mark As Matched",
          },
        },
        revisingMarkedAsMatchedModal: {
          title:
            'Cancel the status "Mark As Matched" of the expense, or update the comment',
          footer: {
            cancelButton: "Cancel Mark As Matched",
            updateButton: "Update Comment",
          },
        },
      },
      messages: {
        cancelConfirm: "Are you sure you want to cancel?",
      },
    },
    rootGroupPreferences: {
      actions: {
        archive: "Archive",
        unarchive: "Unarchive",
      },
      imports: {
        receiptBoxes: {
          title: "Import posts",
          caption: "You can import posts from xlsx.",
          sample: "Sample (ja)",
          guide1:
            "Column D of the sample xlsx is a remarks column. Please delete the column before import.",
          guide2:
            "You can add items by the import process, but update or delete.",
        },
      },
      titles: {
        archiveReceiptBoxes: "Archive the post",
        createPaperlessPlanSubscriptions: "Add a subscription",
        createReceiptBoxes: "Add a post",
        deletePaperlessPlanSubscriptions: "Delete a subscription",
        deleteReceiptBoxes: "Delete a post",
        paperlessPlanSubscriptions: "Paperless plan subscriptions",
        receiptBoxes: "Posts",
        search: "Search companies",
        searchReceiptBoxes: "Search posts",
        unarchiveReceiptBoxes: "Unfreeze the post",
        updatePaperlessPlanSubscriptions: "Update the subscription",
        updateReceiptBoxes: "Update the post",
      },
      labels: {
        includeArchivedBoxes: "Include frozen items",
      },
      messages: {
        archiveConfirm: "Are you sure you want to archive?",
        deleteConfirm: "Are you sure you want to delete?",
        unarchiveConfirm: "Are you sure you want to unarchive?",
      },
      models: {
        rootGroup: {
          properties: {
            name: "Company name",
          },
        },
        receiptBox: {
          properties: {
            address: "Address",
            archived: "Archived",
            name: "Post name",
            zipCode: "Zip code",
          },
          propertyDescriptions: {
            zipCode:
              "Enter in '3 digit - hyphen - 4 digit' format, e.g. 123-4567",
          },
        },
        paperlessPlanSubscription: {
          properties: {
            term: "Subscription term",
          },
        },
      },
    },
  },
  paymentRequests: {
    common: {
      alert: "アラート",
      send: "Send",
      edit: "Edit",
      delete: "Delete",
      cancel: "Cancel",
      save: "Save",
      create: "Create",
      input: "Input",
      append: "Add",
      expand: "展開",
      ok: "OK",
      back: "Back",
      close: "Close",
      copy: "コピー",
      duplicate: "Duplicate",
      enable: "Enable",
      notify: "Notify",
      doNotNotify: "No notification",
      nothing: "Nothing",
      select: "Select",
      setting: "Setting",
      colleague: "Our company",
      client: "Partner",
      reportCountUnit: "件",
      reportIndex: "Search results {{current}}/{{max}}",
      beforeChange: "変更前",
      afterChange: "変更後",
      confirm: "Confirm",
      uploading: "Uploading",
      copyToClipboard: "Copy To Clipboard",
      deselect: "Cancel",
      finish: "Completion",
      register: "Register",
      searched: "Search results",
      selected: "Selected",
      detailSettings: "Detail settings",
      ng: "NG",
      loading: "Loading",
      workerInputting: "自動入力中",
      searchWithTheConditions: "Search",
      aggregateWithTheConditions: "Aggregate",
      reflect: "Apply",
      thisMonth: "this month",
      nextMonth: "next month",
      lastMonth: "last month",
      selectDepartment: "Select Department",
      unlink: "Unlink",
      fileName: "File name",
      detail: "Details",
      blankSearch: "Blank",
      currnecyConversion: "{{currency}} conversion",
      halfWidthNumbersWithDigit: "{{digit}} half-width numbers",
      overView: "Over view",
      openAnotherWindow: "open another window",
      doDelete: "Delete",
      notSet: "Not Set",
    },
    message: {
      confirmDelete: "Are you sure you want to delete it?",
      confirmDeleteWithName:
        'Are you sure you want to delete aggregation named "{{name}}" ?',
      confirmBulkDelete:
        "If multiple selections are made, they will be deleted all at once.\nAre you sure you want to delete it?",
      underControlledOrg:
        "It is an invoice that is original book under control ",
      underControlledOrgDocument:
        "It is an document that is original book under control ",
      cannotRestoreOriginal:
        "You cannot do confirmation, export, and so on of original book if you delete it.",
      enterAggregationName: "Please enter aggregation name",
      noDataFound: "There are no data.",
      hasNotSavedDiff: "Unsaved",
      notifyToNewUsersInCharge:
        "Do you notify members who are newly assigned to person due to a change in suppliers?",
      attachedInvoiceFiles: {
        add: "Up to 10 invoice images can be added.",
        change: "You can replace the image of the invoice being displayed.",
      },
      attachedTaxDocumentFiles: {
        add: "最大10枚まで画像を追加できます",
        change: "表示中の画像を入れ替えることができます",
      },
      autoReusedPast:
        "The system automatically generates this statement based on the most recent invoice from the same supplier.",
      copyToClipboardSuccess: "Successfully copied to clipboard.",
      copyToClipboardFailed: "Failed to copy to clipboard.",
      urlIsNotValid: "The URL is incorrect.",
      guideToNationalTaxDocuments: "Tax documents",
      autoInput: "It is an invoice inputted automatically.",
      input: "Please input.",
      select: "Please select.",
      unlinkProposalsForReportItems:
        "At the same time as the deletion, proposals are also deleted from the billing details.",
      confirmUnlink: "Are you sure you want to Unlink it?",
      confirmDiscardChanges: "Edits are not saved, are you sure?",
      confirmOpenWindow:
        "Do you want to close the existing window and reopen a new one?",
      saved: "It has been saved.",
      savedSub: "You can check the saved invoices on the invoice list.",
    },
    errors: {
      common: {
        notSelected: "Not selected",
        notInput: "Not entered",
        invalidAmount: "Please enter an amount",
      },
      unitPrice: {
        inputValue: "Please enter unit price",
      },
      quantity: {
        inputValue: "Please enter quantity",
      },
      costAllocations: {
        totalNotFull: "Total value is not 100%.",
        totalAmountNotFull:
          "The total amount does not match the amount with tax of the line.",
        totalAmountNotFullForExcludeTax:
          "The total amount does not match the amount without tax of the line.",
        hasZeroPercentage: "0% exists in the prorated percentage.",
        hasZeroAmount: "Zero exists in the prorated amount.",
        sumOfPercentagesMustNotBeZero:
          "The total prorated percentage cannot be registered as 0%.",
        sumOfAmountsMustNotBeZero:
          "The total prorated amount cannot be registered as 0.",
        duplicateDepartment: "Duplicate departments.",
        emptyOrInvalidDepartment:
          "A department that does not exist has been specified.",
      },
      genericFieldItems: {
        required: "Required Fields",
        multipleNotAllowed: "Multiple selections are not allowed.",
      },
      attachedInvoiceFiles: {
        overLimit:
          "Could not be added because the maximum number of files has been exceeded.",
        invalidMimetype: "Could not be changed due to unsupported extension.",
      },
      proposals: {
        notSameSupplier:
          "The supplier set up in the proposal and invoices are different.",
      },
      exports: {
        feePayerNotSelected: "手数料負担先を選択してください",
      },
      preference: {
        updateFailed: "設定の更新時にエラーが発生しました",
      },
      foreignCurrency: {
        getFailed:
          "An error occurred while retrieving foreign currency information.",
      },
      registratedNumber: {
        inputAlert: "Please enter 13 digits.",
      },
    },
    notifications: {
      invoiceCreated: "An invoice registered",
      invoiceDeleted: "Invoice deleted",
      invoiceDeletingNotSelected: "Deleting invoices is not selected",
      invoiceUpdated: "Invoice updated",
      noteUpdated: "Note updated",
      proposalSaved: "稟議を保存しました",
      invoiceNotSelected: "Invoice is not selected",
      updatingInvoiceNotSelected: "Invoice for updating is not selected",
      invoiceApproved: "Invoice approved.",
      invoiceUnapproved: "The invoice was handled as unapproved successfully.",
    },
    titles: {
      menuTitle: "Invoices",
      histories: "Aggregation Histories",
      registerNewInvoice: "Register a new Invoice",
      createNewInvoice: "Invoice (Register new)",
      reportItemsEdit: "Line Setting",
      paymentEdit: "Payment Setting",
      reportItemsFormColumnSetting: "表示列設定",
      notifyToEmployee: "Members notification",
      previewOfficialize:
        "Differences from the registered information of the supplier",
      previewWorkerInvoiceInput:
        "Differences from the information on the invoice",
      timeline: "Timeline",
      reportAlert: "請求書アラート",
      reportRequest: "支払依頼申請",
      reportTemplates: "Invoice Templates",
      reportTemplateCreate: "Registar a new Invoice Template",
      reportTemplateUpdate: "Edit a Invoice Template",
      reportTemplateShow: "Show a Invoice Template",
      proposalRequest: "稟議申請",
      consolidatedReportRequest: "一括支払依頼申請",
      proposalCreate: "発注稟議作成",
      proposalInput: "Details",
      proposalTimeline: "Timeline",
      nationalTaxDocuments: "Tax Documents",
      nationalTaxDocumentCreate: "Register a new Tax Document",
      nationalTaxDocumentDetail: "国税関係書類詳細",
      nationalTaxDocumentBulkUpdate:
        "Bulk update Tax Documents {{count}} selected",
      nationalTaxDocumentTypeSelectorModal:
        "Select a Tax Document Type Category",
      nationalTaxDocumentTypeAliasSelectorModal: "Select a Tax Document Type",
      waitingWorkerDocumentInputs: "Data Entries",
      waitingWorkerDocumentInputsDetail: "Data Entry Detail",
      consumptionTax: "消費税",
      supplierShow: "Supplier Detail",
      etc: "Others",
    },
    actions: {
      registerInvoice: "Register Invoice",
      next: "Next",
      previous: "Previous",
      supplierShow: "Supplier Detail",
      save: "save",
      closeImageArea: "Close image area",
      openImageArea: "Open image area",
      closeAnotherWindow: "Close another window",
    },
    authorities: {
      help: {
        admin: "システム設定メニューと、取引先画面の閲覧・編集ができます",
        accountant:
          "すべての書類と、取引先画面、集計者専用画面の閲覧・編集ができます",
        supplierManage: "取引先画面(取引先設定タブのみ)の閲覧・編集ができます",
      },
    },
    select: {
      debitInvoiceCategory: "借方請求明細科目選択",
      project: "プロジェクト選択",
    },
    previewWorkerInvoiceInput: {
      description:
        "Update the current entry with the information on the invoice.",
      beforeChange: "Current entry",
      afterChange: "Information on the invoice",
    },
    previewOfficialize: {
      description:
        "Update a supplier's registration information with the current entry.",
      beforeChange: "Supplier's registration information",
      afterChange: "Current entry",
    },
    periodicReport: {
      title: "Periodic Payment Setting",
      description: "日程を指定して、請求書データを自動で作成します",
      notice:
        "Periodic registration of invoices will begin on or after the day following the change of settings.",
      relativeMonthOption: "登録日の{{relativeMonth}}{{day}}日",
      // eslint-disable-next-line camelcase
      relativeMonthOption_end: "登録日の{{relativeMonth}}末日",
      relativeMonthLabels: [
        "This month of registration date",
        "Next month of registration date",
      ],
      relativeMonth: [
        "This month",
        "Next month",
        "{{differenceMonth}} months later",
      ],
      dayOfWeek: [
        "Sunday",
        "Monday",
        "TuesDay",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      days: "{{day}}",
      endOfMonth: "end of the month",
      cyclicEventOption: {
        weekly:
          "毎週$t(paymentRequests.periodicReport.cyclicEventOption.dayOfWeek.{{day}})",
        monthly: "{{day}} of every month",
        // eslint-disable-next-line camelcase
        monthly_end: "End of every month",
        labels: {
          weekly: "Every Week",
          monthly: "Every Month",
        },
      },
      term: "Valid term",
      relatedTaxDocuments: "Related documents",
      relatedTaxDocumentsSelection: "Related documents selection",
      taxDocuments: "Tax documents",
      hasTaxDocuments: "Availability of related documents",
      bulkUnlink: "Bulk unlink",
      error: {
        supplierIsEmpty: "取引先が設定されていません",
        supplierIsBlocked:
          "凍結されているため定期支払が作成されないようになっています",
      },
      message: {
        confirmUnlink: "Are you sure you want to bulk unlink?",
      },
    },
    properties: {
      undefined: "",
      null: "",
      searchCondition: "Search conditions",
      filterBySpecified: "Search with the specified conditions",
      amount: "Total amount",
      paidAmount: "Total amount",
      foreignCurrencyTotalAmounts: "Foreign Currency Total Amounts",
      currency: "Currency",
      rate: "Rate",
      sequenceNumber: "Report ID",
      consolidatedReportSequenceNumber: "Consolidated Report ID",
      requestName: "Request Name",
      requestDate: "Request Date",
      approvalStatus: "Approval status",
      requestStatus: "Request Status",
      requesterName: "Applicant",
      belongingDepartment: "Department",
      withholding: "Withholding tax",
      aggregationId: "Aggregation ID",
      scheduleAt: "Payment Scheduled Date",
      supplierInCharge: "Supplier In Charge",
      supplier: "Supplier",
      supplierName: "Supplier Name",
      supplierCode: "Supplier Code",
      supplierManager: "取引先管理",
      personInCharge: "Person In Charge",
      departmentInCharge: "Department in charge",
      groupName: "Belonging Department",
      dueAt: "Payment Due Date",
      appropriatedAt: "Accounting Date",
      registeredAt: "Registered Date",
      registeredAtWithTime: "Registered date",
      receivedAt: "Receipt date",
      lastApprovedAt: "Final Approval Date",
      enclosedReport: "関連請求書",
      approvalFlowName: "Workflow name",
      paymentMethod: "Payment Method",
      methodType: {
        unselected: "Not selected",
        transfer: "Transfer",
        debit: "Direct debit",
        cash: "Cash",
        // eslint-disable-next-line camelcase
        payment_slip: "Payment slip",
        card: "Card",
        bill: "Bill",
        check: "Check",
        // eslint-disable-next-line camelcase
        electronically_recorded_monetary_claims:
          "Electronically recorded monetary claims",
        // eslint-disable-next-line camelcase
        overseas_remittance: "Overseas remittance",
        other: "Other",
        undefined: "",
      },
      sourceType: {
        external: "recieve",
        internal: "issue",
      },
      bank: "Bank Name",
      bankBranch: "Branch Name",
      bankAccountType: "Account Type",
      ordinal: "Ordinary",
      current: "Current",
      saving: "Saving",
      other: "Other",
      bankAccountNumber: "Number",
      bankAccountHolder: "Holder Name",
      status: "Status",
      created: "Created",
      approved: "Approved",
      unpaid: "Unpaid",
      paid: "Paid",
      paymentStatus: "Payment Status",
      unexported: "Unexported",
      exported: "Exported",
      documentTypeAlias: "Tax Document Type",
      multiPayerDepartment: "複数の費用負担部署",
      accountingDataExportStatus: "Accounting Data Export Status",
      aggregationMethod: "Aggregation Method",
      amountInputType: "金額入力",
      includeTax: "With Tax",
      excludeTax: "Without Tax",
      amountIncludeTax: "Billing amount",
      registratedNumber: "Registrated Number",
      asInvoice: "Qualified Invoice",
      asInvoiceShort: "Qualified invoice",
      asInvoiceLong: "Qualified invoice",
      asInvoiceForSearch: "Qualified Invoice",
      asInvoiceCheck: {
        true: "Yes",
        false: "No",
      },
      reportNumber: "Invoice number",
      memo: "Memo",
      invoiceMemo: "Invoice Note",
      reportItemMemo: "Line note",
      consumptionTax: "消費税",
      targetTotalExcludeTax: "{{percentage}}% eligible",
      sendFrom: "Sender",
      reportItem: {
        name: "Line name",
        amountIncludeTax: "Amount with tax",
        shortAmountIncludeTax: "With tax",
        amountExcludeTax: "Amount without tax",
        amountConsumptionTax: "Tax amount",
        amountWithholding: "Withholding tax",
        shortAmountWithholding: "源泉",
        foreignCurrency: "Foreign currency",
        debitInvoiceCategory: "Debit invoice line item",
        creditInvoiceCategory: "Credit invoice line item",
        taxCategory: "Tax category/rate",
        proposal: "Proposal",
        purchaseOrderNumber: "Purchase order number",
        costAllocation: "Cost allocated department",
        departmentInCharge: "Department",
        project: "Project",
        participant: "Participants",
        genericFieldItems: "Generic Fields",
        memo: "Note",
        reportItemMemo: "Line note",
        foreignCurrencyAmountIncludeTax: "Foreign currency amount with tax",
        foreignCurrencyAmountExcludeTax: "Foreign currency amount without tax",
        quantity: "Quantity",
        unitPrice: "Unit price",
        projectDisplayId: "Project ID",
        projectName: "Project Name",
      },
      reportLabel: "Invoice label",
      createdAt: "Created At",
      completedAt: "Completion Date",
      aggregationName: "Aggregation Name",
      numberOfInvoices: "Number of Invoices",
      totalAmount: "Total Amount",
      totalAmountIncludeTax: "税込金額",
      totalAmountWithholding: "Withholding tax",
      paymentAmount: "Total amount",
      unaggregated: "Unaggregated",
      aggregated: "Aggregated",
      aggregationStatus: "Aggregation Status",
      payFee: "Pay fee",
      none: "Unspecified",
      our: "our",
      their: "their",
      feeTarget: "Those who are covered fees",
      searchScope: "Search scope",
      inCharge: "I am in charge",
      noCharge: "No charge",
      nationalTaxDocumentsType: "Tax Document Type Category",
      nationalTaxDocumentTypeAlias: "Tax Document Type",
      transactedAt: "Transaction Date",
      all: "All",
      onlyDeleted: "Only Deleted",
      onlyMoved: "Only Moved",
      new: "New",
      stampedAt: "タイムスタンプ",
      validStampedAt: "有効性",
      stamped: "あり",
      notStamped: "なし",
      valid: "Valid",
      invalid: "無効",
      result: "Result",
      message: "Message",
      download: "ダウンロード",
      userDepartment: "Belonging Department",
      genericFields: {
        dataSet: {
          item: {
            code: "code",
            name: "name",
          },
        },
      },
    },
    reports: {
      exports: {
        actions: {
          exportColumns: "Start creating a file with the search results",
        },
      },
      update: {
        images: {
          message: {
            failed:
              "An error occurred while saving the invoice files. Please reload the invoice and re-update the files. If the error persists, please contact customer-support.",
          },
        },
      },
      searchScope: {
        help: {
          all: "View all available invoices.",
          inCharge:
            "Only invoices for which you or your department is specified as the supplier on the invoice will be displayed.",
          noCharge:
            "Only invoices with unregistered suppliers specified will be displayed.",
          deleted: "Include deleted invoices.",
        },
      },
      searchForm: {
        exclusionSearch: "Exclusion",
      },
      enclosedReport: {
        help: "同封（メール・封筒）されていた請求書・その他資料を確認できます\n\n※メール送付の場合、暗号化ファイルや対象外拡張子のファイルはデータ化対象外です",
        inputting: "自動入力中",
        waiting: "件の請求書が自動入力中です",
      },
      diffWorkerInvoiceInput:
        "There is a difference between the information on the invoice and the information entered below.",
      hasAlert: "Alert message",
      proposals: {
        sameSupplierAndApprovedOnly:
          "You can only select purchase order proposals that are tied to the same supplier as this invoice and have been approved.",
        proposalDetail: "Proposal detail",
      },
      reportRelatedDocumentsIndicator: {
        messages: {
          empty: "No Related document",
          available: "",
          nItems: "{{count}} Related document",
        },
        actions: {
          edit: "Edit",
        },
      },
      reportItemsIndicator: {
        messages: {
          noItems: "No Line",
          itemsAvailable: "Line",
        },
        actions: {
          addItem: "Add",
          editItem: "Edit",
        },
      },
      reportRelatedDocument: {
        documentTypes: {
          report: "Invoice", // payment_request_reportには書類種別がないので、ここでi18nを定義
          empty: "Tax document type unspecified",
        },
        documentNames: {
          report: "Invoice", // payment_request_reportには書類名がないので、ここでi18nを定義
          empty: "Document name not entered",
        },
        labels: {
          enclosed: "Enclosed",
          indexLink: "({{value}})",
          indexLinkPopover: {
            noAttached: "Related documents",
            attached:
              "There are {{value}} related documents that are tied together.",
          },
          hasAttached:
            "There are {{value}} related documents that are tied together.",
          seeAll: "See all",
          backToRelatedList: "Back to related documents",
          searchRelatedDocuments: "Search related documents",
        },
        titles: {
          base: "Related documents",
          attached: "Related documents(Ties only)",
          show: "Related document(Details)",
        },
        actions: {
          attach: "Click to associate with the invoice on the left",
          detach: "Click to unassociate",
          openDetail: "Open detail screen",
          loadMore: "Loading more",
          backToRelationIndex: "Back to the related documents",
        },
        messages: {
          attached: "Added related document.",
          detached: "Removed related document.",
          closed: "You do not have permission to view this.",
          noAttachedRelatedDocument:
            "There are no related documents tied to the invoice.\nIf you want to tie in, search and tie in.",
          attachSuccess: "Related documents have been tied.",
          detachSuccess: "Untied related documents.",
          failedReloadAttachedRelatedCount:
            "Failed to retrieve the number of related documents associated. Please reload the screen.",
        },
        helps: {
          isNotSameSupplier:
            "It is not associated to the same supplier as the invoice.",
        },
        search: {
          titles: {
            searchRelatedDocuments: "Refine related documents",
            selectDocumentTypeAliases: "Select tax document type",
          },
          labels: {
            related: "Related documents",
            notRelated: "Unrelated documents",
          },
          placeholders: {
            documentName: "Enter the document name to be searched",
          },
          actions: {
            expand: "Specify detailed search conditions",
          },
          helps: {
            hasOtherSupplierInRelated:
              "Because some documents are associated with other suppliers, we search from all suppliers by default.",
          },
        },
        viewer: {
          titles: {
            selector:
              "Select the related documents to be displayed on the left side",
            viewer: "Displaying images of related documents",
          },
        },
        fileViewer: {
          previewArea: "Image Preview Area",
          description: "※Select the related document to view the image.",
        },
        modals: {
          requestTypeTargetSelector: {
            title: "Select the tax document type of related documents",
            labels: {
              report: "Invoice",
            },
            actions: {
              clearSelection: "Clear selection",
              searchPlaceholder: "Tax document type name to be searched",
            },
          },
        },
      },
      timestampVerify: {
        title: "Timestamp verification",
        searchedTarget:
          "Performs timestamp verification on all expenses that meet the search conditions.",
        selectedTarget:
          "Performs timestamp verification on selected expenses ({{count}} invoices)",
        sample: "Click here to download a sample of the validation results.",
        warn: "Please note that time stamp verification may take some time to complete. Please understand that it may take some time to complete.",
        showAll:
          "Show all verified results (normally shows the 5 most recent results)",
        start: "Start verification",
        started: "Time stamp verification of expenses has been started.",
        completed: "Time stamp verification of expenses has been completed.",
        inprogress: "Expense's timestamp validation in progress.",
        error: "Expense's timestamp verification failed.",
        unit: "Verified receipt image",
      },
      resolutionInformations: {
        link: "Scanner Requirement/ dpi：{{satisfied}}",
        caution: "(Check this requirement for scanned data.)",
        title: "Scan Information",
        error: "Image information could not be retrieved.",
        pageNumber: "{{pageNumber}} page of PDF：{{satisfied}}",
        resolution: {
          dpi: "DPI：DPI{{dpi}}",
          dpiDifferent: "DPI：DPI{{width}}x{{height}}",
        },
        pixels: "Pixels：{{width}}x{{height}}（{{total}}）",
        colorspace: "Colorspace：{{gradation}} shades of grey",
        digital: "Electronic Transaction Data",
        open: "Detail",
        close: "Back",
      },
      attachedInvoiceFiles: {
        unsupportedTimestamp:
          "Files except images(jpg, png, pdf) will not be timestamped",
      },
      errors: {
        registratedNumber: {
          updateFailed:
            "An error occurred when updating the registrated number.",
        },
      },
      asInvoice: {
        notice:
          "Even if your registrated number is verified, you may not meet the requirements for a qualified invoice.",
      },
      alerts: {
        consumptionTaxDifferenceCheckOnInvoice: {
          message:
            "There is a difference between the consumption tax amount on the invoice and the consumption tax amount currently entered",
        },
      },
      labels: {
        youInCharge: "You are in charge of invoice.",
      },
      pagination: {
        count: "{{order}} / {{total}}",
      },
      attachedInvoiceFileBulkDownloadRequests: {
        words: {
          bulkDownload: "Download invoice images in bulk",
          gotoFileNameSetting: "Go to configure invoice file names",
          nReportsSelected: "invoice(s) are selected",
          showAllDownloadHistories:
            "Show all download histories (normally shows the 5 most recent results).",
        },
        messages: {
          description:
            "This will download the images of the selected invoices in bulk. The downloaded files will be saved in ZIP format.\nThe file names of the images included in the ZIP can be configured in the system settings.",
          needAccountantPrivilegeToConfigure:
            'To configure the file names of the downloaded images, you need to have "Aggregator" privileges.',
          youNeedSelectReportsToDownload:
            "No invoices selected. Please select the invoices you want to download in bulk.",
          selectedReportExceedsMax:
            "You can select up to {{max}} invoices at a time. {{selected}} invoices are selected.",
        },
        actions: {
          startBulkDownload: "Create a zip file from selected invoice images",
        },
        fileNameFormat: {
          variables: {
            amount: "Amount",
            appropriated: "Accounting Date",
            supplier_name: "Supplier Name", // eslint-disable-line camelcase
            sequence_number: "Report ID", // eslint-disable-line camelcase
            file_index: "Image Index", // eslint-disable-line camelcase
          },
          editCard: {
            title: "File Name Format",
            messages: {
              description1:
                "You can set the file name format of the invoice images to be downloaded in bulk.",
              description21:
                "In addition to alphanumeric characters and some symbols",
              description22: ", you can use the following variables.",
              description3:
                "You must include the variables 'Report ID' and 'Image Index'.\nSpaces cannot be used.",
            },
            words: {
              example: "Example:",
            },
            labels: {
              formatString: "File Name Format",
            },
          },
        },
      },
    },
    reportItems: {
      title: {
        lines: "Lines",
        linesCount: "（{{value}} lines）",
        new: "Creating Lines",
        edit: "Edititng Lines",
      },
      messages: {
        createSuccess: "Created line.",
        createSuccessSub: "You can check the created details on the last line.",
        updateSuccess: "Updated line.",
        copySuccess: "Copied line.",
        copySuccessSub: "You can check the copied details on the last line.",
        selectingCount: "Selected: {{count}} lines",
        destroySuccess: "Deleted lines.",
        errors: {
          failedToUploadFile: "Failed to upload the file.",
          failedUpdateForTaxIncluded:
            "Failed to update Tax category/rate. Please reload the screen.",
        },
        alertHeader:
          "If there are errors, warnings, or automatically posted lines, an icon will be displayed in this column.",
        warnings: {
          hasAlert: "There is an invoice alert with check level 'warning'.",
        },
      },
      labels: {
        addDepartment: "Add department",
        costAllocationDepartmentCriteria: "Cost allocated department criteria",
        deleteAll: "Delete all",
        costAllocationValue: {
          amount: "{{name}}={{value}}",
          percentage: "{{name}}={{value}}%",
        },
        valueTypes: {
          inputAsPercentage: "Percentage",
          inputAsAmount: "Amount",
        },
        amountIncludeTax: "Amount（tax included）",
        amountExcludeTax: "Amount（tax excluded）",
        cost: "Cost",
        reminingAmount: "Remining {{value}}",
        tableSetting: "Table Settings",
        amountComsumptionTax: "{{comsumptionTax}}",
        targetAmount: "（Target amount：{{excludeTax}}）",
        lineDuplicate: "Duplicate a line",
        lineDestroy: "Delete a line",
        lineActionHelp: "Duplicate or delete a line",
        participant: {
          colleague: "Our company {{value}} people",
          client: "Partner {{value}} people",
        },
      },
    },
    reportTemplates: {
      name: "Template Name",
      autoCreation: "Auto Journal Entries",
      autoInput: "Automated Input",
      supplierSettings: "Supplier Settings",
      workerInput: "Priority Autofill Information",
      default: "default",
      useReportItemAutoCreation: "autoCreation",
      carryoverAmountConfig: "Carryover Amount Config",
      useCarryoverAmount: "Use Carryover Amount",
      relativeMonthOption:
        "After registration date of {{relativeMonth}} {{day}}",
      // eslint-disable-next-line camelcase
      relativeMonthOption_end:
        "After registration date of {{relativeMonth}} last day",
      actions: {
        addReportTemplate: "Add invoice template",
      },
      help: {
        default:
          "If no invoice template is set up for a supplier or no supplier is specified, the default invoice template will be applied.",
        useCarryoverAmount:
          "Use: The carryover amount will also be reflected on your invoice lines.\nDo not use: The carryover amount will not be reflected on your invoice lines.",
      },
      form: {
        labels: {
          description:
            "You can set a template to be applied when creating an invoice.",
        },
        paymentSettingForm: {
          workerInputPriority: {
            supplierNamePriority: "Supplier Name",
            paymentInformationPriority: "Payment Information",
            bankAccountHolderPriority: "Bank Account Holder",
            payFeePriority: "Pay fee",
          },
          descriptions: {
            paymentInformation:
              "Payment Information(Payment Method, Bank Name, Branch Name, Bank Account Type, Bank Account Number)",
          },
          relativeMonth: {
            "-1": "last month",
            0: "this month",
            1: "next month",
            2: "2 months later",
            3: "{{differenceMonth}} months later",
          },
          days: "{{day}}",
          endOfMonth: "last day",
          registeredAtFrom: "After registration date of",
          of: "",
          calculatedDay: "(If you register today: {{- calculatedDay}})",
          labels: {
            supplierDescription:
              "When registering invoices with automatic entry, input information can be specified for each item.",
            dueAtPriorityLabel:
              "Enter initial value only if {{columnName}} is empty when creating invoice.",
            dueAtPriorityDescription:
              "If {{columnName}} is entered automatically, that value is given priority.",
            dueAtNotSelected:
              "(If not selected, the due date will be set as indicated on the invoice.)",
          },
        },
        reportItemsForm: {
          labels: {
            description:
              "When invoices are registered for automatic entry, the details are automatically created from the most recently updated invoices of the same supplier.",
          },
        },
        etcForm: {
          help: {
            memo: "If the value of the autofill exists, the value of the template is ignored. If the value of the autofill is empty, the value of the template is applied.",
          },
        },
      },
      modal: {
        delete: {
          hasSupplierAlert:
            "This is an invoice template that has been set up for a supplier. Are you sure you want to delete it?",
        },
      },
    },
    reportTemplateSelector: {
      titles: "Select invoice template",
      newButton: "Click here to create a new template",
    },
    invoiceSettings: {
      labels: {
        title: "Invoice label list",
        description:
          "Configurable labels can be registered from [Invoices summary > Other tab] (Max. 20 data)",
        table: {
          headers: {
            label: "label",
          },
        },
        modal: {
          title: {
            createLabel: "Create a Report Label",
            editLabel: "Edit the Report Label",
          },
          name: "name",
          selectLabelColor: "Select label color",
        },
        messages: {
          success: {
            create: "Created invoice label.",
            update: "Updated invoice label.",
            delete: "Deleted invoice label.",
          },
        },
      },
    },
    reportRequests: {
      created: "Applied.",
    },
    proposals: {
      sequenceNumber: "ID",
      title: "Proposal name",
      content: "Details",
      supplierAuthorityUsers: "取引先担当者(複数選択可)",
      supplierAuthorityGroups: "取引先担当部署(複数選択可)",
      debitInvoiceCategories: "Debit invoice line item",
      costAllocationDepartments: "Cost allocated department",
      projects: "Projects",
      buttons: {
        uploaderInformation: "送付先を確認",
        usageStatus: "消化状況を確認",
      },
      modals: {
        totalBudget: "稟議金額",
        totalUsed: "消化済み金額",
        reportSequenceNumber: "請求書",
        titles: {
          uploaderInformation: "請求書の送付先を確認",
          usageStatus: "消化状況",
        },
      },
      alerts: {
        supplierWillBeSavedWhenApproved:
          "現在設定されている取引先は未登録ですが、最終承認時に登録されます。",
        checkSendingInformation:
          "「送付先を確認」ボタンより、請求書の送付先をご確認ください。",
      },
      messages: {
        noData: "Proposal Not Found.",
      },
    },
    invoiceTable: {
      alert: "Alert",
      memo: "Note",
      invoiceMemo: "Invoice Note",
      storageNumber: "Original book management number",
      createdByForeignCurrency:
        "This is an invoice which is used foreign currency",
      notApprovedYet: "Unapproved invoice.",
      inCharge: "In charge",
      bankName: "Bank Name",
      sender: "Sender",
      consolidatedReport: "Consolidated Report ID",
      supplierCode: "Supplier code",
      supplierName: "Supplier name",
      costAllocatedDepartment: "Cost Allocated Department",
      amount: "Total Amount",
      asInvoice: "Qualified Invoice",
      purchaseOrderNumber: "Purchase Order Number",
      reportLabel: "Invoice Label",
      registeredAt: "Registered Date",
    },
    aggregationTable: {
      invoiceId: "Invoice ID",
      aggregateSelected: "Aggregate selected {{count}} invoices",
      images: "Images and so on",
    },
    proposalTable: {
      title: "Proposals",
      redirectToNew: "Register",
      searchForm: {
        labels: {
          title: "Proposal name",
          supplierName: "Supplier Name",
          supplierCode: "Supplier Code",
          costAllocationDepartment: "Cost allocated department",
          projectDisplayId: "Project ID",
          projectName: "Project name",
          memo: "Note",
          blockedStates: "Consumption",
          usedStates: "Consumption",
          statuses: "Status",
        },
        values: {
          blockedStates: {
            yes: "Disable",
            no: "Enable",
          },
          usedStates: {
            no: "unused",
          },
        },
      },
      properties: {
        title: "Proposal name",
        totalBudget: "Total amount",
        status: "Status",
        usageStatus: "Consumption",
        content: "内容",
        supplierCode: "Supplier code",
        supplierName: "Supplier name",
        costAllocationDepartmentNames: "Cost allocated department",
        projectNames: "Project",
        memo: "Note",
        sequenceNumber: "ID",
      },
      buttons: {
        block: "Disable",
        unblock: "Enable",
      },
      messages: {
        confirm: {
          block: "本当に無効化しますか？",
          unblock: "本当に有効化しますか？",
        },
        success: {
          blocked: "データを無効化しました",
          unblocked: "データを有効化しました",
        },
      },
    },
    nationalTaxDocument: {
      properties: {
        name: "Document Name",
        createdAt: "登録日",
        sequenceNumber: "Document ID",
        amountIncludeTax: "Transaction Amount",
        foreignCurrencyAmountIncludeTax: "Foreign Currency Amount",
        label: "Tax Document Label",
        registratedNumber: "Registrated Number",
        image: "Image",
        dedicatedinputtingNotice: "Automatic inputting...",
        dataTarget: {
          label: "Search scope",
          taxDocument: "Tax documents",
          attachedFile: "Attached files",
        },
        relatedAttachedFiles: "Attached Files",
      },
      actions: {
        type: {
          searchPlaceholder: "Documents Type Category to search",
        },
        typeAlias: {
          searchPlaceholder: "Document Type to search",
          clearSelection: "Clear Selection",
        },
        register: {
          manual: "Manual",
          bulkUpload: "Multiple registration",
        },
      },
      titles: {
        selectTaxDocumentType: "Select Document Type Category",
        registerTaxDocument: "register Tax Document",
        exportColumns: "Start creating a file with the search results",
      },
      messages: {
        documentCreated: "{{documentName}} registered.",
        documentUpdated: "updated.",
        documentOfBeingInCharge: "document of being in charge",
        deleteFailed: "書類の削除に失敗しました",
        mustInputTransactedAtWhenUseForeignCurrency:
          "To enter a foreign currency, enter the Transaction Date.",
        registratedNumberUpdateFailed: "登録番号の更新に失敗しました",
        exportSize: "You can export at most {{size}} reports at once.",
      },
      modals: {
        createConfirmationModal: {
          title: "警告",
          content:
            "国税関係書類は作成後に削除ができません。\n登録内容が正しいことをご確認の上、データを保存してください。",
        },
        nationalTaxDocumentEditModal: {
          actions: {
            displayLargely: "Edit histories",
            cancelDedicatedInputting: "Change Manual Inputting",
            workerInputCorrectionRequest: "Report incorrect input",
          },
          confirmBody:
            "It is not possible to revert to automatic input once it is executed. Do you want to perform the cancellation?",
        },
        moveConfirmationModal: {
          title: "Move a file to Invoice",
          contentHeader:
            "実行後、国税関係書類一覧に表示されなくなります。元に戻せません。",
          contentMain: {
            moveByWorkerInput:
              "自動入力を利用し、選択書類を請求書一覧に移します。",
            mustNotManipulateUntilWorkerInputCompleted:
              "※自動入力が完了するまで操作できなくなります",
            moveByManual:
              "現在の登録情報を利用し、移す場合は手入力を選択してください。",
            moveByManualNotImage:
              "Move the selected documents to the list of reports, using saved data.",
            workerInputMimetype:
              "* Automatic input is available for pdf, jpg, and png.",
          },
          actions: {
            moveToReportWorkerInput: "自動入力",
            moveToReport: "手入力",
          },
          message: {
            moveToReportWorkerInputCompleted: "自動入力への処理が完了しました",
            moveToReportCompleted: "請求書への変更が完了しました",
            returnedInvalidResponse:
              "請求書への手動移行処理で不正な結果が返ってきました",
          },
        },
        exportModal: {
          actions: {
            exportColumns: "Start creating the latest accounting data",
          },
        },
        multiImageUpload: {
          title: "Multiple registration of Tax Documents",
          uploadCompleted: "Completed. Success: {{success}}, Error: {{error}}",
          uploadInProgress: "Registrating {{uploaded}}/{{total}}...",
          upload: "Register",
          cancel: "Cancel",
          close: "Close",
          selectPlaceholder: "Drag & Drop or Select PDF or image files",
          fileLimit: "{{count}}/300",
          emptyImages: "No file selected",
          errors: {
            unacceptableMIMEType:
              "{{files}} can not uploade because of unavailable file type.",
            tooManyFiles:
              "Too many files. Please re-select with less than {{count}} files.",
            uploadCanceled: "Canceled",
            tooLargeFile:
              "File size is too large. Please re-select with a file size of 5 GB or less.",
            systemError: "A system error has occurred. Please contact support.",
          },
          form: {
            useDedicatedInput: "Use automatic input with OCR",
            useOperatorInput: "Use automatic operator input",
            sourceTypeHelp:
              "Depending on the selection, the following information on the document will be read as supplier name.\nreceive: the name of the issuer of the document\nissue: Name of the recipient of the document",
            collapse: "Enter transaction date, transaction amount",
            automaticInputTargetPlaceholder: "(Automatic input)",
          },
          ocrExtensionWarning:
            "Files except images (jpg, png pdf) will only be registered without being converted into data.",
        },
      },
      request: {
        moveToReport: "Move a file to Invoice",
      },
      timestampVerify: {
        title: "Timestamp verification",
        searchedTarget:
          "検索条件を満たす全国税関連書類を対象にタイムスタンプ検証を実行します",
        selectedTarget:
          "選択した国税関連書類({{count}}件)を対象にタイムスタンプ検証を実行します",
        sample: "検証結果のサンプルのダウンロードはこちら",
        warn: "タイムスタンプ検証は完了までお時間を要する場合がございます。ご了承ください。",
        showAll: "全ての検証結果を表示(通常では最新5件の結果を表示します)",
        start: "検証を開始",
        started: "国税関連書類のタイムスタンプ検証を開始しました",
        completed: "国税関連書類のタイムスタンプ検証が完了しました",
        inprogress: "国税関連書類のタイムスタンプ検証中です",
        error: "国税関連書類のタイムスタンプ検証に失敗しました",
        unit: "検証済み国税関連書類画像",
        timeout:
          "Timestamp verification for National Tax documents failed.\nPlease reduce the number of validation targets and try again.",
      },
      searchForm: {
        exclusionSearch: "除外検索",
        help: {
          dataTarget: {
            attachedFile:
              "Documents uploaded by suppliers as attachments, such as statements and man-hour breakdowns will be displayed.",
          },
        },
      },
      relatedAttachedFileSourceLink: {
        label1: "（Attachments with an ID of ",
        label2: "）",
      },
    },
    history: {
      common: {
        add: "追加",
        edit: "更新",
        delete: "削除",
        move: "移動",
      },
      nameFormats: {
        byAgent: "({{name}} substituted)",
        viaApi: "API",
      },
      noInput: "Not yet entered",
      report: {
        title: "Change History",
        byUser: "Updated by",
        historyType: "Change summary",
        createdAt: "Updated date",
        changes: "Changes",
        edit: "Update",
        delete: "Delete",
        create: "Add",
        move: "Move",
        moveEdit: "Editing (Automatic editing by moving)",
        moveWorkerEdit: "Editing (Editing by automatic input)",
        /* eslint-disable camelcase */
        attached_invoice_files: "Invoice image",
        payment_method_id: "Payment Method",
        due_at: "Payment Due Date",
        schedule_at: "Payment Scheduled Date",
        appropriated_at: "Accounting date",
        pay_fee: "Transfer fee",
        method_type: "Payment Method",
        supplier_name: "Supplier Name",
        bank_name: "Bank Name",
        bank_branch_name: "Branch Name",
        bank_account_type: "Account Type",
        bank_account_number: "Number",
        bank_account_holder_kana: "Holder Name",
        total_amount: "Amount",
        registrated_number: "Registrated Number",
        as_invoice: "Qualified Invoice",
        paymentMethodType: {
          transfer: "Transfer",
          debit: "Direct debit",
          cash: "Cash",
          // eslint-disable-next-line camelcase
          payment_slip: "Payment slip",
          card: "Card",
          bill: "Bill",
          check: "Check",
          // eslint-disable-next-line camelcase
          electronically_recorded_monetary_claims:
            "Electronically recorded monetary claims",
          // eslint-disable-next-line camelcase
          overseas_remittance: "Overseas remittance",
          other: "Other",
        },
        ordinal: "Ordinary",
        current: "Current",
        saving: "Saving",
        other: "Other",
        their: "their",
        our: "our",
        report_number: "Invoice number",
        received_at: "Receipt date",
        memo: "Note",
        tax_included: "Input tax included/excluded",
        document_type: "",
        otherSupplier: "Another supplier",
        null: "",
        "": "",
        /* eslint-enable camelcase */
      },
      reportItemTable: {
        edit: "Update",
        create: "Add",
        delete: "Delete",
        // eslint-disable-next-line camelcase
        move_edit: "Update (automatic editing by moving)",
      },
      reportItem: {
        edit: "Update line",
        create: "Add line",
        delete: "Delete line",
        /* eslint-disable camelcase */
        move_edit: "Editing line (automatic editing by moving)",
        debit_invoice_category: "Debit invoice line item",
        tax_category: "Tax category/rate",
        project: "Project",
        credit_invoice_category: "Credit invoice line item",
        name: "Line name",
        amount_include_tax: "Amount with tax",
        amount_exclude_tax: "Amount without tax",
        unit_price: "Unit price",
        quantity: "Quantity",
        amount_consumption_tax: "Tax amount",
        amount_withholding: "Withholding tax",
        foreign_currency_id: "Foreign currency",
        foreign_currency_amount_include_tax: "Foreign currency amount with tax",
        foreign_currency_amount_exclude_tax:
          "Foreign currency amount without tax",
        exchange_rate: "Foreign currency rate",
        purchase_order_number: "Purchase order number",
        memo: "Line note",
        cost_allocation: "Cost allocated department",
        participant: "Participants",
        colleague: "Our company",
        client: "Partner",
        payment_request_proposal_sequence_number: "Proposal",
        null: "",
        "": "",
        /* eslint-enable camelcase */
      },
      reportLabel: {
        create: "Add label",
        delete: "Delete label",
      },
      proposal: {
        title: "変更履歴",
        ownFields: {
          /* eslint-disable camelcase */
          title: "Proposal name",
          content: "内容",
          total_budget: "金額",
          foreign_currency_id: "外貨種別",
          foreign_currency_total_budget: "外貨金額",
          exchange_rate: "為替レート",
          memo: "メモ",
          is_valid: "有効",
          payment_request_supplier: "取引先",
          payment_request_payment_method_supplier_name: "取引先名",
          "": "",
          /* eslint-enable camelcase */
        },
        attachedFiles: {
          /* eslint-disable camelcase */
          attached_file: "画像",
          "": "",
          /* eslint-enable camelcase */
        },
        projects: {
          /* eslint-disable camelcase */
          project_id: "プロジェクト",
          "": "",
          /* eslint-enable camelcase */
        },
        debitInvoiceCategories: {
          /* eslint-disable camelcase */
          payment_request_debit_invoice_category_id: "借方請求明細科目",
          "": "",
          /* eslint-enable camelcase */
        },
        costAllocationDepartments: {
          /* eslint-disable camelcase */
          cost_allocation_department_id: "費用負担部署",
          "": "",
          /* eslint-enable camelcase */
        },
        supplier: {
          new: "(新規取引先)",
        },
      },
      nationalTaxDocument: {
        title: "変更履歴",
        ownFields: {
          /* eslint-disable camelcase */
          type: "書類種別",
          type_alias_id: "書類種別",
          transacted_at: "取引日",
          amount_include_tax: "取引金額",
          supplier_name: "取引先名",
          memo: "メモ",
          name: "書類タイトル",
          document_type: "",
          foreign_currency_id: "外貨",
          foreign_currency_amount_include_tax: "外貨税込金額",
          exchange_rate: "外貨レート",
          registrated_number: "Registrated Number",
          department: "Supprier Department",
          project_id: "Project",
          "": "",
          /* eslint-enable camelcase */
        },
        attachedFiles: {
          /* eslint-disable camelcase */
          attached_file: "Tax document data",
          "": "",
          /* eslint-enable camelcase */
        },
        documentTypes: {
          deleted: "(deleted)",
        },
        documentTypeAliases: {
          deleted: "(deleted)",
        },
        projects: {
          deleted: "(deleted)",
        },
      },
    },
    reportAlerts: {
      ruleList: "請求書アラートルール一覧",
      levels: {
        ignore: "オフ",
        warning: "Warning",
        cannotRequest: "Application not allowed",
        cannotApprove: "承認不可",
      },
    },
    request: {
      prepareRequest: "Input a request info",
      checkContents: "Confirm the request contents",
      title: "Title",
      department: "Department",
      amount: "Total",
      comment: "Comment",
      approvalFlow: "Approval flow",
      submit: "Submit",
      sendRequest: "Apply",
      created: "Applied",
      approve: "Approve",
      approved: "Approved",
      cancelApprove: "Revoke approval",
      cancelApproved: "Revoked",
      reject: "Reject",
      rejectTo: "Reject to",
      rejected: "Rejected",
      recall: "Withdraw",
      recalled: "Withdrawn",
      resubmit: "Reapplication",
      resubmitted: "Reapplied",
      changeApprovalFlow: "Reconfigure the flow and apply",
      changeApprovalFlowShort: "Reconfigure the flow",
      useCurrentApprovalFlow: "Use current approval flow",
      applyNewApprovalFlow: "Apply new approval flow",
      reapplyingModal:
        "Do you reapply the request with the current approval flow?",
      delete: {
        confirm: {
          title: "申請の削除",
          description: "申請を削除し、請求書を「未申請」の状態に戻します。",
        },
      },
      bulk: {
        requested: "Applied.",
        sequenceNumbersLabel: "Report ID：",
        sendRequests: "Apply",
        checkContents: "Application details（{{count}} invoices）",
        includeReports: "List of Invoices Included",
        editRequest: {
          name: "Name of application",
          approvalFlow: "Application Flow",
          totalAmount: "Total amount",
          priceWithCount: "{{price}}（{{count}} invoices）",
          checkIncludeReports: "Check the included invoices",
        },
        reapplying: {
          title: "承認フローの設定方法を選択",
          current: {
            label: "「自動」の場合",
            description:
              "過去に申請したことのある支払依頼が、前回使用した承認フローで再提出されます。\n新規の支払依頼の場合、承認フローを手動で確認・編集します。",
            action: "自動",
          },
          new: {
            label: "「手動」の場合",
            description: "すべての支払依頼の承認フローを確認・編集します。",
            action: "手動",
          },
        },
      },
      approverSelect: {
        title: "Add approver",
        description:
          "This application allows for additional approval steps.\nIf not added, the application will be final approval.",
        addStep: "Add the following approval steps",
        noStep: "No additional approval steps",
      },
      consolidated: {
        requested: "Applied for bulk.",
        sendRequests: "Apply for bulk payment request",
      },
    },
    preferences: {
      featureForRP:
        "こちらの機能はTOKIUM経費精算のみの機能です。TOKIUMインボイスには適用されませんのでご注意ください。",
      properties: {
        proposalContentFormat: "Proposal Content Template",
      },
      account: {
        documentDestination: "Email of document destination",
        mailAddress: "Mail address",
        description:
          "請求書等の書類を添付・送信することで、自動入力が行われます。\n尚、システムへの送信用となるため、お問い合わせ等の返信はできません。",
      },
      approval: {
        approvalSetting: "承認設定",
        bulkApprove: "一括承認を使用できるようにする",
        bulkApproveCaution:
          "一括承認は支払依頼申請のみの機能です。経費申請、事前申請、汎用申請、稟議申請には適用されませんのでご注意ください。",
        bulkApproveDesc: "承認者が複数の申請をまとめて承認できるようになります",
        bulkApproveChanged: "一括承認設定を更新しました",
      },
      distributionConditions: {
        title: "テナント全体の共通設定",
        description:
          "書類タイトルに登録した文字列を含む書類が届いた場合に請求書として振り分けを行います（登録数最大20件）\n取引先ごとの個別設定を設定している場合、そちらが優先されます。",
        changed: "請求書振分を更新しました",
      },
      distributionConditionsBySupplier: {
        title: "取引先ごとの個別設定",
        headers: {
          priority: "優先度",
          suppliers: "適用対象取引先",
          conditions: "請求書振り分け条件",
          status: "ステータス",
        },
        actions: {
          addCondition: "請求書振分条件を追加する",
          expandSuppliers: "全て表示({{count}}件)",
          collapseSuppliers: "閉じる",
          enable: "有効",
          disable: "無効",
          edit: "編集",
          replicate: "複製",
          destroy: "削除",
        },
        modal: {
          title: "振分条件詳細",
          labels: {
            suppliers: "適用対象取引先",
            conditions: "請求書振分条件",
            apply: "設定の適用（有効化）",
            priority: "優先度",
            memo: "メモ",
            enabled: "有効",
            disabled: "無効",
          },
          actions: {
            save: "保存",
            editSuppliers: "取引先リストを選択",
          },
        },
      },
      notifications: {
        invoice: "Notification for Invoice",
        onWorkerInputCompleted:
          "When an automatic input of invoice has been completed",
        onChangeReportSupplier: "When an invoice's supplier has been changed",
        onChangeClosingDate:
          "When there is an unapplied bill whose application deadline is near",
        onChangeClosingDateDescription:
          "The application deadline and notification date are set by the administrator",
        nationalTaxDocument: "Notification for Tax Document",
        onNationalTaxDocumentWorkerInputCompleted:
          "When an automatic input of tax-document has been completed",
      },
      reports: {
        titles: {
          proposalContentFormat: "Proposal Content Template",
        },
        descriptions: {
          proposalContentFormat:
            "You can set the default content when filling the proposal.",
        },
      },
      consumptionTaxInput: {
        title: "Sales tax input setting",
        descriptions: {
          useTaxExcluded:
            "Changes due to activation\n・Enables selection of whether to input invoice lines including or excluding tax.\n・The amount of consumption tax entered in the invoice lines is output to the accounting data.",
          useTaxExcludedAsDefault:
            "If enabled, will be tax excluding input by default when creating invoices.",
        },
        useTaxExcluded: "Allow selectable tax-inclusive/tax-excluded input",
        useTaxExcludedAsDefault: "Default is input excluding tax",
      },
      reportEditSetting: {
        title: "Supplier edit setting",
        labels: {
          enableSupplierEdit:
            "Allow editing of supplier information when editing invoices",
        },
      },
      eligibleInvoiceSetting: {
        title: "Eligible Invoice Preferences",
        labels: {
          useRegistratedNumberAutocompleteToSupplier:
            "Registrated number auto-completion to supplier",
        },
        descriptions: {
          useRegistratedNumberAutocompleteToSupplier:
            "Automatically complete the registrated number of the invoice to the supplier when applying or approved.\n* Only when the supplier's registrated number is blank.\n* If the payment request application is OFF, automatic completion will be performed at the timing of approving the invoice.",
        },
      },
      purchaseRequisitionNumber: {
        title: "Invoice items setting",
        enable: "Purchase order number",
        description:
          "Add a field for the purchase order number to the invoice lines.\nPurchase order number is an optional field.",
      },
      resolutionInformation: {
        report: {
          enable: "Invoice",
        },
        nationalTaxDocument: {
          enable: "Nationl Tax documents",
        },
      },
      alertRules: {
        reportDifferenceSetting: "請求書記載情報差分アラート設定",
        supplierDifferenceSetting: "取引先マスタ差分アラート設定",
        keys: {
          supplierName: "取引先名",
          dueAt: "支払期日",
          paymentMethodType: "支払方法",
          bankName: "金融機関名",
          bankBranchName: "支店名",
          bankAccountType: "口座種別",
          bankAccountHolderKana: "名義人",
          bankAccountNumber: "口座番号",
          payFee: "手数料負担先",
        },
        actions: {
          enable: "アラートを出す",
          disable: "無視する",
        },
      },
    },
    analysisDetail: {
      aggregationDetail: {
        title: "Aggregation summary",
        name: "Name",
        creationDate: "Creation Date",
        amount: "Amount",
        totalTransferAmount: "Total Transfer Amount",
        totalAmountIncludingWithholding: "Total Amount",
        totalWithholding: "Total Withholding",
        numberOfInvoices: "Number of Invoices",
      },
      buttons: {
        exportZenginData: "Zengin and accounting data export",
        handleAsPaid: "Handle as paid",
        paid: "Paid",
        updateToExported: "Update to exported",
        exported: "Exported",
      },
      messages: {
        confirmProceed: "Are you sure you want to proceed it?",
        unableToProceedUnapproved:
          "Unable to proceed as paid because its aggregation includes unapproved invoices",
      },
      aggregationPerPayee: {
        titles: {
          transfer: "Aggregation per payee(payment)",
          transferHelp:
            "If there is a payee with a transfer amount of less than 0 yen, the Zengin data output will fail. Please check the relevant payment request application.",
          other: "Aggregation per payee(other)",
        },
        tableHeaders: {
          bankName: "Bank Name",
          branchName: "Branch Name",
          bankAccountNumber: "Bank Account Number",
          accountHolder: "Account Holder",
          accountType: "Account Type",
        },
        memberTable: {
          account: "Account",
        },
      },
    },
    exports: {
      modalTitle: "File export",
      startCreatingLatestAccountingData:
        "Start creating the latest accounting data",
      zengin: {
        tabTitle: "Zengin data export",
        paymentAccount: "Payment account",
        paymentDate: "Payment date",
        placeHolder: "Please enter the payment date",
        transferFeeHelp:
          "※The amount of money to be transferred will be automatically calculated by referring to the payee (※1) selected on this screen and the payee (※2) set in the invoice.",
        payFeeHelp:
          "Select the bank settings for the transfer. The amount of money to be transferred will be automatically calculated according to the fee-paying party for each invoice.",
        table: {
          calculatedAnswer: "calculated amount",
          noChange: "no change",
          amountPlusFee: "amount + transfer fee",
          amountMinusFee: "amount - transfer fee",
        },
      },
      formatted: {
        tabTitle: "Accounting data export",
        exportFormat: "Export format",
        specifiedDate: "Specified transaction date",
      },
      messages: {
        exportSize: "You can export at most {{ size }} reports at once.",
      },
    },
    invoiceDetail: {
      title: "Invoice (Detail)",
      comment: "Comment",
      paymentAmount: "Payment amount",
      totalAmount: "Total amount",
      close: "Close",
      percentAmount: "{{number}}% Amount",
      amountOfWithholdings: "Amount of withholdings",
      paymentSettings: "Payment settings",
      displayLargely: "Change History",
      workerInputCorrectionRequest: {
        short: "Report incorrect input",
        long: "Request correction of automatic input",
      },
      approve: "Approve",
      unapprove: "Revert to Unapproved",
      noImageAttached: "No image attached",
      diffWorkerInputAmount:
        "Total amount is different from the amount automatically filled in",
      uploadSizeAnnotationTitle: "Upload the file.",
      uploadSizeAnnotationDetail: "* Up to 10 files.",
      detailAmount: "breakdown",
      summaryAmount: "summary",
      overview: {
        paymentInformation: "Payment Information",
        supplementaryInformation: "Supplementary Information",
        asInvoiceSetting: "Qualified invoice setting",
      },
      info: {
        supplier: "Supplier：",
        supplierInCharge: "Supplier in charge：",
        status: "Status：",
        sequenceNumber: "Report ID：",
        registeredAt: "Registered date：",
        sendFrom: "Sending Information：",
      },
      sendInfoPopup: {
        sendFromInfo: "Sending Information",
        sendMeans: "Sending Method",
        sendFrom: "Sender",
      },
      supplierInChargePopup: {
        title: "Supplier in charge",
        userInCharge: "Accessible Users",
        groupInCharge: "Accessible Departments",
        noInCharge: "None",
      },
      amountDiffAlert: {
        alert:
          "Total amount is different from the amount automatically filled in",
        workerInputAmount: "the amount automatically filled in＝{{ value }}",
      },
      resolution: {
        label: "Scanning resolution",
        notSatisfied:
          "Even if the scan resolution is NG, it does not matter if it is an electronic transaction*.\n*Transactions exchanged via e-mail or the Internet",
        imageAcquisitionFailure: "Image information could not be retrieved.",
      },
      timeStamps: {
        label: "Time stamp information",
        unsupportedTimestamp:
          "Files except images(jpg, png, pdf) will not be timestamped",
        failed: {
          headline: "Failed to add timestamp.",
          exampleTitle: "Examples of applicable cases",
          example1: "Attachments are password-protected.",
          example2: "Attached PDF file is signed.",
          example3: "Attachments are huge in size (e.g., hundreds of MB)",
          link1: "*For more information",
          link2: "please refer.",
        },
        inprogress: {
          desciption1: "Time stamping is in progress.",
          desciption2: "Please wait for a while.",
        },
      },
      timeline: {
        progress: "Approval status",
        latestComment: "Latest comment",
        otherApprovers: " {{count}} others",
        otherApproversIncludedDepartments: " {{count}} others",
        seeAll: "See all",
        unapproved: "Unapproved",
        conditionAll: "Everyone",
        conditionAny: "Anyone",
        enterComment: "Enter a comment",
        separator: ", ",
        userName: "({{userName}})",
        userNameWithAgentName: "({{agentName}}(On behalf of {{userName}}))",
        applicantNameWithAgentName: "{{agentName}}(On behalf of {{userName}})",
      },
      alert: {
        hasCheckItems: "Check items available",
        item: "item",
        duplicateAlert:
          'A supplier with the same details already exists in the supplier master. When updating, the supplier will be registered twice.\nTo avoid this problem, please click "Select" from the item "Supplier", and set the supplier from the list (Supplier Master).',
        helps: {
          help1:
            'Alerts corresponding to the set check level can be checked on the "System Settings" > "Alert Rules" screen.',
          help2:
            'Select the "Confirm" button next to the check alert to update the difference information, etc. and resolve the alert.',
          help3:
            '※If the "Confirm" button does not exist, please follow the instructions.',
        },
        error: {
          title:
            'There is an invoice alert with check level "Application not allowed".',
          description1:
            "We cannot move on to the next step if this is not done.",
          description2: "Please check the contents before responding.",
        },
        warning: {
          title: 'There is an invoice alert with check level "Warning".',
          description1: "You can proceed to the next step as is,",
          description2: "but please check the contents before taking action.",
        },
        blockedSupplier: {
          title: "Tied to a blocked supplier.",
          message: "Please select an unblocked supplier or contact management.",
        },
        differentWorkerInvoiceInput: {
          title:
            'There is a difference between "the contents of the invoice as entered" and "the contents of the invoice as converted to data".',
          message: "You can update the difference on the confirmation screen.",
        },
        unattachedToOfficialSupplier: {
          title: "Unregistered supplier is set.",
          message:
            "Unregistered suppliers can be registered on the confirmation screen.",
          successMessage: "Supplier master has been registered.",
        },
        attachedToUnofficialPaymentMethod: {
          title:
            'There is a difference between the "Details of the entered supplier" and the "Details of the already added supplier".',
          message: "You can update the difference on the confirmation screen.",
          successMessage: "Supplier master has been updated.",
        },
        workerInvoiceInputPreviewModal: {
          title: "Confirmation (invoice statement)",
          description:
            'You can update from "the contents of the invoice as entered" to "the contents of the invoice as converted to data".',
          beforeChange: "the contents of the invoice as entered",
          afterChange: "the contents of the invoice as converted to data",
        },
        unofficialPaymentMethodPreviewModal: {
          title: "Confirmation (registration details of supplier)",
          description:
            'You can update the "Details of the already added supplier" to the "Details of the entered supplier".',
          beforeChange: "Details of the already added supplier",
          afterChange: "Details of the entered supplier",
        },
        unattachedToOfficialSupplierPreviewModal: {
          title: "Confirmation (supplier)",
          description: "Register the supplier with the invoice entry details.",
          afterChange: "Invoice entry details",
        },
      },
      actions: {
        update: "Update",
        register: "Register",
      },
    },
    sendFrom: {
      summary: {
        system: "System",
        tokium: "TOKIUM",
      },
      email: "Invoices received by e-mail.",
      emailFromTokium: "TOKIUM uploaded.",
      manual: "This is a manually created invoice.",
      post: "Invoice received by mail.",
      agency: "TOKIUM uploaded.",
      uploader: "Invoices received through the invoice uploader.",
      periodic: "This is an invoice created from periodic payment.",
      dedicated: "Invoices were converted from OCR to data.",
      dedicatedOperator:
        "National tax documents were converted data by operator input.",
      api: "Created by API.",
    },
    supplierSelector: {
      actions: {
        searchPlaceholder: "supplier name or code (multiple words allowed)",
      },
      titles: {
        selectSupplier: "Select supplier",
      },
      labels: {
        otherPaymentMethod: "Other payment methods",
      },
    },
    supplierExport: {
      modalTitle: "Suppliers export",
      goToImportModal: "Go to the supplier import screen",
      messages: {
        toFixContent:
          "To fix the already configured contents, please export the latest configured content and fix the exported file, and then please import the fixed file",
      },
    },
    supplierImport: {
      modalTitle: "Suppliers import",
      downloadCurrentSettingsContent: "Export the current configured contents",
      messages: {
        importSuppliers: "Import suppliers from an xlsx file.",
        toDownloadSampleHere: "To export an example of the file here.",
        toFixContent:
          "To fix the already configured contents, please export the latest configured content and fix the exported file, and then please import the fixed file",
        useLatestDownloadFileWhenUpdate:
          "It is better to use the latest example file when updating the suppliers.",
        removeNoteColumnBeforeToImport:
          "{{ column }}-column on the file describes some notes for importing. Please remove the column before use.",
      },
    },
    suppliers: {
      edit: "Edit",
      phoneNumber: "Phone Numbers",
      phoneNumberHelp:
        "When creating invoice data, if the unique 'Code for registration' for each business partner cannot be confirmed, we will link the invoice and business partner based on the phone number and account information (Bank, Account Type, Account Number).",
      registratedNumberTitleHelp:
        "税務署から適格請求書発行事業者に通知された登録番号を指します。登録番号は「T+半角数字13桁」です。",
      registratedNumberInputAlert: "Please enter 13 digits.",
      accessibleUserSetting: "Accessible Users",
      accessibleDepartmentSetting: "Accessible Departments",
      invoiceUploaderAddressee: "Invoice address",
      invoiceUploaderAddresseeHelp:
        "お取引先様が請求書送付時に利用する取引先ごとの名前です。\n郵送時・請求書のアップロードサイトでお取引先様が目にする名前ですので、重複・社内情報の流出にご注意ください。\n同一取引先と複数の部署が取引を行っている場合、部署名(ex: 横浜営業部)を入力することで、取引先が選択しやすくなります。",
      invoiceUploaderAddresseeNeeded: "Invoice address : Not entered",
      invoiceUploaderCodeHelp:
        "'Code for registration' is information that we assign to business partner, and the business partner assigns this code to send an invoice to us.",
      diffOfficial:
        "There is a difference between the information registered by the supplier and the information entered below.",
      noOfficial: "Unregistered supplier is set.",
      blocked: "Tied to a blocked supplier.",
      beforeChangeHelp: "取引先マスタに登録された値です",
      afterChangeHelp: "現在の入力値です",
      officialized: "取引先マスタを登録/更新しました",
      duplicateSupplierPossibility:
        "同一内容の取引先が既に取引先マスタに存在している可能性があります",
      applicableTemplate: "Applying:",
      detailDescription:
        "This page can't edit this supplier. If you would like to edit, access the supplier edit page by administrators.",
      titles: {
        autoCreation: "Autofill Setting",
        registratedNumber: "Registrated Number",
        phoneNumber: "Phone Numbers",
        userInCharge: "Accessible Users",
        groupInCharge: "Accessible Departments",
        other: "その他",
        reportTemplate: "Invoice template",
        invoiceMailerModal: {
          requestForUpdateAndConfirmSendingMethod: "取引先への送付先変更連絡",
          editMail: "送付メール",
        },
      },
      labels: {
        supplierCode: "Supplier Code",
        supplierName: "Supplier Name",
        paymentMethod: "支払方法",
        phoneNumber: "Phone Numbers",
        userInCharge: "Accessible Users",
        groupInCharge: "Accessible Departments",
        receivingReportStatus: "Last Receipt Date",
        confirmReceivingReport: "Last Receipt Check",
        receiving: "Receipt",
        notReceiving: "Not receipt",
        blockedStatus: "Block Status",
        blocked: "Blocked",
        notBlocked: "Not blocked",
        invoiceUploaderCode: "Code for registration",
      },
      tables: {
        columns: {
          supplierCode: "Supplier Code",
          supplierName: "Supplier Name",
          paymentMethod: "支払方法",
          userInCharge: "Accessible Users",
          groupInCharge: "Accessible Departments",
          phoneNumber: "Phone Numbers",
          latestReportReceivingDate: "Last Receipt Date",
        },
      },
      buttons: {
        block: "凍結する",
        cancellBlocked: "凍結解除",
      },
      descriptions: {
        confirmReceivingReport:
          "本項目をONに設定した取引先は、「請求書受領状態」を条件に検索可能になります",
        receivingReportSearch:
          "「請求書受領確認」設定有効の取引先から、以下の条件に合うものを抽出できます",
        paymentMethodsPayFeeSelect:
          "「取引先設定の内容でデータ化」が有効でも「未指定」が選択されている場合は、請求書の記載内容が優先されます。",
      },
      actions: {
        block: "Block",
        unblock: "Unblock",
        openEmailEditorModal: "Send Request For Update",
        selectSupplier: "Select Supplier",
        searchPlaceholder: "Supplier name",
        reportTemplates: {
          searchPlaceholder: "Invoice template name",
        },
      },
      propertyGroups: {
        sendingInformation: "Invoice Sending Information",
      },
      properties: {
        supplierSetting: "Supplier Setting",
        supplier: "Supplier",
        code: "Supplier code",
        name: "Supplier name",
        paymentMethod: "Payment method",
        registratedNumber: "Registrated number",
        phoneNumbers: "Phone numbers",
        accessibleUsers: "Accessible users",
        accessibleDepartments: "Accessible departments",
        invoiceUploaderCode: "Code for registration",
        email: "Email",
        uploaderUrl: "URL for registration",
        sendingEmail: "Email for send invoice",
        sendingAddress: "Physical address for send invoice",
        latestReportReceivingDate: "Last Receipt Date",
        reportTemplateName: "Invoice template name",
        reportTemplate: "Invoice template",
      },
      errors: {
        registratedNumber: {
          updateFailed: "登録番号の更新時にエラーが発生しました",
        },
      },
    },
    supplierInvoiceMailer: {
      properties: {
        email: "Email",
        subject: "Subject",
        body: "Body",
      },
      messages: {
        youShouldCopy:
          "Please copy and paste this email and send it from your regular email system.",
      },
      actions: {
        switchToPreview: "To Preview",
        switchToEditor: "To Editor",
      },
      labels: {
        preview: "Preview",
      },
    },
    timestamp: {
      properties: {
        inprogress: "Timestamp inprogress",
        failed: "Timestamp failed",
      },
    },
    workerMailRejection: {
      titles: {
        suppliers: "取引先を選択",
        statusOfInCharge: "取引先の担当者",
      },
      labels: {
        userInCharge: "自分",
        groupInCharge: "自分の所属部署",
        notInCharge: "自分以外",
        noCharge: "未設定",
      },
      buttons: {
        setForEachSupplier: "取引先ごとに設定する",
      },
      messages: {
        saved: "設定を保存しました",
      },
    },
    bankAccount: {
      types: {
        ordinal: "Ordinal",
        current: "Current",
        saving: "Saving",
        other: "Other",
      },
    },
    invoiceCategory: {
      title: "科目名",
      setting: "請求明細科目",
      properties: {
        displayName: "科目名称",
        superCategory: "勘定科目",
        parentCategory: "親科目",
        subCategory: "補助科目",
        requiresCompanion: "参加者入力",
        requiresWithholding: "源泉徴収対応",
        required: "必須",
        noNeed: "不要",
        sort: "並び順",
        taxCategory: "税区分",
        enable: "する",
        disable: "しない",
        memoTemplate: "メモ欄テンプレート",
        isDefault: "既定の科目",
      },
      actions: {
        categoryAsParent: "親科目として設定する",
      },
      descriptions: {
        categoryAsParent:
          "一度登録すると、子科目から親科目、親科目から子科目への変更はできません。",
        enable: "「利用する」となっている子科目がないため、編集できません。",
      },
      exports: {
        goToImportModal: "インポート画面へ移動",
        messages: {
          toFixContent:
            "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
        },
      },
    },
    debitInvoiceCategory: {
      title: "借方",
      placeholder: "検索する借方請求明細科目名",
      setting: "借方請求明細科目設定",
      settingWithExpense: "経費/借方請求明細科目設定",
      properties: {
        superCategory: "勘定科目",
        parentCategory: "親科目",
        subCategory: "補助科目",
        requiresCompanion: "参加者入力",
        requiresQuantityAndUnitPrice: "数量・単価入力",
        required: "必須",
        noNeed: "不要",
        sort: "並び順",
        taxCategory: "税区分",
        enable: "する",
        disable: "しない",
        memoTemplate: "メモ欄テンプレート",
      },
      actions: {
        add: "借方請求明細科目を追加",
        includeDisabled: "Show disabled debit invoice categories",
        categoryAsParent: "親科目として設定する",
        edit: "借方請求明細科目編集",
        select: "Select invoice category",
        searchPlaceholder: "Debit invoice category name",
      },
      descriptions: {
        categoryAsParent:
          "一度登録すると、子科目から親科目、親科目から子科目への変更はできません。",
        enable: "「利用する」となっている子科目がないため、編集できません。",
      },
      imports: {
        title: "借方請求明細科目インポート",
        desciption: "借方請求明細科目をインポートします。",
        sample: "サンプルのダウンロードはこちら",
        guide: "サンプルデータ「項目説明」シートに補足事項の記載があります。",
        goToExportModal: "現在の設定内容をエクスポート",
        toFixContent:
          "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
      },
      exports: {
        title: "借方請求明細科目エクスポート",
        goToImportModal: "インポート画面へ移動",
        messages: {
          toFixContent:
            "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
        },
      },
    },
    creditInvoiceCategory: {
      title: "貸方",
      placeholder: "検索する貸方請求明細科目名",
      setting: "貸方請求明細科目設定",
      settingWithExpense: "経費/貸方請求明細科目設定",
      properties: {
        superCategory: "勘定科目",
        parentCategory: "親科目",
        subCategory: "補助科目",
        requiresCompanion: "参加者入力",
        required: "必須",
        noNeed: "不要",
        sort: "並び順",
        taxCategory: "税区分",
        enable: "する",
        disable: "しない",
        memoTemplate: "メモ欄テンプレート",
      },
      actions: {
        add: "貸方請求明細科目を追加",
        includeDisabled: "利用しない貸方請求明細科目も表示する",
        excludeDisabled: "利用しない貸方請求明細科目を非表示にする",
        categoryAsParent: "親科目として設定する",
        edit: "貸方請求明細科目編集",
        select: "貸方請求明細科目を選択",
        searchPlaceholder: "検索する貸方請求明細科目名",
      },
      descriptions: {
        categoryAsParent:
          "一度登録すると、子科目から親科目、親科目から子科目への変更はできません。",
        enable: "「利用する」となっている子科目がないため、編集できません。",
      },
      imports: {
        title: "貸方請求明細科目インポート",
        desciption: "貸方請求明細科目をインポートします。",
        sample: "サンプルのダウンロードはこちら",
        guide:
          "サンプルデータのK列に補足事項の記載があります。インポート時にはK列を削除してください。",
        goToExportModal: "現在の設定内容をエクスポート",
        toFixContent:
          "設定済みの内容を修正する際には、最新の設定内容をエクスポートし、エクスポートファイルを修正の上インポートしてください。",
      },
      exports: {
        title: "貸方請求明細科目エクスポート",
      },
    },
    taxCategoryOption: {
      title: "税区分選択",
      properties: {
        taxCategoryName: "税区分名",
        taxRate: "税率",
        term: "有効期間",
        sort: "並び順",
      },
    },
    reportItem: {
      continuous: "Reuse input information",
      createdCount: "lines",
      created: "created",
      hasAlert: "Line is incomplete",
      hasAlertFollowingItems: "Incomplete line",
      notMatchProposal:
        "Debit invoice line items not included in the proposals, or cost allocated department has been established.",
      notMatchProposalDebitInvoiceCategoryOrCostAllocationDepartment:
        "Debit invoice line items not included in the proposals, or cost allocated department has been established.",
      overBudgetOfProposal:
        "The total amount of invoice lines associated with the purchase order proposal application exceeds the amount of the application.",
      createCopied: "コピーを作成",
      createFromPast: "Create from history",
      pastList: "List of past lines：{{supplierName}}",
      inputFromPast: "Overwrite by history",
      selectItemYouWantToInput: "Please select the line you wish to enter.",
      createFromImport: "Import",
      otherActions: "その他の操作",
      taxCategoryUnmatchForAsInvoice:
        "It is determined that the invoice is qualified invoice, but a tax category for not qualified is selected.",
      taxCategoryUnmatchForNotAsInvoice:
        "It is determined that the invoice is not qualified invoice, but the tax category for qualified has been selected.",
      descriptions: {
        createFromPastData:
          "You can create a list with the contents of previous list for the same supplier.",
        overwriteToFromPastData:
          "You can overwrite a list with the contents of a previous list for the same supplier.",
        latestInSimilarPastData:
          "Only the latest one similar previous list is displayed.",
        doNotInputSupplier: "No supplier has been entered.",
        alertForConsumptionTax:
          "Due to rounding off fractions, some amounts may have an error of 1 yen in the tax-excluded changeover. In that case, please correct the amount.",
        notQuotingParticipants:
          "No participant is cited because the debit invoice category has been changed to not used or the participant has been changed to not required.",
        notQuotingCostAllocations:
          "Cost allocated departments are not cited because they are prorated by amount.",
        notQuotingCostAllocationsBecauseExistsDeletedPayer:
          "Cost allocated departments are not cited because they contain deleted departments.",
        notQuotingProject:
          "The project is not quoted because the project function is set to not be used.",
      },
      messages: {
        mustInputAppropriatedAtWhenUseForeignCurrency:
          "To enter foreign currency, the accounting date must be entered.",
        importFinished: "Line creation on import is complete.",
        noData: "Invoice Not Found.",
      },
      labels: {
        valueTypes: {
          inputAsPercentage: "Input as percentage",
          inputAsAmount: "Input as amount",
        },
        amounts: {
          automaticFormula:
            "[Automatic calculation result] Unit price x Quantity=",
        },
        unitPrice: {
          automaticFormula:
            "[Automatic calculation result] Amount without tax ÷ Quantity=",
        },
      },
      genericFields: {
        values: {
          deletedDataSet: "Deleted master",
        },
        itemsSelector: {
          title: "Select from generic fields {{name}}",
          searchPlaceholder: "Multiple keywords can be specified",
        },
      },
      actions: {
        add: "Add report line",
        addManually: "Add Manually",
        addFromImport: "Add from import",
        edit: "Edit report line",
      },
    },
    reportItemImport: {
      modalTitle: "Invoice line import",
      messages: {
        importReportItems: "Import invoice lines.",
        toDownloadSampleHere: "Download sample",
        toTaxIncludedDownloadSampleHere: "Download tax-included input sample",
        toTaxExcludedDownloadSampleHere: "Download tax-excluded input sample",
        bulkCreateFromImport: "Create multiple lines at once.",
        cantOverWriteAndDeleteAtAlreadyRegistered:
          "Already registered lines cannot be overwritten or deleted.",
        removeNoteColumnBeforeToImport:
          'Supplementary information is provided in column AA of the sample data "請求明細情報" sheet. Remove the AA column when importing.',
        removeNoteColumnBeforeToImportForGenericFields:
          'Supplementary information is provided in column C of the sample data "汎用マスタマッピング" sheet. Remove the C column when importing.',
        notChangeSheetNameInFormat:
          'Do not change the names of the "請求明細情報" sheet and "汎用マスタマッピング" sheet.',
        removeExceptReportItemInfoSheet:
          'If it takes a long time to import, it may be improved by deleting sheets other than "請求明細情報" sheet and "汎用マスタマッピング" sheet',
        genericFieldLicenseMessage:
          'If you do not have a General-Fields contract, you do not need to enter values in columns of "汎用マスタ1〜5" on the "請求明細情報" sheet and the "汎用マスタマッピング" sheet.',
        changeTaxCategoryDependingOnAsInvoice:
          'Please change the tax categories to be entered according to the check of "Qualified Invoice".',
      },
    },
    superCategory: {
      properties: {
        defaultCreditCategory: "既定の貸方勘定科目(請求書明細用)",
      },
    },
    currencyPreference: {
      errors: {
        noAppropriatedAt: "計上日が設定されていません",
        earlyOrExpired: "外貨の適用期間外です",
      },
    },
    metadata: {
      imports: {
        guideWithHierarchyWithKeihi:
          "部署名・経費科目名・借方請求明細科目名に「/」が含まれる場合はエクスポート時に「¥/」と変換されますが、そのままインポートしてください。",
        guideWithHierarchy:
          "部署名・借方請求明細科目名に「/」が含まれる場合はエクスポート時に「¥/」と変換されますが、そのままインポートしてください。",
      },
    },
    agentSettings: {
      agentTable: {
        reportRequest: "支払依頼申請",
        proposalRequest: "稟議",
        canMakeAndSubmit: "作成・申請",
        canApprove: "承認",
      },
      agentAuthorities: {
        canApprove: "経費承認",
        canMakeReportRequestAndSubmit: "支払依頼申請作成・申請",
        canApproveReportRequest: "支払依頼申請承認",
        canMakeProposalRequestAndSubmit: "稟議作成・申請",
        canApproveProposalRequest: "稟議承認",
      },
    },
    approveJobs: {
      bulkApproveSelectedPaymentReportRequests:
        "選択した支払依頼申請を一括承認する",
      bulkApproveSelectedPreReports: "Bulk approve selected pre requests",
      bulkApproveSelectedReports: "Bulk approve selected requests",
      bulkApproveSelectedApplications: "Bulk approve selected {{tabName}}",
      processing: "実行中",
      error: "一括承認エラー",
      message: {
        bulkCreate: "一括承認の実行を開始しました",
      },
    },
    reportRequestJobs: {
      processing: "In process",
      error: "Application error",
    },
    waitingWorkerDocumentInputs: {
      detail: "Deta Entry detail",
      createdAt: "Registered date",
      supplierCode: "Supplier code",
      supplierName: "Supplier name",
      toReport: "Manual entry as invoice",
      toNationalTaxDocument: "Manual entry as tax document",
      confirmTitleToReport: "Manual entry as invoice",
      confirmTitleToNtd: "Manual entry as tax document",
      confirmBody:
        "実行すると自動入力中書類に戻すことはできません。よろしいですか？",
      indexCount: "Results",
      toManualNow: "Manual input without waiting for automatic input",
      movedFromNtd: "Move a file to Invoice menu",
      copyUrl: "Copy URL",
      messages: {
        noData: "No Data",
      },
    },
    accountingDataScheduledExports: {
      title: "インボイス",
      common: {
        exclusion: "以外",
      },
      tasks: {
        forms: {
          export: {
            payerAccount: {
              label: "支払口座",
            },
          },
        },
        analysisAggregationConditions: {
          noData: "集計条件なし",
          details: "集計条件詳細",
          costAllocationDepartment: "費用負担部署",
          departmentInCharge: "担当部署",
          userDepartment: "Belonging Department",
          bank: "支払口座",
          projectId: "プロジェクトID",
          projectName: "プロジェクト名",
          supplierName: "取引先名",
          supplierCode: "取引先コード",
          includeChildDepartment: "下位部署を含む",
          includeNotAggregated: "未集計",
          includeAggregated: "集計済み",
          includeNotExported: "会計データ未出力",
          includeExported: "会計データ出力済み",
          includeNotPaid: "未払い",
          includePaid: "支払処理済み",
          includeOurPayFee: "当方",
          includeTheirPayFee: "先方",
          duePeriod: "支払期日",
          schedulePeriod: "支払希望日",
          appropriatedPeriod: "計上日",
          lastApprovedPeriod: "最終承認日",
          registeredPeriod: "Registered date",
          reportLabel: "請求書ラベル",
          purchaseOrderNumber: "発注書番号",
          consolidatedReportSequenceNumber: "一括支払依頼申請ID",
          paymentMethods: "支払方法",
          registratedNumber: "Registrated Number",
          includeAsInvoice: "あり",
          includeNotAsInvoice: "なし",
          memo: "Note",
          period: {
            current: "当月",
            previous: "前月",
            rangeAndDate: "{{range}} {{from}}日~{{to}}日",
          },
          includeLabel: {
            departmentSearchRange: "部署検索範囲",
            aggregationStatus: "集計状況",
            exportationStatus: "会計データ出力状況",
            paymentStatus: "支払い状況",
            payFee: "Transfer fee",
            asInvoice: "Qualified Invoice",
          },
        },
        readOnlyForm: {
          bankDataCreation: {
            feePaymentDestination: "Fee payment destination",
            our: "Our",
            their: "Their",
          },
        },
      },
      noLicense: {
        messageForIP:
          "The accounting data scheduled exports function (for TOKIUMインボイス) is a paid option. Please contact our support team to use this function.",
        summaryForIP: `You can automate following operations with assigned period span, which you are now doing manually at [Invoice Post > Aggregation]

          ・create aggergation
          ・export accounting data
          ・export bank data

          furthermore, you can transfer accounting data files to designated FTP server of your company.
        `,
      },
    },
    consolidatedReports: {
      properties: {
        sequenceNumber: "申請ID",
        totalAmountIncludingWithholding: "総額",
        totalAmountExcludeWithholding: "総額\n(各源泉徴収税額を除く)",
        totalWithholding: "(源泉徴収税総額)",
        requester: "申請者名",
        requestStatus: "申請状況",
      },
      views: {
        includingReportsCard: {
          cardTitle: "請求書一覧",
        },
      },
      messages: {
        requestedAt: "{{requestedAt}}に申請",
      },
    },
    nationalTaxDocumentSettings: {
      labels: {
        title: "国税関係書類ラベル一覧",
        description: "登録数最大20件",
        table: {
          headers: {
            label: "ラベル",
          },
        },
        modal: {
          title: {
            createLabel: "国税関係書類ラベルを追加",
            editLabel: "国税関係書類ラベルを編集",
          },
        },
      },
    },
  },
  td: {
    fileForm: {
      timestamp: {
        alert: "This file format cannot add timestamp",
      },
    },
  },
  managements: {
    invoicingOrganizations: {
      properties: {
        name: "Registrated Business Name",
        registrationDate: "Registration Date",
        verifyStatus: "Registration Status",
        address: "所在地",
        updateDate: "Update Date",
      },
      verifyStatuses: {
        OK: "Valid",
        NG: "Invalid",
      },
      processDetail: {
        expire: "Lapse of registration",
        disposal: "Cancel Registration",
        deleted: "Deletion of registration",
        unregistered: "Unregistered",
      },
      description: {
        automatedProcessVerifyStatus:
          "入力した登録番号と、国税庁の適格請求書発行事業所公表サイトを自動照合した結果を表示します。\n最新の情報が反映されるまで1日程度の遅れが発生する場合があります。",
      },
      title: {
        organizationInformation: "Organization information",
      },
    },
    errors: {
      invoicingOrganization: {
        getFailed: "適格請求書発行事業者情報の取得時にエラーが発生しました",
        unregistered:
          "登録番号に該当する適格請求書発行事業者が登録されていません",
      },
    },
  },
  navigations: {
    titles: {
      helpCenter: "Help Center",
    },
  },
  activityLogs: {
    title: "Log",
    serchSetting: "Specify search conditions",
    downloadModal: {
      title: "Export logs",
      description1: "The maximum number of data that can be output is 100,000.",
      create: "Create CSV file.",
    },
    downloadButton: {
      button: "Export",
    },
  },
  logins: {
    errors: {
      password: {
        blank: "Please input password.",
        tooShort: "Please input more than {{minPasswordLength}} characters.",
        notMatch: "The password confirmation does not match.",
      },
    },
    pageTitles: {
      resetPassword: "Reset Password",
    },
    pageDescriptions: {
      resetPassword: "Please input new password",
      passwordCondition:
        "（{{minPasswordLength}} characters or more and {{maxPasswordLength}} or less）",
      passwordConditionOfCharTypes:
        "（Must contain at least 3 of the following: lowercase , uppercase , numbers, and symbols）",
    },
    placeholders: {
      password: "password",
      passwordConfirmation: "password (confirmation)",
    },
    buttons: {
      resetPassword: "Reset Password",
    },
    pageLinks: {
      backToSignIn: "Back to sign in page",
      reSendInvitation: "Resend invitation email",
    },
    forcePasswordChangePages: {
      message:
        "Password conditions have changed. Please change to the new password.",
      condition: "Password condition",
      length: "At least 8 characters",
      chars:
        "Contain at least three of the following: lowercase letters, uppercase letters, numbers, and symbols.",
      usableSymbols: "Symbols",
      symbols:
        "@ # $ % ^ & * - _ ! + = [ ] { } | \\ : ' , . ? / ` ~ \" ( ) ; < >",
      newPassword: "New password",
      newPasswordConfirm: "New password (confirmation)",
      changePassword: "Change password",
      errors: {
        format: "Password does not meet the requirements.",
        notMatch: "The password confirmation does not match.",
      },
    },
  },
  eDocOption2016: {
    title: "Electronic bookkeeping option ※2016 revision version",
  },
  eDocOption2022: {
    title: "Electronic bookkeeping option ※2022 revision version",
    timeStampExpirationDate: "Timestamp expiration date: {{day}} days",
    startedAt: "Started At",
    periodOfFlowCycleWithRange: "Business cycle period (range 0-60)",
    placeholder: {
      dateInput: "Y/m/d",
    },
  },
  systemSettings: {
    systemSettings: "System settings",
    menus: {
      paymentRequests: {
        inputFormats: "Input Formats",
        distributionConditions: "書類振分",
        invoiceSettings: "Invoice settings",
        nationalTaxDocumentSettings: "Tax Document Settings",
      },
      members: "Members",
      posts: "Posts",
      groups: "Departments",
      companions: "Participants",
      expenseAccounts: "Expense Accounts",
      requestTypes: "Request Forms",
      approvalFlows: "Approval Flows",
      projects: "Projects",
      currencies: "Currencies",
      export: "Journal Entries Export Configuration",
      rootCategories: "Categories",
      taxCategories: "Tax Categories",
      businessCategories: "Automatic Input Categories",
      reports: "Reports",
      allowances: "Allowances",
      alertRules: "Alert Rules",
      icCardOptions: "IC Card",
      metadata: "Metadata",
      closingDates: "Closing Date",
      analysesConfig: "Journal Entries Export",
      eDocOptions: "Time Stamp",
      listOptions: "Report List Columns",
      corporateCards: "Corporate Cards",
      journalEntries: "Journal Entries",
      accountingDataScheduledExports: "Accounting Data Scheduled Exports",
      ipRestriction: "Security Settings",
      btob: "BtoB",
      activityLogs: "Logs",
      payment: "Payment",
      externalTables: "Company Code",
      genericFields: "Generic Fields",
      referenceFilters: "Reference Filters",
      bulkImageDownloads: "Bulk Download",
    },
  },
  importStatus: {
    started: "Waiting for import...",
    importing: "Importing data...",
    uploading: "Uploading import results...",
    failure: "Import failed",
    success: "Import completed successfully",
    error: "An error has occurred",
  },
  ReferenceFilters: {
    resourceType: {
      all: "All",
      nationalTaxDocument: "Tax Document",
    },
    headers: {
      enabled: "enabled",
      name: "Rule Name",
      resourceType: "Data Type",
      description: "Description",
    },
    Ntd: {
      resourceRuleExplanation: {
        all: "All Tax Document",
        // eslint-disable-next-line camelcase
        under_my_department: "Tax Document under your department",
        // eslint-disable-next-line camelcase
        under_my_department_and_created_by_myself:
          "Tax Document under your department and created by yourself",
        helpBalloon:
          "You will be able to view Tax Document of your department that you have created.",
        isAppliedToSubsidiaries: {
          true: "(Including subordinate departments)",
          false: "(Not including subordinate departments)",
        },
      },
    },
    member: {
      title: "Who",
      description: "The logged-in employee is either",
      addMember: "Add employee",
      assigneTrigger: "Unassigned only",
      assigneTriggerDescription:
        "Filters to only employees with no scope assigned.",
      modalTitle: "Select employee",
      placeholder:
        "Please input employee name, department name or mail address",
      notValuesFieldMember: "Not value",
      checkAll: "Select {{count}} displayed",
    },
    condition: {
      title: "Condition",
    },
  },
  ir: {
    receivingImages: {
      labels: {
        clientEmail: '差出人',
        destEmail: '宛先',
        fileName: 'ファイル名',
        createdAt: '受領日時',
      },
      index: {
        title: '受領書類一覧',
      },
      show: {
        title: '受領書類詳細',
        actions: {
          share: '共有',
        },
      },
      shareModal: {
        title: '書類を共有する',
        labels: {
          email: '共有先のメールアドレス',
        },
        actions: {
          share: '共有',
        },
        messages: {
          success: '共有しました',
        },
      },
    },
  },
  viewer: {
    passwordForm: {
      title: "Please enter pdf password",
      subTitle: "This PDF is password protected.",
      placeholder: "enter password",
    },
    changeViewer: "change viewer",
    browserPDFError: {
      title: "Viewer is not supported.\nPlease change from here.",
      button: "Change Viewer",
    },
  },
};

export default locales;
